export const Last20Years = Array.from({ length: 20 }, (_, index) =>
  (new Date().getFullYear() - index).toString(),
);
export const Months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];


export const eventType = [
  { label: 'Work Shop', value: "work_shop" },
  { label: 'Mock Exam', value: "mock_exam" },
  { label: 'General Event', value: "general_event" },
  { label: 'Seminar', value: "seminar" },
  { label: 'Conference', value: "conference" },
  { label: 'Class', value: "class" },
  { label: 'Expo', value: "expo" },
]


export const activityStatusFilter = [
  { label: 'All', value: "all" },
  { label: 'Upcoming', value: "Upcoming" },
  { label: 'Ongoing', value: "Ongoing" },
  { label: 'Previous', value: "Previous" },

]

export const getStatusTag = (status) => {
  switch (status) {
    case "Processing":
      return <div className="main-new-div-tags-098uj">Processing</div>;
    case "Accepted":
      return <div className="main-new-div-tags-098uj">Accepted</div>;
    case "On Hold":
      return <div className="main-new-div-tags-098uj">On Hold</div>;
    default:
      return null;
  }
};