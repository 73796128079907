import {
  ArrowLeftOutlined,
  CalendarOutlined,
  MailOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import ExamImgNew from "../../assets/images/exam.png";
import prerarationImgNew from "../../assets/images/preparation.png";
import EventImgNew from "../../assets/images/event.png";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { Accordion } from "react-bootstrap";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";
import ContentApis from "../../services/ContentApis";
import { useMediaQuery } from "react-responsive";
import NodataImg from "../../assets/images/no-data.png";
import useDebounce from "../../hooks/useDebounce";
const { Title, Text } = Typography;

window.Buffer = window.Buffer || require("buffer").Buffer;

const { Panel } = Collapse;
function Transaction() {
  const { request } = useRequest();
  const isMobile = useMediaQuery({ maxWidth: 991 });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [activeKey, setActiveKey] = useState(null);

  const getData = () => {
    setLoading(true);
    request({
      url: `${apiPath.transactionList}?search=${debouncedSearchText}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setData(data?.data);
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [debouncedSearchText]);

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <section className="main container-fluid">
        <div className="account-outers">
          <Row>
            {isMobile ? <BackArrow /> : <AccountSideNav />}
            <Col span={24} lg={16} xl={15} className="mx-auto  amt-auto">
              <div className="tital-text-sign">
                {lang("Transaction History")}
              </div>
              <div className="account-main12345">
                <div className="prvecy-main-discription">
                  <div className="main-new-saerch-bar-new-for-bookingss">
                    <div className="role-wrap">
                      <Input.Search
                        placeholder="Search by keyword"
                        style={{ maxWidth: 400 }}
                        enterButton
                        value={searchText}
                        onChange={onSearch}
                      />
                    </div>
                  </div>

                 <div className="main-new-remember-classes-main-scroll-class">
                 {loading ? (
                    <div className="for-spin-loader-clas">
                      <Spin />
                    </div>
                  ) : (
                    <Row gutter={[24, 24]}>
                      {data?.length ? (
                        data?.map((item, index) => (
                          <Col
                            span={24}
                            md={12}
                            className="main-new-collapes-classs-o"
                            key={index}
                          >
                            <Collapse
                              activeKey={activeKey}
                              onChange={(key) =>
                                setActiveKey(
                                  key.length ? [key[key.length - 1]] : []
                                )
                              }
                            >
                              <Panel
                                header={
                                  <div className="heading-transiationmain-9ijuhy">
                                    <div className="main-new-class-header-of-logo-456">
                                    {item?.type === "Exam" ? (
                                        <div className="mini-logo-exam-img">
                                          {" "}
                                          <img src={ExamImgNew} />{" "}
                                        </div>
                                      ) : item?.type === "Event" ? (
                                        <div className="mini-logo-exam-img mini-logo-exam-img-for-event">
                                          {" "}
                                          <img src={EventImgNew} />{" "}
                                        </div>
                                      ) : (
                                        <div className="mini-logo-exam-img mini-logo-exam-img-for-preparation">
                                          {" "}
                                          <img src={prerarationImgNew} />{" "}
                                        </div>
                                      )}
                                    <h5>
                                      {item?.booking_id?.bookingNo
                                        ? item?.booking_id?.bookingNo
                                        : ""}
                                    </h5>  
                                      </div>                               
                                    <h4>{item?.type ? item?.type : ""}</h4>
                                  </div>
                                }
                                key={index.toString()}
                              >
                                <Card className="main-newtransistion-cardf">
                                  <div className="main-new-inner-text-transation-iju">
                                    <h2>Title</h2>
                                   {item?.type === "Exam" ? 
                                    <h6>
                                    {item?.booking_id?.examData?.name ? item?.booking_id?.examData?.name : ""}{" "}
                                    ({item?.booking_id?.examData?.exam_code ? item?.booking_id?.examData ?.exam_code : ""})
                                  </h6> :
                                    <h6>
                                      {item?.booking_id?.eventData?.name ? item?.booking_id?.eventData?.name : ""}{" "}
                                      ({item?.booking_id?.eventData?.event_code ? item?.booking_id?.eventData ?.event_code : ""})
                                    </h6>}
                                  </div>
                                  <div className="main-new-inner-text-transation-iju">
                                    <h2>Center</h2>
                                    <h6>
                                      {item?.booking_id?.centerData?.name ? item?.booking_id?.centerData?.name : ""}
                                    </h6>
                                  </div>
                                  <div className="main-new-inner-text-transation-iju">
                                    <h2>Payment Type</h2>
                                    <h6>Card</h6>
                                  </div>
                                  <div className="main-new-inner-text-transation-iju">
                                    <h2>Booking date</h2>
                                    <h6>
                                      <CalendarOutlined />{" "}
                                      {item?.booking_id?.created_at
                                        ? moment(
                                            item?.booking_id?.created_at
                                          ).format("DD-MMM-YYYY hh:mm a")
                                        : ""}
                                    </h6>
                                  </div>
                                  <div className="main-new-inner-text-transation-iju">
                                    <h2>Units</h2>
                                    <h6>1</h6>
                                  </div>
                                  <div className="main-new-inner-text-transation-iju">
                                    <h2>Total</h2>
                                    <h6>
                                      R{" "}
                                      {item?.booking_id?.totalAmount
                                        ? item?.booking_id?.totalAmount
                                        : item?.booking_id?.amount || 0}
                                    </h6>
                                  </div>
                                  <Button
                                    type="primary"
                                    block
                                    style={{
                                      marginTop: 20,
                                      width: "100%",
                                      maxWidth: "100%",
                                    }}
                                    onClick={() =>
                                      item?.invoice
                                        ? window.open(item?.invoice)
                                        : ""
                                    }
                                  >
                                    VIEW INVOICE
                                  </Button>
                                </Card>
                              </Panel>
                            </Collapse>
                          </Col>
                        ))
                      ) : (
                        <Col span={24} className="mt-5">
                        <div className="no-data-image">
                          <img src={NodataImg} />
                        </div>
                        </Col>
                      )}
                    </Row>
                  )}
                 </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Transaction;
