import moment from "moment";
import { Timezone } from "./timezone";
export const getFileExtension = (url) => {
  // Get the last part of the URL after the last '/'
  const filename = url.substring(url.lastIndexOf('/') + 1);

  // Get the file extension by getting the last part of the filename after the last '.'
  const extension = filename.substring(filename.lastIndexOf('.') + 1);

  return extension;
};

export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key]) {

      if (obj[key] === '{"min":0,"max":20000000}') {

      } else {
        return false;
      }

    }
  }
  return true;
}

export function formatDate(date) {
  const now = moment();
  const inputDate = moment(date);

  if (now.isSame(inputDate, 'day')) {
    return 'Today, ' + inputDate.format('hh:mm A');
  } else if (now.subtract(1, 'day').isSame(inputDate, 'day')) {
    return 'Yesterday, ' + inputDate.format('hh:mm A');
  } else {
    return inputDate.format('DD/MM/YYYY, hh:mm A');
  }
}

export function formatPhone(countryCode, phoneNumber) {
    if(!phoneNumber) return ''
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
  if (countryCode && numericPhoneNumber) {
    const groups = numericPhoneNumber.match(/(\d{2})(\d{3})(\d{3})(\d+)/);
    if (groups) {
      return `+${countryCode}-${groups[1]}-${groups[2]}-${groups[3]}-${groups[4]}`;
    }
  }
  return phoneNumber;
}


export const capitalize = (str) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeNullValues = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } 
  }
  return obj
}

export const dateString = (created_at,format="LLL")=>{
  console.log("Client TimeZone",Timezone);
  if(!created_at) return 
  return moment(created_at).tz(Timezone).format(format)
} 


export const formatTimeAgo = (date) => {
  const now = moment();
  const postTime = moment(date);
  const diff = now.diff(postTime, ("minutes"));

  if (diff < 1) return ("Just now");
  if (diff < 60)
    return `${diff} ${diff === 1 ? ("minute") : ("minutes")} ${(
      "ago"
    )}`;
  if (diff < 24 * 60)
    return `${Math.floor(diff / 60)} ${
      Math.floor(diff / 60) === 1 ? ("hour") : ("hours")
    } ${("ago")}`;
  if (diff < 30 * 24 * 60)
    return `${Math.floor(diff / (24 * 60))} ${
      Math.floor(diff / (24 * 60)) === 1 ? ("day") : ("days")
    } ${("ago")}`;
  return postTime.format("ll");
};
