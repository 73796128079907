import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// const config = {
//     apiKey: "AIzaSyAz3PprwaKTQW55OcKOgoW8v_8QqO3w6S8",
//     authDomain: "tawasionline-412810.firebaseapp.com",
//     databaseURL: "https://tawasionline-412810-default-rtdb.firebaseio.com",
//     projectId: "tawasionline-412810",
//     storageBucket: "tawasionline-412810.appspot.com",
//     messagingSenderId: "629427547236",
//     appId: "1:629427547236:web:34096274a86f03fec74371",
//     measurementId: "G-Z64JKDD6FL"
// };
const config = {
    apiKey: "AIzaSyAr8bp7rTzszmQaGFE-ze-w9LjuW6pt-zo",
    authDomain: "examconnect-ed2bc.firebaseapp.com",
    projectId: "examconnect-ed2bc",
    storageBucket: "examconnect-ed2bc.firebasestorage.app",
    messagingSenderId: "849248514727",
    appId: "1:849248514727:web:ec24432732942a9c28e182",
    measurementId: "G-WGC7WN4LLF"
  };

const m_app = initializeApp(config);

const app = firebase.initializeApp(config);

const db = firebase.firestore();

const m_db = getFirestore(m_app);
const realDb = getDatabase(m_app);

export { db, firebase, realDb, m_db, m_app }