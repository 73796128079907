import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  Divider,
  Row,
  Col,
  Layout,
  Form,
} from "antd";
import { Container } from "react-bootstrap";
import { ArrowLeftOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Footer from "../../components/layout/Footer";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import NodataImg from "../../assets/images/no-data.png";
import moment from "moment";
import ConfirmationBox from "../../components/ConfirmationBox";
import Alert from "../../assets/images/alert-ngg.png";
import DeleteApis from "../../services/DeleteApis";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import ConFirmPayment from "./Payment";
import { useMediaQuery } from "react-responsive";

const { Title, Text } = Typography;

const Index = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const [form] = Form.useForm();
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConFirmPay, setShowConFirmPay] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState(null);
  const [cartId, setCartId] = useState(null);
  const { ShowConfirmBox, ShowAlertBox } = ConfirmationBox();
  const { deleteData } = DeleteApis();
  const isMobile =useMediaQuery({maxWidth:991});
  const navigate = useNavigate();

  const api = {
    list: apiPath.listCart,
    delete: apiPath.deleteCart,
    checkOut: apiPath.checkOut,
  };

  const handleDelete = () => {
    deleteData({
      url: api.delete,
      id: selected,
      setRefresh: setRefresh,
      setLoading: setLoading,
      setSelected: setSelected,
      method: "GET",
    });
  };

  const getDataData = (id) => {
    setLoading(true);
    request({
      url: `${api.list}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setList(data?.data?.[0] ?? []);
          console.log(data?.data?.[0], "data?.data");
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  const handlecheckout = (id) => {
    setLoading(true);
    const payload = { cart_id: cartId ? cartId : null };
    request({
      url: `${api.checkOut}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setShowConFirmPay(false);
          // window.open(data?.data, "_blank");
          window.location.replace(data?.data);
        }
        console.log(data, "checkOut");
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getDataData();
  }, [refresh]);

  return (
    <>
      <Layout>
      {isMobile ? <BackArrow /> :""}
        <Container>
       
            <Card className="main-new-card-booking">
              <h3>{list?.cartDetails?.length ? `Confirm your booking : ${list?.cartDetails?.length}` : "" }</h3>
              {loading ? (
            <Loader />
          ) : (  <div>
              {/* Exam Details */}
             <div className="main-new-remember-classes-main-scroll-class">
             <Row gutter={[16, 16]} className="mb-3">
                {list?.cartDetails?.length ? (
                  list?.cartDetails?.map((item) => (
                    <Col
                      span={list?.cartDetails?.length === 1 ? 24 : 24}
                      lg={list?.cartDetails?.length === 1 ? 24 : 12}
                    >
                      <div className="main-new-pre-booking-card-new">
                        <div className="main-new-card-booking-row">
                          <div>
                            <Text strong>
                              {item?.type === "Preparation" ? "Exam preparation:" : item?.type === "Exam" ? "Subject"  : "Events"}{" "}
                            </Text>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Text>
                              {item?.type === "Exam" ? item?.syllabus_id?.name : item?.event_id?.name ? item?.event_id?.name : "-"}
                            </Text>
                          </div>
                        </div>

                        {/* Student */}
                        <div className="main-new-card-booking-row">
                          <div>
                            <Text strong>Student</Text>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <Text>
                              {item?.student?.length
                                ? item?.student
                                    ?.map(
                                      (student) =>
                                        `${student?.first_name} ${student?.last_name}`
                                    )
                                    .join(", ")
                                : "-"}
                            </Text>
                          </div>
                        </div>

                        <div className="main-new-card-booking-row">
                          <div span={12}>
                            <Text strong>Start Date</Text>
                          </div>
                          <div span={12} style={{ textAlign: "right" }}>
                         {item?.type === "Exam" ? 
                            <Text> {" "} {item?.exam_id?.start_date ? moment(item?.exam_id?.start_date).format( "DD-MMM-YYYY hh:mm a") : ""}</Text>
                          : <Text> {" "} {item?.event_id?.start_date ? moment(item?.event_id?.start_date).format( "DD-MMM-YYYY hh:mm a") : ""}</Text>}
                          </div>
                        </div>

                        {/* Center */}
                        <div className="main-new-card-booking-row">
                          <div span={12}>
                            <Text strong>Center</Text>
                          </div>
                          <div span={12} style={{ textAlign: "right" }}>
                            <Text>
                              {item?.center_id?.name
                                ? item?.center_id?.name
                                : ""}
                            </Text>
                          </div>
                        </div>

                        <div className="main-new-card-booking-row">
                          <div span={12}>
                            <Text strong>Price per booking</Text>
                          </div>
                          <div span={12} style={{ textAlign: "right" }}>
                            <Text>
                              R{" "}
                              {item?.type === "Exam" ? item?.amount : item?.event_id?.booking_fees ? item?.event_id?.booking_fees : "0"}
                            </Text>
                          </div>
                        </div>

                        <div className="main-new-remove-and-edit-button">
                          <Button
                            type="text"
                            danger
                            icon={<i className="fas fa-trash" />}
                            onClick={() => {
                              setSelected(item?._id);
                              setIsDeleteVisible(true);
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            type="text"
                            icon={<i className="fas fa-edit" />}
                            onClick={() =>
                              navigate(
                                item?.type === "Exam" ?
                                `/view-exam/${item?.center_id?._id}/${item?.exam_id?._id}?type=${item?.type}&student=${item?.student?.[0]?._id}&cartId=${item?._id}` :
                                `/view-event-preparation/${item?.event_id?._id}?type=${item?.type}&student=${item?.student?.[0]?._id}&cartId=${item?._id}`
                              )
                            }
                          >
                            Edit info
                          </Button>
                        </div>
                        {/* Total */}
                        <Row
                          justify="space-between"
                          style={{ marginTop: "20px", marginBottom: "10px" }}
                        >
                          <Text strong>Total</Text>
                          <Text strong style={{ color: "#00A676" }}>
                            R{item?.totalAmount}
                          </Text>
                        </Row>
                      </div>
                    </Col>
                  ))
                ) : (
                  <Col span={24}>
                    <div className="no-data-image">
                      <img src={NodataImg} />
                    </div>
                  </Col>
                )}
              </Row>
             </div>
              {list?.cartDetails?.length > 1 ? (
                <Row
                  style={{ marginTop: "20px", marginBottom: "10px" }}
                  className="mai-new-drand-totalhjkmj"
                >
                  <Text strong>GrandTotal</Text>
                  <Text strong style={{ color: "#00A676" }}>
                    R{list?.totalAmount ?? "0"}
                  </Text>
                </Row>
              ) : (
                ""
              )}

              {list?.cartDetails?.length ? (
                <div className="main-new-card-button-booking-add-checkout">
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      setShowAlert(true);
                      setCartId(list?._id);
                    }}
                    className="main-new-add-more-booking-ikuj7890-checkout"
                  >
                    CHECKOUT
                  </Button>
                  <Button
                    block
                    onClick={() => navigate(`/exam`)}
                    className="main-new-add-more-booking-ikuj7890"
                  >
                    <PlusOutlined /> ADD MORE BOOKINGS
                  </Button>
                </div>
              ) : (
                ""
              )}
              </div>  )}
            </Card>
        
        </Container>
      </Layout>
      {isDeleteVisible && (
        <ShowConfirmBox
          reasons={[]}
          title={"Delete Cart"}
          subtitle={`Are you sure you want to delete this cart?`}
          show={isDeleteVisible}
          hide={() => {
            setIsDeleteVisible(false);
            setSelected();
          }}
          onOk={() => handleDelete()}
        />
      )}
      {showAlert && (
        <ShowAlertBox
          okText="Proceed"
          reasons={[]}
          title={
            <div className="main-new-classes-alert">
              <img src={Alert} />
              Alert!!
            </div>
          }
          subtitle={`One or more of your students qualify for concessions. However, the centre where you have selected an exam booking has not verified the offering of concessions. Do you wish to proceed?`}
          show={showAlert}
          hide={() => {
            setShowAlert(false);
            setSelected();
          }}
          onOk={() => {
            setSelected();
            setShowAlert(false);
            setShowConFirmPay(true);
          }}
        />
      )}
      {showConFirmPay && (
        <ConFirmPayment
          show={showConFirmPay}
          hide={() => {
            setShowConFirmPay(false);
            setSelected();
          }}
          onOk={() => handlecheckout()}
        />
      )}
    </>
  );
};
export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow fillter-sidebar-back-arrow-notification125">
      <div onClick={( ) => navigate("/account-menu")} className="sidebar-btn">
      <ArrowLeftOutlined />
    </div>
    </div>
  );
};

export default Index;
