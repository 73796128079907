import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";
import { useMediaQuery } from "react-responsive";

window.Buffer = window.Buffer || require("buffer").Buffer;

function ChangePassword() {
  const { request } = useRequest();
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { old_password, new_password } = values;
    setLoading(true);
    const payload = {
      old_password: old_password,
      new_password: new_password,
    };
    request({
      url: apiPath.changePassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        console.log("data", data);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setLoading(false);
          form.resetFields();
        } else {
          setLoading(false);
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <section className="main container-fluid">
        <div className="account-outers">
          <Row>
            {isMobile ? <BackArrow /> : <AccountSideNav />}
            <Col span={24} lg={16} xl={15} className="mx-auto  amt-auto">
              <div className="tital-text-sign">{lang("Change Password")}</div>

              <div className="account-main">
                <div className="profile-card security-card">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="signup-form security-form">
                      <Form
                        onFinish={onFinish}
                        layout="vertical"
                        className="row-col"
                        form={form}
                      >
                        <Row gutter={12}>
                          <Col span={24} md={24}>
                            <Form.Item
                              className="username-form billing-form"
                              // label="Current Password"
                              name="old_password"
                              rules={[
                                {
                                  required: true,
                                  message: lang(
                                    "Please enter the old password!"
                                  ),
                                },
                              ]}
                            >
                              <Input.Password
                                className="form-of-type"
                                placeholder={lang("Current Password")}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24}>
                            <Form.Item
                              className="username-form billing-form"
                              name="new_password"
                              rules={[
                                {
                                  required: true,
                                  message: lang(
                                    "Please enter the new password!"
                                  ),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("old_password") === value
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          lang(
                                            "Old password & new password must be different!"
                                          )
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input.Password
                                className="form-of-type"
                                placeholder={lang("New Password")}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24}>
                            <Form.Item
                              className="username-form billing-form"
                              // label="Confirm Password"
                              name="confirm"
                              dependencies={["new_password"]}
                              // hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: lang(
                                    "Please enter the confirm password!"
                                  ),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("new_password") === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        lang(
                                          "Confirm password & password does not match!"
                                        )
                                      )
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input.Password
                                className="form-of-type"
                                placeholder={lang("Confirm Password")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="password-page-btn">
                          <Form.Item>
                            <Button
                              htmlType="submit"
                              className="security-btn"
                              disabled={loading}
                            >
                              {lang("Update Password")}
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default ChangePassword;
