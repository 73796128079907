import Main from "../../components/layout/Main";
import UpdateProfileImage from "../../components/UploadProfileImage";
import UserImg from "../../assets/images/Group 288.png";
import Celebrtin from "../../assets/images/celebration.png";
import AttechEmail from "../../assets/images/attach_email.png";
import WorkImg from "../../assets/images/work_history.png";
import { Button, Card, Image, Modal } from "antd";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import NodataImg from "../../assets/images/no-data.png";
import ExamImgNew from "../../assets/images/exam.png";
import prerarationImgNew from "../../assets/images/preparation.png";
import EventImgNew from "../../assets/images/event.png";
import { getStatusTag } from "../../constants/var";
import useRequest from "../../hooks/useRequest";
import notfound from "../../assets/images/not_found.png";
import apiPath from "../../constants/apiPath";
import { CalendarOutlined } from "@ant-design/icons";
import Loader from "../../components/Loader";
import ViewUpBookingData from "../../modals/ViewUpBookingData";
import Prousergirl from "../../assets/images/for-girl.png";
import Prouserboy from "../../assets/images/for-boy.png";
const ViewDetails = ({ show, hide, data, refresh , setVisibleUpComingdata ,setUpComingdata}) => {
  const navigate = useNavigate();
  const [upData, setUpData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const {request} = useRequest()

  const getUpComingData = () => {
    setLoading(true);
    request({
      url: `${apiPath.studentUpBooking}/${data?._id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setUpData(data?.data ?? []);
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(()=>{
    if(!data) return;
    getUpComingData()
  },[data])

  return (
    <>
      <Modal
        title={null}
        open={show}
        onOk={() => hide()}
        onCancel={() => hide()}
        footer={false}
      >
        <div className="account-prof-mg">
          <div className="main-profilr-fff">
            <Image src={data?.image ? apiPath.profileBaseUrl + data?.image :  data.gender === "Female" ?  Prousergirl :  Prouserboy} alt="" />
          </div>

          <h3>{`${data?.first_name} ${data?.last_name}` ?? "-"}</h3>
          <h4>{data?.type ?? "-"} </h4>
        </div>
        <div className="mian-new-account-inforamation">
          <h5>Information</h5>
          <div className="birthday-information">
            <div className="birthday-information-image">
              <img src={Celebrtin} />
              Birth Date
            </div>
            <h4>{data?.dob ?? "-"}</h4>
          </div>
          <div className="birthday-information">
            <div className="birthday-information-image">
              <img src={AttechEmail} />
              Email
            </div>
            <h4>{data?.email ?? "-"}</h4>
          </div>
          {/* <div className="birthday-information">
            <div className="birthday-information-image">
              <img src={WorkImg} />
              History
            </div> 
             <div className="main-new-back-button main-new-result-button">
              <Button onClick={goToResultPage}>Result</Button>
            </div>
          </div> */}
        </div>
        <div className="mian-new-account-inforamation">
        <h5>Upcoming Booking</h5>
     <div className="main-new-remember-classes-main-scroll-class main-new-remember-classes-main-scroll-class-for-attribute">
     { upData?.length ? (
          upData?.map((item) => (
            <Card
              key={item?._id}
              className="main-new-remember-classes"
              onClick={() => {
                setVisibleUpComingdata(true)
                setUpComingdata(item)
                hide()
              }}
            >
              <div className="main-new-remember-classes-olik-main-divall-mainhj">
                <div className="main-new-calaner-logo-user">
                  <Image
                    src={
                      item?.type === "Exam"
                        ? item?.centerData?.image
                          ? item?.centerData?.image
                          : notfound
                        : item?.eventData?.image
                        ? item?.eventData?.image
                        : notfound
                    }
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>

                <div className="main-new-remember-classes-olik-main-div">
                  <div className="main-new-remamber-clalaner-date-clas">
                   <div className="d-flex gap-1 align-items-center">
                   <div className="mini-logo-exam-img">
                      <img
                        src={
                          item?.type === "Exam"
                            ? ExamImgNew
                            : item?.type === "Event"
                            ? EventImgNew
                            : prerarationImgNew
                        }
                      />
                    </div>
                    <h1>
                      {item?.type === "Exam"
                        ? item.syllabusData?.name
                        : item.eventData?.name}{" "}
                    </h1>
                   </div>
                    <div className="main-new-remamber-clalaner-date-clas1234">
                      
                    <h4>
                                        {item?.type === "Exam"
                                          ? item?.examData?.start_date ? (
                                            <>
                                              <span>{moment(item?.examData?.start_date).format("DD-MMM-YYYY")}</span>
                                              <span>{moment(item?.examData?.start_date).format("hh:mm a")}</span>
                                            </>
                                          ) : (
                                            ""
                                          )
                                          : item?.eventData?.booking_start_date ? (
                                            <>
                                              <span>{moment(item?.eventData?.booking_start_date).format("DD-MMM-YYYY")}</span>
                                              <span>{moment(item?.eventData?.booking_start_date).format("hh:mm a")}</span>
                                            </>
                                          ) : (
                                            ""
                                          )
                                        }
                                      </h4>
                      
                    </div>
                  </div>

                  <div className="main-new-remamber-clalaner-date-clas">
                    <h6>
                      {/* {item?.type} */}
                      {"  "}
                      {item?.type === "Exam"
                        ? item.examData?.exam_code
                        : item.bookingNo
                        ? `${item.bookingNo}`
                        : ""}
                    </h6>
                    <h1>{item.code}</h1>
                  </div>
                </div>
              </div>
              <div className="main-new-absolute-positionhh">
                {getStatusTag(item.status)}
              </div>
            </Card>
          ))
        ) : (
          loading ? <Loader/> : 
          <div className="no-data-image">
            <img src={NodataImg} />
          </div>
        )}
     </div>
        </div>
        
      </Modal>

    </>
  );
};

export default ViewDetails;
