import React, { useState, useEffect } from "react";
import { message, Upload as UploadAntd, Image } from "antd";
import Camera from "../assets/images/camera.png";
import Avatar from "../assets/images/avatar-1.png";
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { Severty, ShowToast } from "../helper/toast";
import apiPath from "../constants/apiPath";

// S3 configuration
const s3Config = {
  region: "us-east-1",
  credentials: {
    accessKeyId: "0IQ4511TBG1F7U85AI86", // Secure these credentials in production
    secretAccessKey: "W5o8cuuTvHX6KSfSXBs5wpqnk7FUI7pJP1DEp9Zx",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
};
const s3Client = new S3Client(s3Config);

const UploadProfileImage = ({
  setImage,
  value,
  disabled = false,
  isDimension = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  // Validate image before upload
  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 5; // Ensure file is less than 5MB
    if (!isLt2M) {
      message.error("Image must be smaller than 5MB!");
      return false;
    }

    if (isDimension) {
      await checkImageDimensions(file);
    }

    return isJpgOrPng && isLt2M;
  };

  // Check the image dimensions if required
  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");

      img.onload = () => {
        if (
          img.width >= 300 &&
          img.width <= 800 &&
          img.height >= 300 &&
          img.height <= 800
        ) {
          resolve();
        } else {
          ShowToast(
            `Please upload an image with dimensions (300-800)X(300-800). Uploaded image is ${img.width} X ${img.height}`,
            Severty.ERROR
          );
          reject(
            `Invalid image dimensions: ${img.width} X ${img.height}. Must be between (300-800)X(300-800).`
          );
        }
      };

      img.src = URL.createObjectURL(file);
    });
  };

  // Upload file to S3
  const uploadFileToS3 = async (file, bucketName) => {
    const key = `examconnect/profile/${file.name}`; // Define the key for the file
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    const upload = new Upload({
      client: s3Client,
      params,
    });

    try {
      const data = await upload.done();
      return data;
    } catch (err) {
      throw err;
    }
  };

  // Handle image upload
  const handleImgChange = async (event) => {
    const { file } = event;

    setLoading(true);
    try {
      const data = await uploadFileToS3(file, "booknow");
      // setImageUrl(apiPath.profileBaseUrl + data.Key); // Set the uploaded image URL for preview
      // setImage(apiPath.profileBaseUrl + data.Key); // Pass the uploaded image URL to the parent component
      setImageUrl(data.Key); // Set the uploaded image URL for preview
      setImage(data.Key); // Pass the uploaded image URL to the parent component
      setLoading(false);
    } catch (err) {
      console.error("S3 Upload Error:", err);
      setLoading(false);
    }
  };

  // Initial image URL setup if there's a value prop
  useEffect(() => {
    if (value) setImageUrl(value);
  }, [value]);

  // Upload button for user interaction
  const uploadButton = (
    <div className="upload-btn-wrapper">
      <div className="user-upload-ic">
        <img src={Camera} alt="camera icon" />
      </div>
      <div className="default_img">
        <img src={Avatar} alt="default avatar" />
      </div>
    </div>
  );

  return (
    <UploadAntd
      disabled={disabled}
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={handleImgChange}
    >
      {imageUrl ? (
        <div className="uploaded-img-wrapper">
          {!disabled && (
            <div
              className="remove-wrap"
              onClick={(e) => {
                e.stopPropagation();
                setImageUrl(null);
                setImage(null);
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.66536 2.33333H8.33203C8.33203 1.97971 8.19156 1.64057 7.94151 1.39052C7.69146 1.14048 7.35232 1 6.9987 1C6.64508 1 6.30594 1.14048 6.05589 1.39052C5.80584 1.64057 5.66536 1.97971 5.66536 2.33333ZM4.66536 2.33333C4.66536 2.02692 4.72572 1.7235 4.84298 1.44041C4.96024 1.15731 5.13211 0.900088 5.34878 0.683417C5.56545 0.466747 5.82268 0.294875 6.10577 0.177614C6.38886 0.0603535 6.69228 0 6.9987 0C7.30512 0 7.60853 0.0603535 7.89163 0.177614C8.17472 0.294875 8.43194 0.466747 8.64861 0.683417C8.86528 0.900088 9.03716 1.15731 9.15442 1.44041C9.27168 1.7235 9.33203 2.02692 9.33203 2.33333H13.1654C13.298 2.33333 13.4252 2.38601 13.5189 2.47978C13.6127 2.57355 13.6654 2.70072 13.6654 2.83333C13.6654 2.96594 13.6127 3.09312 13.5189 3.18689C13.4252 3.28065 13.298 3.33333 13.1654 3.33333H12.2854L11.5054 11.4073C11.4455 12.026 11.1574 12.6002 10.6972 13.0179C10.2369 13.4356 9.63757 13.6669 9.01603 13.6667H4.98136C4.35994 13.6667 3.76077 13.4354 3.30066 13.0177C2.84056 12.6 2.55252 12.0259 2.4927 11.4073L1.71203 3.33333H0.832031C0.699423 3.33333 0.572246 3.28065 0.478478 3.18689C0.38471 3.09312 0.332031 2.96594 0.332031 2.83333C0.332031 2.70072 0.38471 2.57355 0.478478 2.47978C0.572246 2.38601 0.699423 2.33333 0.832031 2.33333H4.66536ZM2.68603 3.33333L3.4367 11.196C3.46809 11.5299 3.62827 11.8359 3.88746 12.0595C4.14666 12.2831 4.48514 12.4073 4.83136 12.4073H9.16603C9.51225 12.4073 9.85073 12.2831 10.1099 12.0595C10.3691 11.8359 10.5293 11.5299 10.5607 11.196L11.3114 3.33333H2.68603Z"
                  fill="red"
                />
              </svg>
            </div>
          )}
          <div className="upload-img-content">
            <Image src={apiPath.profileBaseUrl + imageUrl} width={90} height={90} preview={false} />
          </div>
        </div>
      ) : (
        uploadButton
      )}
    </UploadAntd>
  );
};

export default UploadProfileImage;
