import { Form, Modal } from "antd";



const ConFirmPayment = ({
    show,
    hide,
    onOk, 
    data,
    children,
    okText = "Ok",
    cancelText = "Cancel",
}) =>{
    return(
        <>
        <Modal
        width={500}
        open={show}
        onOk={() => {
          if (onOk) onOk();
          hide();
        }}
        maskClosable={false}
        okText={okText}
        cancelText={cancelText}
        onCancel={hide}
        centered
        className="tab_modal deleteWarningModal"
      >
        <Form layout="vertical" className="p-2">   
          <h4 className="modal_title_cls mb-2">Continue Checkout</h4>
          <h4 className="modal_sub_title_cls mb-2 ">
           The PayFast payment portal will direct you to finalise your payment. After checkout, the system automatically returns you to the Book Now page. 
           Please refrain from closing or refreshing your app during this process.</h4>
        </Form>
      </Modal>
        </>
    )
}


export default ConFirmPayment;