import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Tooltip,
  Upload,
  message,
  Layout,
  Typography,
} from "antd";
import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/booknow-small-logo.png";
import { Container } from "react-bootstrap";
import { useAuthContext } from "../../context/AuthContext";
import LogoutNewImg from "../../assets/images/logout (2).png";
import ConfirmationBox from "../../components/ConfirmationBox";
import Prouser from "../../assets/images/user.png";
import Notification from "../../assets/images/notification-white.svg";
import CartIcon from "../../assets/images/shopping-cart.png";
import Student from "../../assets/images/new-student-img.png";
import { useAppContext } from "../../context/AppContext";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

function Header({}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { ShowConfirmModal } = ConfirmationBox();
  const { isLoggedIn, isMobile, userProfile, logout,  } =
    useAuthContext();
    const {
      notificationCount,
      setRefreshNotification,
    } = useAppContext();
    const {request} = useRequest()

  const handleProfileButton = () => {
    if (!isLoggedIn) {
      navigate("/login");
      logout();
    } else {
      isMobile ? navigate("/account-menu") : navigate("/account");
    }
  };

  const navigateNotification = () => {
    if (!isLoggedIn) {
      navigate("/login");
      logout();
    } else {
      request({
        url: `${apiPath.readNotifcation}`,
        method: "POST",
        onSuccess: ({ data }) => {
          // ShowToast("Notification  Read All Successfully", Severty.SUCCESS);
          setTimeout(() => {
            setRefreshNotification(true);
          }, 20);
        },
        onError: (err) => {
          console.log(err);
        },
      });
      navigate("/notification");
    }
  };

  const navigateStudent = () => {
    if (!isLoggedIn) {
      navigate("/login");
      logout();
    } else {
      navigate("/student");
    }
  };
  const navigateCart = () => {
    if (!isLoggedIn) {
      navigate("/login");
      logout();
    } else {
      navigate("/cart");
    }
  };

  return (
    <>
      <div className="header">
        <Row align="middle" className="main-new-header-row-uj">
          <Col span={12} md={12}>
            <div className="logo">
              <img
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
                src={Logo}
                alt="Logo"
                className="img-fluid"
              />
            </div>
          </Col>

          <Col span={12} md={12} className="new-main-header-hy6">
            <div className="d-lg-none main-new-mobile-logo-gherader">
              <ul>
              <li>
                  <a
                    className={`nav-items ${
                      location.pathname === "/exam" ? "active" : ""
                    }`}
                    onClick={() => navigate("/exam")}
                  >
                    Exams
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    className={`nav-items ${
                      location.pathname === "/preparation" ? "active" : ""
                    }`}
                    onClick={() => navigate("/preparation")}
                  >
                    Preparations
                  </a>
                </li>
                <li>
                  <a
                    className={`nav-items ${
                      location.pathname === "/event" ? "active" : ""
                    }`}
                    onClick={() => navigate("/event")}
                  >
                    Events
                  </a>
                </li>
              </ul>
              <div
                className="profileDropdownMain"
                onClick={() => handleProfileButton()}
              >
                <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                  <div className="d-flex align-items-center gap-2">
                    <div className="userImg">
                      <Image
                        src={userProfile?.image ? apiPath.profileBaseUrl + userProfile.image : Prouser}
                        preview={false}
                      />
                    </div>
                    {/* <div className="d-none d-xl-block">
                      <div className="userName">{userProfile?.name}</div>
                    </div> */}
                  </div>
                </Button>
              </div>

            </div>
            <div className="d-none d-lg-block">
              <ul>
                {/* <li>
                <a
                  className={`nav-items ${
                    location.pathname === "/book-now" ? "active" : ""
                  }`}
                  onClick={() => navigate("/book-now")}
                >
                  Book
                </a>
              </li> */}
               <li>
                  <a
                    className={`nav-items ${
                      location.pathname === "/exam" ? "active" : ""
                    }`}
                    onClick={() => navigate("/exam")}
                  >
                    Exams
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    className={`nav-items ${
                      location.pathname === "/preparation" ? "active" : ""
                    }`}
                    onClick={() => navigate("/preparation")}
                  >
                    Preparations
                  </a>
                </li>
                <li>
                  <a
                    className={`nav-items ${
                      location.pathname === "/event" ? "active" : ""
                    }`}
                    onClick={() => navigate("/event")}
                  >
                    Events
                  </a>
                </li>
                {isLoggedIn ? (
                  <>
                    <li className="main-new-notificatio-header">
                      <div className="notification-header d-lg-block">
                        <Tooltip
                          title={"Notifications"}
                          color="#fff"
                          overlayInnerStyle={{
                            color: "#000",
                            padding: "8px 12px",
                            borderRadius: "4px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigateNotification();
                            }}
                            className="notifiaction-buttin-main quotation-icons"
                          >
                            <img src={Notification} alt="Notification" />
                            {notificationCount ? (
                              <span className="active_notification active_notification-999 ">
                                {notificationCount ?? 0}
                              </span>
                            ) : (
                              ""
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </li>
                    <li className="main-new-notificatio-header">
                      <div className="notification-header d-lg-block">
                        <Tooltip
                          title={"Students"}
                          color="#fff"
                          overlayInnerStyle={{
                            color: "#000",
                            padding: "8px 12px",
                            borderRadius: "4px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigateStudent();
                            }}
                            className="notifiaction-buttin-main quotation-icons"
                          >
                            <img src={Student} alt="Students" />
                            
                          </Button>
                        </Tooltip>
                      </div>
                    </li>
                    <li className="main-new-notificatio-header">
                      <div className="notification-header d-lg-block">
                        <Tooltip
                          title={"Cart"}
                          color="#fff"
                          overlayInnerStyle={{
                            color: "#000",
                            padding: "8px 12px",
                            borderRadius: "4px",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigateCart();
                            }}
                            className="notifiaction-buttin-main quotation-icons CartIcon-newhjk"
                          >
                            <img src={CartIcon} alt="Cart" />
                           
                          </Button>
                        </Tooltip>
                      </div>
                    </li>
                    <li className="main-new-profile-header">
                      <div
                        className="profileDropdownMain"
                        onClick={() => handleProfileButton()}
                      >
                        <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                          <div className="d-flex align-items-center gap-2">
                            <div className="userImg">
                              <Image
                                src={
                                  userProfile?.image
                                    ? apiPath.profileBaseUrl + userProfile.image
                                    : Prouser
                                }
                                preview={false}
                              />
                            </div>
                            {/* <div className="d-none d-xl-block">
                              <div className="userName">
                                {userProfile?.name}
                              </div>
                            </div> */}
                          </div>
                        </Button>
                      </div>
                    </li>
                  </>
                ) : (
                  <li>
                    <Button
                      onClick={() => navigate("/login")}
                      className="join-btn-neww"
                    >
                      <UserOutlined />{" "}
                      <span class="p-button-label">SignIn</span>
                    </Button>
                  </li>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      {isLogoutModalVisible && (
        <ShowConfirmModal
          title={"Logout"}
          subtitle={`Are you sure you want to logout?`}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => logout()}
        />
      )}
    </>
  );
}

export default Header;
