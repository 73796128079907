import {
  Col,
  Row,
  Form,
  Modal,
  Input,
  Button,
  Card,
  Tag,
  Tooltip,
  Image,
} from "antd";
import { useContext, useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import {
  CalendarOutlined,
  EyeFilled,
  FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import Newmainpin from "../assets/images/home_pin.png";
import TimingImg from "../assets/images/timer.png";
import ClockImg from "../assets/images/clock_loader_20.png";
import DateImg from "../assets/images/date_range.png";
import SingleImageUpload from "../components/SingleImageUpload";

const ViewUpBookingData = ({ show, hide, data ,refresh }) => {
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);
  const [component, setComponent] = useState([]);
  const [docsFile, setDocsFile] = useState(data?.document);
  const FileType = ["application/pdf"];
 
  const { request } = useRequest();

  const getData = () => {
    setLoading(true);
    request({
      url: `${apiPath.upcomingBookingView}/${data?._id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setList(data?.data ?? []);
          getComponent(data?.data?.[0]?.syllabus_option_id);
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const getComponent = (id) => {
    request({
      url: `${apiPath.getComponent}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setComponent(data?.data ?? []);
      },
      onError: (err) => {
        console.log(err, "err");
      },
    });
  };

  useEffect(() => {
    if (!data) return;   
    getData();
    setDocsFile(data?.document)
    console.log(data, "data");
  }, [data]);

  const handleDocFile = (value,key) => {
    if(key === "sendDocs"){
      console.log(value,"valuevalue")
      const payload = {document : value.length > 0 ? value[0].name: "" } 
      request({
        url: `${apiPath.uploadDocs}/${data?._id}`,
        data: payload,
        method: "POST",
        onSuccess: ({ data, status }) => {
            hide()
            refresh()
        },
        onError: (err) => {
          console.log(err, "err");
        },
      });
    }
   
  };

  return (
    <>
      <Modal
        open={show}
        onCancel={hide}
        footer={null}
        header={null}
        className="main-new-attribute-calander-modal mainnew-attribue-heading-new-fre-modal"
      >
        <div className="main-new-modal-ofbooking-view-pahe-ijuh890">
          {component?.length
            ? component?.map((item) => (
                <div className="main-new-view-of-collapes-ikjhu-heading">
                  <div className="mainnew-attribue-heading-new-fre">
                    <h3>Components</h3>
                  </div>

                  <div className="main-new-view-of-collapes-ikjhu">
                    <div className="main-new-view-of-collapes-ikjhu-inner-12">
                      {item?.title && <h5>{item?.title}</h5>}
                      {item?.code && <p>{item?.code}</p>}
                    </div>
                    <div className="main-new-view-of-collapes-ikjhu-inner-12">
                      {item?.duration && (
                        <h2>
                          <img src={TimingImg} alt="Duration" />
                          {item?.duration}
                        </h2>
                      )}
                      {item?.weighting && (
                        <h3>
                          <img src={ClockImg} alt="Weighting" />
                          {item?.weighting}
                        </h3>
                      )}
                      {item?.syllabus_date && (
                        <h4>
                          <img src={DateImg} alt="Syllabus Date" />
                          {item?.syllabus_date}
                        </h4>
                      )}
                      {item?.tool_tip && (
                        <Tooltip title={item?.tool_tip}>
                          <h4 style={{ cursor: "pointer" }}>?</h4>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>

        {list?.length ? (
          list?.map((item) => (
            <Card>
              <div className="mainnew-attribue-heading-new-fre">
                <h3>Attributes</h3>
              </div>
              <div className="main-new-card-attribute-calander-new-main-12">
                <h3>Student</h3>
                <div>
                  {item?.studentData?.length ? (
                    <Button
                      icon={<UserOutlined />}
                      type="text"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {item?.studentData?.map(({ name }) => name).join(", ")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              {/* Status Section */}
              <div className="main-new-card-attribute-calander-new-main-12">
                <h3>Status</h3>
                <div>
                  <Tag color="orange">{item?.status ?? "Processing"}</Tag>
                </div>
              </div>

              {/* Attachments Section */}
              <div className="main-new-card-attribute-calander-new-main-12">
                <h3>Attachments</h3>
             
                <div className="new-mian-second-upload-docunent-card-logo15-new-main">
             <div>
             {item?.document ? (
                  
                  <div>
                    <Button
                      type="primary"
                      block
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                      }}
                      onClick={() =>
                        item?.document ? window.open(apiPath.profileBaseUrl+item?.document) : ""
                      }
                    >
                      <EyeFilled /> 
                    </Button>
                  </div>
               
              ) : (
                ""
              )}
             </div>
                  <div className="new-mian-second-upload-docunent-card-logo15">
                    <SingleImageUpload
                      value={docsFile}
                      fileType={FileType}
                      imageType={"file"}s
                      btnName={"PDF"}
                      onChange={(data) => handleDocFile(data, "sendDocs")}
                      isDimension={false}
                    />
                  </div>

                </div>
               
             
              </div>
             

              {/* Booking No Section */}
              <div className="main-new-card-attribute-calander-new-main-12">
                <h3>Booking No</h3>
                <div>
                  <Button icon={<FileOutlined />} type="text">
                    {item?.bookingNo ?? "-"}
                  </Button>
                </div>
              </div>

              {/* Exam Start Date Section */}
              <div className="main-new-card-attribute-calander-new-main-12">
                <h3>Exam start date</h3>
                <div>
                  <Button icon={<CalendarOutlined />} type="text">
                    {item?.type === "Exam"
                      ? item?.examData?.start_date
                        ? moment(item?.examData?.start_date).format(
                            "DD-MMM-YYYY hh:mm a"
                          )
                        : "-"
                      : item?.eventData?.event_start_date
                      ? moment(item?.eventData?.event_start_date).format(
                          "DD-MMM-YYYY hh:mm a"
                        )
                      : "-"}
                  </Button>
                </div>
              </div>

              {/* Center Section */}
              <div className="main-new-card-attribute-calander-new-main-12">
                <h3>Center</h3>
                <div className="main-new-card-attribute-calander-new-main-12-main-divhg65">
                  <div className="pin-iamge0main-divhgf">
                    {/* <img src={Newmainpin} /> */}
                    <h6>{item?.centerData?.profile_desc ?? "-"}</h6>
                  </div>
                  {item?.centerData?.location ? (
                    <div className="pin-iamge0main-divhgf">
                      <h6>{item?.centerData?.location ?? "-"}</h6>
                    </div>
                  ) : (
                    ""
                  )}
                  {item?.centerData?.center_code ? (
                    <div className="pin-iamge0main-divhgf">
                      {item?.centerData?.center_code ?? "-"}
                    </div>
                  ) : (
                    ""
                  )}

                  {item?.centerData?.email ? (
                    <div className="pin-iamge0main-divhgf">
                      {item?.centerData?.email ?? "-"}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Card>
          ))
        ) : (
          <Card loading={loading}></Card>
        )}
      </Modal>
    </>
  );
};

export default ViewUpBookingData;
