import { Col, Row, Form, Modal, Input, Button } from "antd";
import lang from "../helper/langHelper";
import { useContext, useEffect, useState } from "react";
import { InputOTP } from "antd-input-otp";
import Logo from "../assets/images/booknow-small-logo.png";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { useAuthContext, AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router";
import { Spinner } from "react-bootstrap";

const DeleteAccountOtp = ({ show, hide }) => {
  const [loading, setLoading] = useState(false);
  const [otpForm] = Form.useForm();
  const [verifyLoading, setVerifyLoading] = useState(false);
  const { request } = useRequest();
  const { logout, setUserProfile, setIsLoggedIn } = useContext(AuthContext);
  const { userProfile } = useAuthContext();
  const navigate = useNavigate();
  const [resendTimer, setResendTimer] = useState(60);
  const [loading2, setLoading2] = useState(false);

  // const onDeleteVerifyAccount = (values) => {
  //   request({
  //     url: apiPath.deleteAccount,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       if (data.status) {
  //         hide()
  //         setIsLoggedIn(false);
  //         ShowToast(data.message, Severty.SUCCESS);
  //         setTimeout(()=> navigate("/login"),200);
  //         setUserProfile();
  //         console.log(data);
  //         localStorage.removeItem("userProfile");
  //         localStorage.removeItem("token");

  //       } else {
  //         ShowToast(data.message, Severty.ERROR);
  //       }
  //     },
  //     onError: (error) => {
  //       ShowToast(error.response.data.message, Severty.ERROR);

  //     },
  //   });
  // };

  const handleVerifyOTP = (values) => {
    console.log(values, "valuess");
    let combinedOtp = values.otp;
    console.log(combinedOtp, "combinedOtp");

    if (combinedOtp.length !== 4) {
      ShowToast(lang("Please enter the complete OTP"), Severty.ERROR);
      return;
    }
    combinedOtp = values.otp ? values.otp.join("") : "";
    setVerifyLoading(true);
    // Verify the OTP entered by the user
    let payload = {};
    payload.email = userProfile?.email ? userProfile?.email : "";
    payload.otp = combinedOtp;
    payload.type = "Guardian";
    request({
      url: apiPath.verifyDeleteAccOTP,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          setVerifyLoading(false);
          hide();
          setIsLoggedIn(false);
          ShowToast("Account has been deleted successfully", Severty.SUCCESS);
          setTimeout(() => navigate("/login"), 200);
          setUserProfile();
          localStorage.removeItem("userProfile");
          localStorage.removeItem("token");
          console.log(data);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setVerifyLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const handleResendOtp = () => {
    if (resendTimer > 0) return;
    setLoading2(true);
    request({
      url: apiPath.deleteAccount,
      method: "GET",
      onSuccess: (data) => {
        setLoading2(false);
        if (data.status) {
          setResendTimer(60);
          ShowToast("Otp send successfully", Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading2(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendTimer]);

  return (
    <Modal
      width={750}
      visible={show}
      okText={lang("Verify")}
      onCancel={() => {
        hide();
      }}
      footer={false}
      className="tab_modal main-otp-model"
      okButtonProps={{
        form: "verify-otp",
        htmlType: "submit",
        loading: verifyLoading,
      }}
    >
      <div className="model-form-main-1">
        {/* <div className="model-img">
        <img src={Modelimage1Img} alt="#" />
      </div> */}
        <Form
          id="verify-otp"
          form={otpForm}
          onFinish={handleVerifyOTP}
          autoComplete="off"
          layout="vertical"
        >
          <h4 className="modal_title_cls modal_title_clsnew-mainh">
            {/* <img src={Logo} /> */}
            <h3>{lang("Verify OTP")}</h3>
          </h4>

          <Form.Item
            name="otp"
            rules={[{ required: true, message: lang("Please enter the OTP") }]}
          >
            <InputOTP
              className="otp-of-type"
              autoSubmit={false}
              length={4}
              inputType="numeric"
            />
          </Form.Item>

          <Form.Item className="mian-new-otp-button0right">
            <Button
              className=""
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {lang("Delete Account")}
            </Button>
          </Form.Item>
          <div className="otherLink text-center ">
            <span>
              {resendTimer
                ? resendTimer <= 9
                  ? "0" + resendTimer + "sec"
                  : resendTimer + "sec"
                : ""}
            </span>
            <div>
              {lang("Didn't Receive OTP")}?
              <button
                disabled={resendTimer}
                style={{
                  background: "none",
                  border: "none",
                  color: resendTimer ? "GrayText" : "#3cc1b8",
                }}
                onClick={handleResendOtp}
                className="textBtn"
              >
                {loading2 ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  lang("Resend")
                )}{" "}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default DeleteAccountOtp;
