import { useNavigate } from "react-router";
import apiPath from "../constants/apiPath";
import { useAppContext } from "../context/AppContext";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { useAuthContext } from "../context/AuthContext";




const AuthApis = ()=>{
    const {setUserProfile,setIsLoggedIn} = useAuthContext()
    const {request } = useRequest() 
    const navigate = useNavigate()


    //Signup
    // const onRegister = ({form,values,mobileNumber,setLoading}) => {  
    //     setLoading(true)
    //     const {newPassword } = values 
    //     const payload = {
    //         ...values,
    //         password :       newPassword,
    //         country_code :   mobileNumber?.country_code,
    //         mobile_number :  mobileNumber?.mobile_number,
    //         device_type: "web",
    //         type: "Guardian",
    //     };

        
    //     request({
    //       url: apiPath.signUp,
    //       method: "POST",
    //       data: payload,
    //       onSuccess: (data) => {
    //        setLoading(false)
    //          console.log("data---",data)
    //          console.log("data111",data)
    //          console.log("data11134",data?.data)
    //           setIsLoggedIn(true);
    //           ShowToast(data.message, Severty.SUCCESS);
    //           setUserProfile(data?.data?.userData);
    //           localStorage.setItem("token", data?.data?.token);
    //           localStorage.setItem("userProfile", JSON.stringify(data?.data?.userData));
    //           setTimeout(() => navigate("/"), 200);
    //           form.resetFields();
           
    //       },
    //       onError: (error) => {
    //         setLoading(false)
    //         ShowToast(error?.response?.data?.message, Severty.ERROR);

    //       },
    //     });
    //   };


      // Add-Edit Favourite
      const favCenter = ({payload,setRefresh,method ="POST"}) => {  
          
        request({
          url: apiPath.favCenter,
          method: method,
          data: payload,
          onSuccess: (data) => {
     
            if (data.status) {
              ShowToast(data.message, Severty.SUCCESS);
              setRefresh(()=> prev => !prev )
            } else {
              ShowToast(data.message, Severty.ERROR);
            }
          },
          onError: (error) => {
   
            ShowToast(error?.response?.data?.message, Severty.ERROR);

          },
        });
      };



      // My Booking
      const fetchBookingData = ({url,pagination ,filter,onFatch,setLoading,debouncedSearchText}) => {  
        setLoading(true)

        const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

        request({
          url: `${url}/?page=${pagination ? pagination.current : 1}&pageSize=${
            pagination ? pagination.pageSize : 1000
          }&bookingNo=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
          method: "GET",     
          onSuccess: ({ data }) => {
           setLoading(false)
           onFatch(data ?? []);             
           
          },
          onError: (error) => {
            setLoading(false)
            console.log(error,"error")
            // ShowToast(error?.response?.data?.message, Severty.ERROR);

          },
        });
      };

      return {favCenter , fetchBookingData}
}




export default AuthApis