import React, { useEffect, useState } from "react";
import { Button, Layout, Tabs } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import Nonotification2Img from "../../assets/images/no-notification-page-img.png";
import RemamberImg from "../../assets/images/new_releases.png";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import Loader from "../../components/Loader";
import { formatTimeAgo } from "../../helper/functions";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
import ConfirmationBox from "../../components/ConfirmationBox";
import Footer from "../../components/layout/Footer";
import NodataImg from "../../assets/images/no-data.png";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function Index() {
  const { request } = useRequest();
  const [notifications, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const [showAllDelete, setShowAllDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { language, setRefreshNotification } = useAppContext();
  const { ShowConfirmBox } = ConfirmationBox();

  const fetchNotification = () => {
    setLoading(true);
    request({
      url: `${apiPath.notificationList}`,
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setNotification(data.data);
          console.log(data.data, "noti..");
        }
        setLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchNotification();
  }, [refresh]);

  const handleNotiNavigate = (noti) => {
    console.log(noti, "useFor...");


    if (!noti?.is_read) {
      request({
        url: `${apiPath.readNotifcation}/${noti?._id}`,
        method: "POST",
        onSuccess: ({ data }) => {
          setRefresh((prev) => !prev);
          ShowToast("Notification Read Successfully", Severty.SUCCESS);
          setTimeout(() => {
            setRefreshNotification(true);
          }, 20);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  const readAllNotifcation = () => {
    request({
      url: `${apiPath.readNotifcation}`,
      method: "POST",
      onSuccess: ({ data }) => {
        ShowToast("Notification  Read All Successfully", Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setTimeout(() => {
          setRefreshNotification(true);
        }, 20);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const deleteNotification = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.deleteNotifcation}/${selected}`,
      method: "DELETE",
      onSuccess: ({ data, message }) => {
        ShowToast(message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setLoading(false);
        setSelected("");
        setShowAllDelete(false);
        setShow(false);
        setTimeout(() => {
          setRefreshNotification(true);
        }, 20);
      },
      onError: (err) => {
        ShowToast(err?.response?.data?.message, Severty.ERROR);
        console.log(err);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Layout>
      {isMobile ? <BackArrow /> :""}
        <section className="notification-section">
          <div className="container">
            <div className="remamber-1 remamber-1-mani">
              <div className="mani-text-notification902main">
                <div className="mani-text-notification902">
                  {lang("Notification")}
                </div>
                {notifications.length ? (
                  <div className="main-dltall-not-all">
                    <Button
                      disabled={!notifications.length}
                      className="deleteall-main-button"
                      onClick={() => {
                        setShowAllDelete(true);
                      }}
                    >
                      {lang("Delete All")}
                    </Button>
                    <Button
                      disabled={!notifications.length}
                      className="deleteall-main-button"
                      onClick={() => {
                        readAllNotifcation();
                      }}
                    >
                      {lang("Read All")}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {loading ? (
                <Loader />
              ) : notifications.length ? (
                notifications?.map((item) => (
                  <div
                    className={`remamber-main ${
                      item?.is_read
                        ? "Notification-read"
                        : "Notification-unread"
                    }`}
                    onClick={() => handleNotiNavigate(item)}
                  >
                    <div className="remamber-img">
                      <img src={RemamberImg} alt="" />
                    </div>
                    <div className="remamber-teext">
                      <div className="remamber-txt-2">
                        <h2>
                          {language !== "en" && language !== null
                            ? item?.[`${language}_title`] ?? item?.title
                            : item?.title}
                        </h2>
                        <h5>
                          {language !== "en" && language !== null
                            ? item?.[`${language}_message`] ?? item?.message
                            : item?.message}
                        </h5>
                      </div>
                      <div className="remamber-para remamber-para-main9">
                        <p>
                          {item?.created_at
                            ? formatTimeAgo(item?.created_at)
                            : ""}
                        </p>
                        <div
                          className="main-delete-out"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(true);
                            setSelected(item?._id);
                          }}
                        >
                          <DeleteOutlined />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-noti-data">
                  <img src={NodataImg} />
                </div>
              )}
            </div>
          </div>
        </section>
      </Layout>
      {show && (
        <ShowConfirmBox
          title={lang("Delete Notification")}
          subtitle={lang(`Are you sure you want to delete this notification?`)}
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          onOk={(value) => deleteNotification(value)}
        />
      )}
      {showAllDelete && (
        <ShowConfirmBox
          title={lang("Delete All Notification")}
          subtitle={lang(`Are you sure you want to delete all notification?`)}
          show={showAllDelete}
          hide={() => {
            setShowAllDelete(false);
            setSelected("");
          }}
          onOk={(value) => {
            setSelected();
            deleteNotification();
          }}
        />
      )}
      
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow fillter-sidebar-back-arrow-notification125">
      <div onClick={() => navigate("/account-menu")} className="sidebar-btn">
      <ArrowLeftOutlined />
    </div>
    </div>
  );
};

export default Index;
