import React,  { useEffect , useRef } from "react";
import { Carousel, Container } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../assets/styles/animate.css";
import { Card, Button, Row, Col, Typography ,Statistic} from "antd";
import Prepare1Img from "../../assets/images/prepare-exam-slide.jpg";
import Banner1Img from "../../assets/images/Event-banner1.png";
import Banner2Img from "../../assets/images/Prep-banner1.png";
import EduwhereImg from "../../assets/images/eduanywhere-logo.png";
import NewappImg from "../../assets/images/android_download (1).4f15489959c71bf14e1b.png";
import NewplayImg from "../../assets/images/ios_download (1).c2dea862804ed87db71c.png";
import New2Img from "../../assets/images/chose-4.svg";
import New1Img from "../../assets/images/chose-5.svg";
import WhyChooseImg from "../../assets/images/adorable-young-boy-doing-his-homework 11515.png";
import CheckBoxImg from "../../assets/images/check-box-with-check-sign.png";
import Count1Img from "../../assets/images/about-us-counter-1.svg";
import Count2Img from "../../assets/images/about-us-counter-2.svg";
import Count3Img from "../../assets/images/about-us-counter-3.svg";
import Count4Img from "../../assets/images/about-us-counter-4.svg";
import AboutNew1Img from "../../assets/images/boy-with-backpack-holding-stack-books (1)12.png";
import RoundshapeImg from "../../assets/images/bg-round-shape-1.svg";
import blog1Img from "../../assets/images/littile-boy1.jpg";
import blog2Img from "../../assets/images/littile-boy3.jpg";
import blog3Img from "../../assets/images/littile-boy2.jpg";
import ExamImg from "../../assets/images/exam-landing-icon.svg";
import PreparationImg from "../../assets/images/preparation-landing-icon.svg";
import EventImg from "../../assets/images/event-landing-icon.svg";
import Banner11Img from "../../assets/images/full-shot-kid-cheating-school 1new11.png";
import Banner22Img from "../../assets/images/banner-32.png";
import Banner33Img from "../../assets/images/full-shot-kid-cheating-school 1new.png";
import {
  AndroidOutlined,
  AppstoreAddOutlined,
  CheckOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import Footer from "../../components/layout/Footer";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import CountUp from 'react-countup';
const Index = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthContext();
  const carouselRef = useRef(null);
  const formatter = (value) => <CountUp end={value} separator="," />;
  const handlePrev = () => {
      carouselRef.current.prev();
  };

  const handleNext = () => {
      carouselRef.current.next();
  };
  useEffect(() => {
    AOS.init({
      duration: 1500,
      delay: 300,
      disable: false
    });
  }, []);

  const counters = [
    {
      id: 1,
      icon: Count1Img,
      count: <Statistic  value={200} precision={2} formatter={formatter} />,
      label: "Happy Students",
      delay:  "200"
    },
    {
      id: 2,
      icon: Count2Img,
      count: <Statistic  value={569} precision={2} formatter={formatter} />,
      label: "Good Comments",
      delay: "250"
    },
    {
      id: 3,
      icon: Count3Img,
      count:  <Statistic  value={32500} precision={2} formatter={formatter} />,
      label: "Services Downloads",
      delay: "300"
    },
    {
      id: 4,
      icon: Count4Img,
      count: <Statistic  value={32} precision={2} formatter={formatter} />,
      label: "Best Awards",
      delay: "350",
      unit: "k"
    }
  ];
  const blogs = [
    {
        imgSrc: blog1Img,
        categoryImgSrc: ExamImg,
        category: 'Exams',
        title: 'Browse exams offered by Cambridge and Pearson Edexcel centers',
        button:'Find More',
        link : "/exam"
    },
    {
        imgSrc: blog2Img,
        categoryImgSrc: PreparationImg,
        category: 'Exam Preparations',
        title: 'Browse the list of services offered by Cambridge and Pearson Edexcel centers',
        button:'Find More',
        link : "/preparation"
    },
    {
        imgSrc: blog3Img,
        categoryImgSrc: EventImg,
        category: 'Events',
        title: 'Browse events organised in your area',
        button:'Find More',
        link : "/event"
    }
];
const slides = [
  {
      imgSrc: Banner22Img,
      title: 'Welcome',
      subtitle: 'Exam',
      description: 'Book an exam at a center nearest to you.',
      buttonText: 'Find More',
      buttonLink: '/exam',
      align: 'left'
  },
  {
      imgSrc: Banner33Img,
      title: 'Welcome',
      subtitle: 'Exam Preparations',
      description: ' Look out for mock exams, laboratory workshops and exam preparation courses.',
      buttonText: 'Learn More',
      buttonLink: '/preparation',
      align: 'left'
  },
  {
      imgSrc: Banner11Img,
      title: 'Welcome',
      subtitle: 'Event',
      description: ' Look out for mock exams, laboratory workshops and exam preparation courses.',
      buttonText: 'Learn More',
      buttonLink: '/event',
      align: 'right'
  }
];
  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <>
      <section className="main-crausel-section">

        {/* <Carousel>
          <Carousel.Item>
            <img
                className="d-block w-100"
                src={Prepare1Img}
                alt="Exams"
                style={{ height: "600px" }}
              />
            <Carousel.Caption
              className="carousel-caption-custom"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <h1>Exams</h1>
              <p>Book an exam at a center nearest to you.</p>
              <Button variant="primary" onClick={() => navigate("/exam")}>
                Find More
              </Button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
                className="d-block w-100"
                src={Prepare1Img}
                alt="Exam Preparations"
                style={{ height: "600px" }}
              />
            <Carousel.Caption
              className="carousel-caption-custom"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <h1>Exam Preparations</h1>
              <p>
                Look out for mock exams,laboratory workshops and exam
                preparation courses.
              </p>
              <Button
                variant="primary"
                onClick={() => navigate("/preparation")}
              >
                Find More
              </Button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
                className="d-block w-100"
                src={Prepare1Img}
                alt="Events"
                style={{ height: "600px" }}
              />
            <Carousel.Caption
              className="carousel-caption-custom"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <h1>Events</h1>
              <p>Attend the Events organize in your area or online..</p>
              <Button variant="primary" onClick={() => navigate("/event")}>
                Find More
              </Button>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
  <div className="photoslider-section  no-padding">
            <Carousel id="home-slider" controls={false} indicators={false} ref={carouselRef}>
                {slides.map((slide, index) => (
                    <Carousel.Item key={index} className="item">
                        <img
                            className="d-block w-100"
                            src={slide.imgSrc}
                            alt={`photoslider${index + 1}`}
                            width="1920"
                            height="700"
                        />
                         <Carousel.Caption className={`text-${slide.align}`}>
                            <div className="container">
                                <div className={` col-lg-6 col-md-12 col-sm-12 col-xs-12 ow-pull-${slide.align} no-padding`}>
                                    <h4 className="animated bounceInLeft">{slide.title}</h4>
                                    <h3 className="animated fadeInDown">{slide.subtitle}</h3>
                                    <p className="animated bounceInRight">{slide.description}</p>
                                    <a
                                        // href={slide.buttonLink}
                                        onClick={()=> navigate(slide.buttonLink)}
                                        title={slide.buttonText}
                                        className="btn btn-primary animated zoomInUp"
                                    >
                                        {slide.buttonText}
                                    </a>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
            {/* Custom arrow buttons */}
            <a className="left carousel-control" onClick={handlePrev}>
                <i className="fa fa-angle-left"></i>
            </a>
            <a className="right carousel-control" onClick={handleNext}>
                <i className="fa fa-angle-right"></i>
            </a>
        </div>




      </section>
      <section className="for-exam-view-more card-section-3">
        <Container>
        
          <Row className="row justify-content-center" gutter={[24,24]}>
            {blogs.map((blog, index) => (
                <Col span={24} md={12} lg={8} key={index}>
                    <div className="blog-wrap-3 mb-30" >
                        <div className="blog-img">
                            <img src={blog.imgSrc} alt="blog" />
                        </div>
                        <div className="content">
                            <div className="meta">
                                <a href="#">
                                    <img src={blog.categoryImgSrc} alt="icon" />
                                    {blog.category}
                                </a>
                            </div>
                            <h3 className="title">
                                <a
                                 onClick={()=> navigate(blog.link)}
                                //  href={blog.link}
                                >{blog.title}</a>
                            </h3>
                          <div className="theme-btn">
                          <a className="" onClick={()=> navigate(blog.link)}>
                            {blog.button}
                            </a>
                            </div>
                     
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
        </Container>
      </section>
     
      <section className="about-section-3 pt-80 pb-lg-80 pb-md-80 pt-xs-50 pb-xs-50">
   

        <div className="container">
          <Row gutter={[24,24]} className="row align-items-center">
       
            <Col span={24} lg={12} className=" text-center">
              <div className="about-thumb-3">
                <div className="thumb">
                  <img className="w-100" src={AboutNew1Img} alt="thumb" />
                </div>
{/* 
                <div className="round-bg-shape">
                  <img className="img-fluid" src={RoundshapeImg} alt="shape" />
                </div> */}

             
              </div>
            </Col>

            <Col span={24} lg={12}>
              <div className="about-content-3 ps-xxl-5" >
                <div className="section-title">
                  <div className="sub-title">
                    <p>About Our Institute</p>
                  </div>
                  <div className="title">
                    <h2>Welcome to  BookNow!</h2>
                  </div>
                </div>
                <div className="description">
                  <p>Please login or register to:</p>
                </div>
                <div className="list">
                  <ul>
                    <li>
                      <img src={CheckBoxImg} />
                      <span>Register for a Cambridge and Pearson Edexcel exam</span>
                    </li>
                    <li>
                      <img src={CheckBoxImg} />
                      <span>Attend home-schooling seminars, expos or other events</span>
                    </li>
                    <li>
                      <img src={CheckBoxImg} />
                      <span>Attend mock exams, laboratory workshops or crash courses</span>
                    </li>
                    <li>
                      <img src={CheckBoxImg} />
                      <span>350+ Quality Topics</span>
                    </li>
                  </ul>
                </div>
                <div className="theme-btn">
                  {!isLoggedIn ? <a  onClick={() => navigate("/login")} >Sign in as Guardian</a> : ""}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="choose-section-3 overflow-hidden  pt-xs-50 pb-xs-50">
        <div className="container">
          <div className="row align-items-center justify-content-center">
         
            <div className="col-xl-6 col-lg-6">
              <div className="about-us-choose-content" >
                <div className="section-title">
             
                  <div className="title">
                    <h2>Why BookNow?</h2>
                  </div>
                </div>
                <div className="text">
                  <p>Home-schooling can be challenging and it is good to make use of services making this job easier. This site makes it a lot easier to register for Cambridge and Pearson Edexcel exams in South Africa. Consider enrolling for one-day courses presented throughout South Africa by passionate and experienced teachers.</p>
                </div>
                <div className="list">
                  <ul>
                    <li>
                      <img src={CheckBoxImg} />
                      <span>Lab workshops</span>
                    </li>
                    <li>
                      <img src={CheckBoxImg} />
                      <span>Mock Exams</span>
                    </li>
                    <li>
                      <img src={CheckBoxImg} />
                      <span>Crash Courses</span>
                    </li>
                  </ul>
                </div>
                <div className="choose-btn-2">
                  <div className="theme-btn" onClick={()=> navigate("/event")}>
                    <a >Find Events</a>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Image Section */}
            <div className="col-xl-6 col-lg-6 text-center mt-md-50 mt-xs-50">
              <div className="choose-3-thumb">
                <div className="thumb">
                  <img className="w-100" src={WhyChooseImg} alt="thumb" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="career-section-3 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-50">
                <div className="sub-title mb-10" >
                  <p>Choose Your Career</p>
                </div>
                <div className="title" >
                  <h2>Discover Your Gain</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="career-wrap" >
                <div className="thumb">
                  <img src={New1Img} alt="Start From Today" />
                </div>
                <div className="content">
                  <p>Start From Today</p>
                  <h4>
                    <a >Join Our Training Course & Build Your Skill.</a>
                  </h4>
                  <a className="read-more" >Join Now</a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="career-wrap" >
                <div className="thumb">
                  <img src={New2Img} alt="Build Your Career" />
                </div>
                <div className="content">
                  <p>Build Your Career</p>
                  <h4>
                    <a >Educating Your Child Early & Spread Knowledge.</a>
                  </h4>
                  <a className="read-more" >Join Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Index;
