import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Loader from "../components/Loader";
import { useNavigate } from "react-router";

const mapContainerStyle = {
  height: "400px",
};

const center = {
  lat: -26.1076578, // You can use the coordinates of one of the schools as the center
  lng: 27.9077789,
};

const MapComponent = ({ data, image, loading = false }) => {
  const navigate = useNavigate()
  return (
    <div className="map-view-item">
      <LoadScript googleMapsApiKey="AIzaSyDQ2DCe8qS4qVCkMtRZRnZZ_TF2qq1HSvs">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={2}
        >
          {loading ? (
            <Loader />
          ) : (
            data?.map((item, index) => (
              <Marker
                icon={{
                  url: image || "default-marker-url.png",
                }}
                key={index}
                position={{
                  lat: item?.type === "Exam" ?  item?.center_id?.latitude :    item?.latitude,
                  lng: item?.type === "Exam"  ?  item?.center_id?.longitude :  item?.longitude,
                }}
                title={item?.type === "Exam" ? item?.center_id?.name : item?.name}
                onClick={()=> item?.type === "Exam" ?  navigate(`/view-exam/${item?.center_id?._id}/${item?.exam_id?._id}?type=Exam`): 
               navigate(`/view-event-preparation/${item?._id}?type=${item?.type}`)}
              />
            ))
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
