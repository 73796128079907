import { useNavigate } from "react-router";
import apiPath from "../constants/apiPath";
import { useAppContext } from "../context/AppContext";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { useAuthContext } from "../context/AuthContext";

const SettingApis = () => {
  const { request } = useRequest();
  const {setRefreshUser} = useAuthContext()

  const changeStatus = ({ url,payload,setLoading}) => {
    setLoading(true);
    request({
      url: `${url}`,
      method: "POST",
      data : payload,
      onSuccess: (data) => {
        setLoading(false); 
       ShowToast(data.message, Severty.SUCCESS);
       setRefreshUser(() => (prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return { changeStatus };
};

export default SettingApis;
