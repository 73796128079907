import React, { useEffect, useState } from 'react';
import { Button, Drawer, Space } from 'antd';
import ContentApis from '../../services/ContentApis';
import Loader from '../../components/Loader';

const AboutDrawer = ({open ,hide}) => {
  const [data,setData] = useState([])
  const [loading,setLoading] = useState(false)
  const {fetchContentData} = ContentApis()

  const getTerms = ()=>{
    fetchContentData({slug : "terms-conditions", setData : setData, setLoading : setLoading})
  }

 useEffect(()=>{
  getTerms()
 },[])
  return (
    <>
   
      <Drawer
       
        placement="right"
        size={1500}
        width={800}
        onClose={hide}
        open={open}
       
      >
      <div className="about-us-maain">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="about-us-discripton">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data?.description,
                        }}
                      />
                    </div>
                  )}
                </div>
      </Drawer>
    </>
  );
};
export default AboutDrawer;