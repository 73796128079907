import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Card,
  Row,
  Col,
  Divider,
} from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import LogoImg from "../../assets/images/booknow-small-logo.png";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import { Severty, ShowToast } from "../../helper/toast";
import useFirebase from "../../hooks/useFirebase";
import apiPath from "../../constants/apiPath";
import { useAuthContext } from "../../context/AuthContext";
import encryptDecrypt from "../../helper/encryptDecrypt";
import AboutDrawer from "./AboutDrawer";
import GoogleLogin from "./GoogleSignUp";
import FacebookLogin from "./FacebookLogin";

const { Title, Text, Link } = Typography;

const Login = () => {
  const [showLogin, setShowLogin] = useState(true);
  const [showforgot, setShowForgot] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showresetPassword, setShowResetPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAboutDrawer, setShowAboutDrawer] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { fcmToken } = useFirebase();
  const { setIsLoggedIn, setUserProfile } = useAuthContext();

  const onSubmit = (values) => {
    const { email, password } = values;

    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = {
      password,
      device_token: fcmToken,
      device_type: "web",
      type: "Guardian",
    };
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    console.log(payload, "fkjdhkd");

    setLoading(true);
    payload.email = email;
    request({
      url: apiPath.login,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setIsLoggedIn(true);
          console.log("rememberMe", rememberMe);
          if (rememberMe?.target?.checked === true || rememberMe === true) {
            var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
            var passwordEncrypt = encryptDecrypt.encryptPassword(
              values.password
            );

            localStorage.setItem("rememberMe", true);
            localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
            localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          } else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("ykmCe2AYEFTHobn");
            localStorage.removeItem("ouUsippetc8S4Ry");
          }
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));

          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          setTimeout(() => navigate("/"), 200);
          form.resetFields();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleResetPassword = (values) => {
    // Send the OTP to the user's email
    setLoading(true);
    let payload = {
      type: "Guardian",
    };
    payload.email = values.forgot_email;
    request({
      url: apiPath.forgotPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setShowVerifyModal(true);
        setShowForgot(false);
        var emailResetEncrypt = encryptDecrypt.encryptEmail(
          values.forgot_email
        );
        localStorage.setItem("UGwdfdR2uHMM24N", emailResetEncrypt);
        form.resetFields();
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const handleVerifyOTP = (values) => {
    const { email, otp } = values;
    setLoading(true);
    // Verify the OTP entered by the user
    let payload = { type: "Guardian" };
    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;
    payload.otp = otp;
    request({
      url: apiPath.verifyOTP,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        form.resetFields();
        setShowVerifyModal(false);
        setShowResetPassword(true);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleReset = (values) => {
    const { email, newPassword } = values;
    setLoading(true);
    // Reset the password with the new password entered by the user
    let payload = {};

    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;

    payload.password = newPassword;
    request({
      url: apiPath.resetPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        handleSingInHere();
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("ykmCe2AYEFTHobn");
    const savedPassword = localStorage.getItem("ouUsippetc8S4Ry");

    if (savedEmail && savedPassword) {
      var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
      var originalPassword = encryptDecrypt.decryptEmail(savedPassword);

      form.setFieldsValue({
        email: originalEmail,
        password: originalPassword,
        remember: true,
      });
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  }, []);

  const handleSingInHere = () => {
    setShowLogin(true);
    setShowForgot(false);
    setShowVerifyModal(false);
    setShowResetPassword(false);
  };

  const handleRememberMeChange = (checked) => {
    setRememberMe(checked);
  };

  return (
    <>
      <div className="login-container">
        <Row gutter={16}>
          <Col
            span={24}
            md={14}
            lg={16}
            className="auth-container-img-panel p-0 order-2 order-md-1"
          >
            <div className="left-section12">
              <div className="mian-new-class-left">
                <div className="main-loginwelcome7yg">
                  <span>Welcome to</span> booknow!
                </div>
                <p>Please register or login to:</p>
                <div className="features-list">
                  <Text>✔ Register for an exam</Text>
                  <Text>
                    ✔ Attend mock exams, laboratory workshops or crash courses
                  </Text>
                  <Text>
                    ✔ Attend homeschooling seminars, expos and other events
                  </Text>
                </div>
              </div>
            </div>
          </Col>

          <Col
            span={24}
            md={10}
            lg={8}
            className="p-0 main-new-login-section-jh-new54rf order-1 order-md-2"
          >
            <div className="login-card-width90">
              <div className="login-card-width90mainh">
                <div className="main-new-logo">
                  <img src={LogoImg} />
                </div>
                {showLogin && (
                  <Card className="login-card">
                    <Title level={3}>User Login</Title>
                    <Form
                      name="login"
                      onFinish={onSubmit}
                      layout="vertical"
                      form={form}
                    >
                      <Form.Item
                        className="username"
                        label={"Email Address"}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                          {
                            max: 255,
                            message:
                              "Email address not more then 255 characters!",
                          },
                          {
                            required: true,
                            message: "Please enter your email!",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter Email Address"} />
                      </Form.Item>
                      <Form.Item
                        className="password"
                        label={"Password"}
                        name="password"
                        rules={[
                          {
                            max: 255,
                            message:
                              "Password should contain more then 255 characters!",
                          },
                          {
                            required: true,
                            message: "Please enter your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          onCut={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"
                          placeholder={"Enter Password"}
                        />
                      </Form.Item>
                      <div className="forgot-password-box">
                        <Form.Item name="remember" valuePropName="checked">
                          <Checkbox
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                          >
                            {"Remember me"}
                          </Checkbox>
                        </Form.Item>
                        <span className="main-new-forgot-password-linkjhgb">
                          <Link
                            onClick={() => {
                              setShowForgot(true);
                              setShowLogin(false);
                            }}
                          >
                            Forgot Password?
                          </Link>
                        </span>
                      </div>
                      <Form.Item className="login-page-login-button-from-item">
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          loading={loading}
                          disabled={loading}
                        >
                          Login
                        </Button>
                      </Form.Item>
                      <div className="main-new-guest-page-class" onClick={()=>navigate("/")}>
                      Continue as a guest
                      </div>
                      <div className="social-login-mainbox">                     
                     <Button className="social-login-btn" onClick={(e) => e.preventDefault()} >
                        <GoogleLogin   />
                       </Button>
                       <Button className="social-login-btn" onClick={(e) => e.preventDefault()} >
                        <FacebookLogin   />
                       </Button>
                       </div>

                      <div className="space-line">
                        <span>OR</span>
                      </div>
                      <Form.Item className="main-login-dont-have-account7yh">
                        <h3>
                          Don't have an account?{" "}
                          <Link onClick={() => navigate("/register")}>
                            Sign Up
                          </Link>
                        </h3>
                      
                      </Form.Item>
                    </Form>

                    <div className="main-new-termsand-condition">
                      <Link
                        className="terms"
                        onClick={() => setShowAboutDrawer(true)}
                      >
                        Show Terms and Conditions
                      </Link>
                    </div>
                  </Card>
                )}
                {showforgot && (
                  <Card className="login-card">
                    <Title level={3}>Forgot Password</Title>
                    <Form
                      name="forgot-password"
                      form={form}
                      layout="vertical"
                      onFinish={handleResetPassword}
                    >
                      <Form.Item
                        className="username"
                        label={"Email Address"}
                        name="forgot_email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                          {
                            max: 255,
                            message:
                              "Email address not more then 255 characters!",
                          },
                          {
                            required: true,
                            message: "Please enter your email!",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter Email Address"} />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          loading={loading}
                          disabled={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                      <Form.Item className="main-login-dont-have-account7yh">
                        <h3>
                          Already have an account?{" "}
                          <Link
                            onClick={() => {
                              setShowForgot(false);
                              setShowLogin(true);
                            }}
                          >
                            Login
                          </Link>
                        </h3>
                      </Form.Item>
                    </Form>
                  </Card>
                )}
                {showVerifyModal && (
                  <Card className="login-card">
                    <Title level={3}>Verify OTP</Title>
                    <Form
                      name="verify-otp"
                      form={form}
                      layout="vertical"
                      onFinish={handleVerifyOTP}
                    >
                      <Form.Item
                        label={"OTP"}
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the OTP",
                          },
                          {
                            len: 4,
                            message: "OTP must be 4 digits",
                          },
                        ]}
                      >
                        <Input
                          autoComplete="off"
                          type="number"
                          maxLength={4}
                          placeholder={"Enter OTP"}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          loading={loading}
                          disabled={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                      <Form.Item className="main-login-dont-have-account7yh">
                        <h3>
                          Already have an account?{" "}
                          <Link
                            onClick={() => {
                              handleSingInHere();
                            }}
                          >
                            Login
                          </Link>
                        </h3>
                      </Form.Item>
                    </Form>
                  </Card>
                )}
                {showresetPassword && (
                  <Card className="login-card">
                    <Title level={3}>Reset Password</Title>
                    <Form
                      name="verify-otp"
                      form={form}
                      layout="vertical"
                      onFinish={handleReset}
                    >
                      <Form.Item
                        label={"New Password"}
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your new password!",
                          },
                          {
                            pattern: new RegExp(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                            ),
                            message:
                              "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                          },
                        ]}
                      >
                        <Input.Password placeholder={"Enter New Password"} />
                      </Form.Item>
                      <Form.Item
                        label={"Confirm New Password"}
                        name="confirm_new_password"
                        dependencies={["newPassword"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please enter the confirm password!",
                          },
                          {
                            pattern: new RegExp(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                            ),
                            message:
                              "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("newPassword") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Password that you entered doesn't match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder={"Enter Confirm Password"}
                        />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          loading={loading}
                          disabled={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                     
                      <Form.Item className="main-login-dont-have-account7yh">
                        <h3>
                          Already have an account?{" "}
                          <Link
                            onClick={() => {
                              handleSingInHere();
                            }}
                          >
                            Login
                          </Link>
                        </h3>
                      </Form.Item>
                    </Form>
                  </Card>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <Footer /> */}
      {showAboutDrawer && (
        <AboutDrawer
          open={showAboutDrawer}
          hide={() => setShowAboutDrawer((prev) => !prev)}
        />
      )}
    </>
  );
};

export default Login;
