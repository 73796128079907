import React, { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";
import apiPath from "../constants/apiPath";
import axios from "axios";

export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {

  const [pageHeading, setPageHeading] = useState("Dashboard");
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [refreshNotification, setRefreshNotification] = useState(false);
  const { userProfile } = useAuthContext()
 
  useEffect(() => {
    setLoading(true)
    let lang = localStorage.getItem("languageSet")
    lang = lang ? lang : 'en';

    let darkTheme = JSON.parse(localStorage.getItem("darkTheme"))
    darkTheme = darkTheme ? darkTheme : false;
  
 

    if (lang == "ar") {
      import('../assets/styles/rtl.css')
    } else {
      if (darkTheme == true) {
        import("../assets/styles/darkTheme.css");
      }else{
        import('../assets/styles/main.css')
      }
    }
    setTimeout(() => setLoading(false), 200)
    setLanguage(lang)
  }, [])

  const getNotification = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(apiPath.baseURL + "app/notification/list", {
        headers,
      });
      const data = response.data
      if (data) {  
        console.log(data,"notidata") 
        setNotification(data.data);
        let total = data?.data?.length ? data?.data?.filter((item)=> item?.is_read === false) : 0
        setNotificationCount(total?.length)
        setRefreshNotification(false)    
      }

      } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!userProfile) return;   
      getNotification()
  }, [userProfile,refreshNotification]);

  return (
    <AppStateContext.Provider
      value={{
        pageHeading,
        setPageHeading,
        language,
        setLanguage,
        notification,
        notificationCount,
        setRefreshNotification
      }}
    >
      {loading ? null : children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppStateContext);
};

