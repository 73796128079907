import React, { useState } from "react";
import { Input, List, Pagination, Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileOutlined,
  MoreOutlined,
} from "@ant-design/icons";

const ResultHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 15;

  // Mock Data for the list of documents
  const data = Array(125).fill({
    title: "AJohnson_Montessori College_23.PDF",
    description: "B6/R28/FEB/21/1 7/14/2023 1:40 AM",
  });

  // Calculate the documents to show based on pagination
  const paginatedData = data.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <EyeOutlined /> Preview
      </Menu.Item>
      <Menu.Item key="2">
        <DownloadOutlined /> Download
      </Menu.Item>
      <Menu.Item key="3">
        {" "}
        <DeleteOutlined /> Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <section className="main-new-result-page">
      <div className="container">
        <div className="header-result-page">
          <h2>Result History</h2>
          <Input.Search placeholder="Search Document" style={{ width: 300 }} />
        </div>

        <div className="filesCount">
          <h1>All Files</h1>
          <h3>120 Files</h3>
        </div>

        <div>
          <List
            itemLayout="horizontal"
            dataSource={paginatedData}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <MoreOutlined style={{ fontSize: "20px" }} />
                  </Dropdown>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <FileOutlined
                      style={{ fontSize: "18px", color: "#00BAB0" }}
                    />
                  }
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />

          <div className="main-new-result-page-pagination">
            <Pagination
              current={currentPage}
              total={data.length}
              pageSize={pageSize}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
              showQuickJumper
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultHistory;
