import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card, Button, Select, Image } from "antd";
import Footer from "../../components/layout/Footer";
import LocationMap from "../../modals/LocationMap";
import FetchDataApis from "../../services/FetchDataApis";
import apiPath from "../../constants/apiPath";
import NodataImg from "../../assets/images/no-data.png";
import useDebounce from "../../hooks/useDebounce";
import SectionWrapper from "../../components/SectionWrapper";
import moment from "moment";
import notfound from "../../assets/images/not_found.png";
import DirectionButton from "../../assets/images/directions_alt.svg";
import ExamImgNew from "../../assets/images/exam.png";
import ExamImgNew1212 from "../../assets/images/Exam-maps2.png";
import Loader from "../../components/Loader";

import { ArrowLeftOutlined, CalendarOutlined, UndoOutlined } from "@ant-design/icons";
import useRequest from "../../hooks/useRequest";
import { eventType } from "../../constants/var";
import { useNavigate } from "react-router-dom";
import { Severty, ShowToast } from "../../helper/toast";
import { useAuthContext } from "../../context/AuthContext";
import { useMediaQuery } from "react-responsive";

const Index = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [allTypes, setAllTypes] = useState(eventType);
  const [allCenter, setAllCenters] = useState([]);
  const [allCources, setAllCources] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [show, setShow] = useState(false);
  const isMobile =useMediaQuery({maxWidth:991});
  const { isLoggedIn } = useAuthContext();
  const { request } = useRequest();
  const [filter, setFilter] = useState({
    type: undefined,
    center: undefined,
    course: undefined,
    syllabus: undefined,
  });

  const api = {
    list: apiPath.listExams,
    center: apiPath.centerList,
    courseList: apiPath.courseList,
    syllabusList: apiPath.syllabusList,
  };
  const { fetchData } = FetchDataApis();
  const navigate = useNavigate();

  const getData = (pagination) => {
    fetchData({
      url: api.list,
      pagination: pagination,
      filter: filter,
      onFatch: handleSetData,
      setLoading: setLoading,
      debouncedSearchText: debouncedSearchText,
      setPagination: setPagination,
    });
  };

  const getCenter = () => {
    request({
      url: `${api.center}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setAllCenters(data?.list ?? []);
      },
    });
  };

  const getCourse = () => {
    request({
      url: `${api.courseList}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setAllCources(data?.list ?? []);
      },
    });
  };

  const getsyllabus = (id) => {
    request({
      url: `${api.syllabusList}/${id}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setSyllabus(data?.list?.docs ?? []);
      },
    });
  };

  const handleSetData = (value) => {
    console.log(value, "data");
    setList(value?.list?.docs);
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
      // total: total ? total : list?.docs?.length ,
    }));
  };

  useEffect(() => {
    getData(pagination);
    getCenter();
    getCourse();
  }, [filter]);

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      
      <section className="main-graph-exam-page">
        <Row gutter={[24, 24]} className="mb-5">
          <Col span={24} lg={10} className="main-google-map-class">
            <Form.Item name="location">
              <LocationMap
                className="mt-3"
                data={list?.length ? list : []}
                loading={loading}
                image={ExamImgNew1212}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={14}>
            <Form
              layout="vertical"
              className="main-new-class-juhygt-map-text-mainh"
            >
              <div className="main-new-class-juhygt-map-text123">
                <section className="">
                  <Row gutter={16}>
                    <div className="main-new-class-juhygt-map-text">
                      <SectionWrapper
                        cardHeading={`All Exams`}
                        extra={
                          <>
                            <div className="w-100 text-head_right_cont">
                              <div className="pageHeadingSearch">
                                <div className="role-wrap">
                                  <Select
                                    last20Years
                                    width="110px"
                                    placeholder={"Select Center"}
                                    showSearch
                                    value={filter.center}
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={allCenter?.map((item) => ({
                                      value: item._id,
                                      label: item.name,
                                    }))}
                                    onChange={(value) =>
                                      onChange("center", value)
                                    }
                                  />
                                </div>
                                <div className="role-wrap">
                                  <Select
                                    last20Years
                                    width="110px"
                                    placeholder={"Select Course"}
                                    showSearch
                                    value={filter.course}
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={allCources?.map((item) => ({
                                      value: item._id,
                                      label: item.name,
                                    }))}
                                    onChange={(value) => {
                                      onChange("course", value);
                                      setFilter((prev) => ({ ...prev, "syllabus": undefined }));
                                      getsyllabus(value);
                                    }}
                                  />
                                </div>
                                {syllabus?.length ? (
                                  <div className="role-wrap">
                                    <Select
                                      last20Years
                                      width="110px"
                                      placeholder={"Select syllabus"}
                                      showSearch
                                      value={filter.syllabus}
                                      filterOption={(input, option) =>
                                        option.label
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={syllabus?.map((item) => ({
                                        value: item._id,
                                        label: item.name,
                                      }))}
                                      onChange={(value) =>
                                        onChange("syllabus", value)
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <Button
                                onClick={() => {
                                  setFilter({
                                    type: undefined,
                                    center: undefined,
                                    syllabus: undefined,
                                    course: undefined,
                                  });
                                  setSyllabus([]);
                                }}
                                type="primary"
                                icon={<UndoOutlined />}
                              >
                                {"Reset"}
                              </Button>
                            </div>
                          </>
                        }
                      >
                        <div className="exam-height-fixed">
                        <Row gutter={[0, 16]}>
                          {loading ? (
                            <Col span={24}>
                              <Loader />
                            </Col>
                          ) : list?.length ? (
                            list.map((item) => (
                              <Col span={24} md={24} xxl={24} key={item?._id}>
                                <Card className="center-card" bordered={true}>
                                  <div className="main-container">
                                    <div className="main-new-banner">
                                      <div className="main-new-banner-image">
                                        <Image
                                          src={
                                            item?.center_id?.image || notfound
                                          }
                                          alt="Tutors & Exams Logo"
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>

                                    <div className="main-preparation-class">
                                      <div className="main-preparation-class-heading main-preparation-class-heading-secong-h">
                                        <div className="mini-logo-exam-img-main-div">
                                          <div className="mini-logo-exam-img">
                                            <img
                                              src={ExamImgNew}
                                              alt="Exam Logo"
                                            />
                                          </div>
                                          {item?.exam_id?.name || "-"}
                                        </div>
                                        {item?.center_id?.web_url && (
                                          <div className="main-new-view-exams-web">
                                            <a
                                              onClick={() =>
                                                window.open(
                                                  item?.center_id?.web_url,
                                                  "_blank"
                                                )
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="visit-button main-new-favrated-button-exam"
                                            >
                                              <i className="fas fa-external-link-alt"></i>
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                      <div className="main-preparation-class-heading">
                                        <img
                                          src={DirectionButton}
                                          alt="Direction Button"
                                        />
                                        <h2>
                                          {item?.exam_id?.exam_code || "-"}
                                        </h2>
                                      </div>
                                      <div className="main-preparation-class-heading">
                                        <img
                                          src={DirectionButton}
                                          alt="Direction Button"
                                        />
                                        <h2>{item?.center_id?.name || "-"}</h2>
                                      </div>

                                     

                                      
                                      <div className="main-preparation-class-heading">
                                        <img
                                          src={DirectionButton}
                                          alt="Direction Button"
                                        />
                                        <h2>
                                          Booking{" "}
                                          {item?.exam_id?.start_date || "-"} -{" "}
                                          {item?.exam_id?.end_date || "-"}
                                        </h2>
                                      </div>
                                      <p className="main-new-case-class">
                                        <Button
                                          type="primary"
                                          onClick={() => {
                                            isLoggedIn
                                              ? navigate(
                                                  `/view-exam/${item?.center_id?._id}/${item?.exam_id?._id}?type=Exam`
                                                )
                                              : ShowToast(
                                                  "Please login first",
                                                  Severty.WARNING
                                                );
                                          }}
                                        >
                                          View
                                        </Button>
                                      </p>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))
                          ) : (
                            <Col span={24}>
                              <div className="no-data-image">
                                <img src={NodataImg} alt="No Data" />
                              </div>
                            </Col>
                          )}
                        </Row>
                        </div>
                      </SectionWrapper>
                    </div>
                  </Row>
                </section>
              </div>
            </Form>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Index;
