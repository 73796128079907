import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";

import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { Accordion } from "react-bootstrap";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";
import ContentApis from "../../services/ContentApis";
import { useMediaQuery } from "react-responsive";

window.Buffer = window.Buffer || require("buffer").Buffer;

function HowWorks() {
  const { request } = useRequest();
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { fetchContentData } = ContentApis();

  const getAbouts = () => {
    fetchContentData({
      slug: "how-it-works",
      setData: setData,
      setLoading: setLoading,
    });
  };

  useEffect(() => {
    setLoading(true);
    getAbouts();
  }, []);
  return (
    <>
      <section className="main container-fluid">
        <div className="account-outers">
          <Row>
            {isMobile ? <BackArrow /> : <AccountSideNav />}
            <Col span={24} lg={16} xl={15} className="mx-auto  amt-auto">
              <div className="privecy-main-discription-1">
                <div className="prvecy-main-discription">
                  <div className="main-new-remember-classes-main-scroll-class">
                  {loading ? (
                    <Loader />
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.description,
                      }}
                    />
                  )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default HowWorks;
