import {
  ArrowLeftOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import apiPath from "../../constants/apiPath";

import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { PhoneNumberField } from "../../components/InputField";
import Loader from "../../components/Loader";
import UploadProfileImage from "../../components/UploadProfileImage";
import dayjs from "dayjs";
import ConfirmationBox from "../../components/ConfirmationBox";

window.Buffer = window.Buffer || require("buffer").Buffer;

function AccountIndex() {
  const { request } = useRequest();
  const isMobile = useMediaQuery({ maxWidth: 991 });
  // const { isMobile } = useAppContext();
  const {
    setIsLoggedIn,
    setUserProfile,
    userProfile,
    setRefreshUser,
    isLoggedIn,
  } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const { ShowConfirmBox } = ConfirmationBox();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleImage = (data) => {
    if (data.length && data.length > 0) onUpdate(data[0].url);
  };

  const onUpdate = (image) => {
    let payload = {};
    payload.image = image;
    setLoading(true);
    request({
      url: `${apiPath?.updateProfileImage}`,
      method: "POST",
      data: payload,
      onSuccess: async (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setRefreshUser(true);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
  }, []);

  return (
    <>
      <section className="main container-fluid">
        <div className="account-outers">
          <Row>
            {isMobile ? <BackArrow /> : <AccountSideNav />}

            <Col span={24} lg={16} xxl={15} className="mx-auto  amt-auto">
              <div className="my-pro-page">
                <h1>{lang("Account Info")}</h1>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="accout-info-orm">
                    <div className="accout-main-44">
                      <div className="account-prof-mg">
                        <div className="main-profilr-fff">
                          <Image
                            src={
                              userProfile?.image ? apiPath.profileBaseUrl + userProfile?.image : Prouser
                            }
                            alt=""
                          />

                          {/* {!loading ? (
                            <UpdateProfileImage
                              fileType={FileType}
                              imageType={"image"}
                              btnName={""}
                              className="edit-iconee"
                              onChange={(data) => handleImage(data)}
                              isDimension={false}
                            />
                          ) : (
                            ""
                          )} */}
                        </div>

                        <h3>
                          {userProfile?.first_name && userProfile?.last_name
                            ? `${userProfile?.first_name} ${userProfile?.last_name}`
                            : userProfile?.name}
                        </h3>
                        <h4>{userProfile?.email ? userProfile.email : "-"} </h4>
                      </div>
                      <div className="account-form-discr">
                        <div className="list-main-innfor">
                          <h3>{lang("Name")}</h3>
                          <h5>
                            {userProfile?.first_name && userProfile?.last_name
                              ? `${userProfile?.first_name} ${userProfile?.last_name}`
                              : userProfile?.name}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("Email")} </h3>
                          <h5>
                            {" "}
                            {userProfile?.email
                              ? userProfile?.email
                              : "N/A"}{" "}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("Mobile number")} </h3>
                          <h5>
                            {" "}
                            {userProfile?.country_code &&
                            userProfile?.mobile_number
                              ? `+${userProfile?.country_code}-${userProfile?.mobile_number}`
                              : "N/A"}{" "}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("DOB")}</h3>
                          <h5>{userProfile?.dob ? userProfile?.dob : "-"} </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("Gender")}</h3>
                          <h5>
                            {" "}
                            {userProfile?.gender ? userProfile?.gender : "-"}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="edit-profille">
                      <button
                        onClick={() => {
                          setShow(true);
                          setSelected(userProfile);
                        }}
                      >
                        {lang("Edit Profile")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {setShow && (
        <AddForm
          show={show}
          hide={() => {
            setShow(false);
            setSelected();
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
}

const AddForm = ({ show, hide, data, refresh }) => {
  const { setIsLoggedIn, setUserProfile, userProfile, setRefreshUser } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();

  const { request } = useRequest();

  const onCreate = (values) => {
    const { dob } = values;
    let payload = { ...values };
    payload.dob = dob ? moment(dob).format("YYYY-MM-DD") : null;
    payload.mobile_number = mobileNumber?.mobile_number;
    payload.country_code = mobileNumber?.country_code;
    payload.image = image;
    // return console.log(payload,"payload");
    setLoading(true);
    request({
      url: `${apiPath?.editProfile}`,
      method: "POST",
      data: payload,
      onSuccess: async (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          console.log(data, "data....");
          setRefreshUser(() => (prev) => !prev);
          hide();
          if (refresh) refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    console.log("data", data);

    form.setFieldsValue({
      ...data,
      country_id: data?.country_id?._id,
      dob: data.dob ? moment(data?.dob) : "",
      mobile: data?.country_code + data?.mobile_number || "",
    });

    setImage(data.image);
    setMobileNumber({
      mobile_number: data?.mobile_number,
      country_code: data?.country_code,
    });
  }, [data]);

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value?.slice(data?.dialCode?.length),
    });
  };
  return (
    <>
      <Modal
        visible={show}
        // title="Forgot Password"
        onCancel={hide}
        title="Basic Modal"
        width={749}
        okText="Submit"
        cancelText="Back"
        className="noevent-model "
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
        footer={false}
      >
        <div className="no-active_event">
          <div className="event-backgg-header">
            <h2>{lang("Edit Profile")}</h2>
          </div>
          <div className="noevent-text">
            <Form
              className="noevent-maain-model row"
              id="create"
              form={form}
              onFinish={onCreate}
              layout="vertical"
            >
              <Row gutter={16}>
                <Col span={24}>
                  <div className="text-center">
                    <Form.Item
                      className="upload_wrap"
                      // rules={[
                      //   {
                      //     validator: (_, value) => {
                      //       if (image) {
                      //         return Promise.resolve();
                      //       }
                      //       return Promise.reject(
                      //         new Error(lang("Profile image is required"))
                      //       );
                      //     },
                      //   },
                      // ]}
                      name="image"
                    >
                      <UploadProfileImage
                        preview={false}
                        value={image}
                        setImage={setImage}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    className="username"
                    name="first_name"
                    rules={[
                      {
                        max: 250,
                        message:
                          "Name should contain more then 250 characters!",
                      },
                      {
                        required: true,
                        message: "Please Enter  Name",
                      },
                    ]}
                  >
                    <Input placeholder={"Enter First Name"} />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    className="username"
                    name="last_name"
                    rules={[
                      {
                        max: 250,
                        message:
                          "Name should contain more then 250 characters!",
                      },
                      {
                        required: true,
                        message: "Please Enter  Name",
                      },
                    ]}
                  >
                    <Input placeholder={"Enter Last Name"} />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    className="username"
                    // label={lang("Email Address")}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                      {
                        max: 255,
                        message: "Email address not more then 255 characters!",
                      },
                      {
                        required: true,
                        message: "Please enter email!",
                      },
                    ]}
                  >
                    <Input placeholder={"Enter Email address"} />
                  </Form.Item>
                </Col>

                <Col span={24} md={12} className="p-0">
                  <PhoneNumberField
                    label={""}
                    name="mobile"
                    placeholder={"Enter Phone Number"}
                    cover={{ md: 24 }}
                    colProps={{ sm: 24, span: 24 }}
                    className="new-mobile-passwordnew username"
                    onChange={handleMobileNumberChange}
                    number={mobileNumber?.mobile_number}
                  />
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="dob"
                    className="username"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter dob!"),
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={(current) =>
                        current && current >= dayjs().endOf("day")
                      }
                      placeholder={lang("DOB")}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    // label={lang("Gender")}
                    className="filtter-select"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: lang("Please select the gender!"),
                      },
                    ]}
                  >
                    <Select
                      className="form-select"
                      placeholder={"Select Gender"}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      <Select.Option value="Male">{"Male"} </Select.Option>
                      <Select.Option value="Female">{"Female"} </Select.Option>
                      <Select.Option value="Prefer not to specify">
                        Prefer not to specify{" "}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <div className="submit-button-mainnn">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-primary-ft mx-auto "
                  loading={loading}
                >
                  {lang("Submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow">
      <div onClick={() => navigate("/account-menu")} className="sidebar-btn">
        <ArrowLeftOutlined />
      </div>
    </div>
  );
};

export default AccountIndex;
