import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card, Button, Select, Image } from "antd";
import Footer from "../../components/layout/Footer";
import LocationMap from "../../modals/LocationMap";
import FetchDataApis from "../../services/FetchDataApis";
import apiPath from "../../constants/apiPath";
import NodataImg from "../../assets/images/no-data.png";
import useDebounce from "../../hooks/useDebounce";
import SectionWrapper from "../../components/SectionWrapper";
import moment from "moment";
import DirectionButton from "../../assets/images/directions_alt.svg";
import notfound from "../../assets/images/not_found.png";
import prepImgNew from "../../assets/images/preparation.png";
import prepImgNew123 from "../../assets/images/Preparations-maps2.png";
import Loader from "../../components/Loader";

import { ArrowLeftOutlined, CalendarOutlined, UndoOutlined } from "@ant-design/icons";
import useRequest from "../../hooks/useRequest";
import { eventType } from "../../constants/var";
import { useNavigate } from "react-router-dom";
import AuthApis from "../../services/AuthApis";
import { Severty, ShowToast } from "../../helper/toast";
import { useAuthContext } from "../../context/AuthContext";
import { useMediaQuery } from "react-responsive";

const Index = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [allTypes, setAllTypes] = useState(eventType);
  const [allCenter, setAllCenters] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [show, setShow] = useState(false);
  const isMobile =useMediaQuery({maxWidth:991});
  const { isLoggedIn } = useAuthContext();
  const { request } = useRequest();
  const [filter, setFilter] = useState({
    type: undefined,
    center: undefined,
  });

  const api = {
    delete: apiPath.deleteStudent,
    list: apiPath.bookPreparationList,
    center: apiPath.centerList,
    preparationMapData: apiPath.preparationMapData,
  };
  const { fetchData } = FetchDataApis();
  const navigate = useNavigate();

  const getData = (pagination) => {
    fetchData({
      url: api.list,
      pagination: pagination,
      filter: filter,
      onFatch: handleSetData,
      setLoading: setLoading,
      debouncedSearchText: debouncedSearchText,
      setPagination: setPagination,
    });
  };

  const getCenter = () => {
    request({
      url: `${api.center}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setAllCenters(data?.list ?? []);
      },
    });
  };

  const getMapData = () => {
    request({
      url: `${api.preparationMapData}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setMapData(data ?? []);
      },
    });
  };

  const handleSetData = (value) => {
    console.log(value, "data");
    setList(value?.list?.docs);
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
      // total: total ? total : list?.docs?.length ,
    }));
  };

  useEffect(() => {
    getData(pagination);
    getCenter();
    getMapData();
  }, [filter]);

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
 
      <section className="main-graph-exam-page">
        <Row gutter={[24, 24]} className="mb-5">
          <Col span={24} lg={10} className="main-google-map-class">
            <Form.Item name="location">
              <LocationMap
                className="mt-3"
                data={list?.length ? list : []}
                loading={loading}
                image={prepImgNew123}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={14}>
            <Form
              layout="vertical"
              className="main-new-class-juhygt-map-text-mainh"
            >
              <div className="main-new-class-juhygt-map-text123">
                <section className="">
                  <Row gutter={16}>
                    <div className="main-new-class-juhygt-map-text">
                      <SectionWrapper
                        cardHeading={`All Preparations`}
                        extra={
                          <>
                            <div className="w-100 text-head_right_cont">
                              <div className="pageHeadingSearch">
                                <div className="role-wrap">
                                  <Select
                                    last20Years
                                    width="110px"
                                    placeholder={"Select Type"}
                                    showSearch
                                    value={filter.type}
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={allTypes?.map((item) => ({
                                      value: item.value,
                                      label: item.label,
                                    }))}
                                    onChange={(value) =>
                                      onChange("type", value)
                                    }
                                  />
                                </div>
                                <div className="role-wrap">
                                  <Select
                                    last20Years
                                    width="110px"
                                    placeholder={"Select Center"}
                                    showSearch
                                    value={filter.center}
                                    filterOption={(input, option) =>
                                      option.label
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={allCenter?.map((item) => ({
                                      value: item._id,
                                      label: item.name,
                                    }))}
                                    onChange={(value) =>
                                      onChange("center", value)
                                    }
                                  />
                                </div>
                              </div>
                              <Button
                                onClick={() => {
                                  setFilter({
                                    type: undefined,
                                    center: undefined,
                                  });
                                }}
                                type="primary"
                                icon={<UndoOutlined />}
                              >
                                {"Reset"}
                              </Button>
                            </div>
                          </>
                        }
                      >
                              <div className="exam-height-fixed">
                        <Row gutter={[0, 16]}>
                          {loading ? (
                            <Loader />
                          ) : list?.length ? (
                            list.map((item) => (
                              <Col span={24} md={24} xxl={24}>
                                <Card
                                  className="center-card"
                                  bordered={true}
                                  key={item?._id}
                                >
                                  <div className="main-container">
                                    <div className="main-new-banner">
                                      <div className="main-new-banner-image">
                                        <Image
                                          src={item?.image || notfound}
                                          alt="Tutors & Exams Logo"
                                          style={{ width: "100%" }}
                                        />
                                      </div>
                                    </div>

                                    <div className="main-preparation-class">
                                      <div className="main-preparation-class-heading main-preparation-class-heading-secong-h">
                                        <div className="mini-logo-exam-img-main-div">
                                          
                                          <div className="mini-logo-exam-img mini-logo-exam-img-for-preparation">
                                            <img
                                              src={prepImgNew}
                                              alt="Preparation Logo"
                                            />
                                          </div>
                                          {item?.name || "-"}
                                        </div>
                                        <h3>
                                          {item?.booking_fees
                                            ? `R ${item?.booking_fees}`
                                            : ""}
                                        </h3>
                                      </div>

                                      {/* Event Code */}
                                      {item?.event_code ?  <div className="main-preparation-class-heading">
                                        <img
                                          src={DirectionButton}
                                          alt="Direction Button"
                                        />
                                        <h2>{item?.event_code || ""}</h2>
                                      </div> : ""}

                                      {/* Center Name */}
                                      {item?.center_id?.name ? <div className="main-preparation-class-heading">
                                        <img
                                          src={DirectionButton}
                                          alt="Direction Button"
                                        />
                                        <h2>{item?.center_id?.name || ""}</h2>
                                      </div> : ""}

                                      {/* Booking Start Date */}
                                     {item?.booking_start_date ? <div className="main-preparation-class-heading">
                                        <img
                                          src={DirectionButton}
                                          alt="Direction Button"
                                        />
                                        <h2>
                                          {item?.booking_start_date ? (
                                            <>
                                              <CalendarOutlined />{" "}
                                              {moment(
                                                item.event_start_date
                                              ).format("DD-MMM-YYYY hh:mm a")}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </div> : ""}

                                      <p className="main-new-case-class">
                                        <Button
                                          type="primary"
                                          onClick={() => {
                                            isLoggedIn
                                              ? navigate(
                                                  `/view-event-preparation/${item?._id}?type=Preparation`
                                                )
                                              : ShowToast(
                                                  "Please login first",
                                                  Severty.WARNING
                                                );
                                          }}
                                        >
                                          View
                                        </Button>
                                      </p>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))
                          ) : (
                            <Col span={24}>
                              <div className="no-data-image">
                                <img src={NodataImg} alt="No Data" />
                              </div>
                            </Col>
                          )}
                        </Row>
                        </div>
                      </SectionWrapper>
                    </div>
                  </Row>
                </section>
              </div>
            </Form>
          </Col>
        </Row>
      </section>
    </>
  );
};
export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow fillter-sidebar-back-arrow-notification125">
      <div onClick={( ) => navigate("/account-menu")} className="sidebar-btn">
      <ArrowLeftOutlined />
    </div>
    </div>
  );
};
export default Index;
