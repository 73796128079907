import { Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, useAuthContext } from "../context/AuthContext";
import UserlogoImg from "../assets/images/user (1).png";
import AboutusImg from "../assets/images/information-button.png";
import HowWorkImg from "../assets/images/user-guide.png";
import PrivecyImg from "../assets/images/insurance.png";
import UpcomingImg from "../assets/images/upcoming.png";
import TransactionImg from "../assets/images/trade.png";
import SettingImg from "../assets/images/settings.png";
import DeleteImg from "../assets/images/delete (1).png";
import LogoutImg from "../assets/images/logout (3).png";
import ChangePassword from "../assets/images/padlock.png";
import RightIcon from "../assets/images/right-errow.png";
import User01 from "../assets/images/side_nav/user.svg";
import Wallet from "../assets/images/side_nav/wallet.svg";
import Timeuser from "../assets/images/side_nav/time.svg";
import Notification from "../assets/images/side_nav/basil_notification.svg";
import UserPlus from "../assets/images/side_nav/user-plus.svg";
import Securityimg from "../assets/images/side_nav/securitymargin.svg";
import Paymentmethod from "../assets/images/side_nav/paymentmethod.svg";
import Autobuy from "../assets/images/side_nav/autobuy.svg";
import UserIcon from "../assets/images/side_nav/user.svg";

import { Container } from "react-bootstrap";
import { ShowToast, Severty } from "../helper/toast";
import useRequest from "../hooks/useRequest";
// import OtpModal from "../modals/OtpModal";
import apiPath from "../constants/apiPath";
import lang from "../helper/langHelper";
import ConfirmationBox from "./ConfirmationBox";
import Logout from "../assets/images/logout-outlined.svg";
import DeleteAccountOtp from "../modals/DeleteAccountOtp";

const AccountSideNav = ({}) => {
  const location = useLocation();
  const { request } = useRequest();
  const [show, setShow] = useState(false);
  const { logout, setUserProfile, setIsLoggedIn, isLoggedIn } =
    useContext(AuthContext);
  const { userProfile } = useAuthContext();
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const navigate = useNavigate();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const { ShowConfirmBox } = ConfirmationBox();
  const showDeleteConfirm = (record) => {
    navigate("/login");
    logout();
  };

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
  }, []);

  const handleDeleteAccount = () => {
    let payload = {};
    request({
      url: apiPath.deleteAccount,
      method: "GET",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          setShowOtpModal(true);
          ShowToast("Otp send successfully", Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <Col span={24} lg={6} className="d-none d-lg-block account-sidebar">
        <div className="side-nav-main side-nav-main-for-web">
          <div className="fillter-sidebar">
            <Link className="sidebar-btn" onClick={() => setShow(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M9 19H7V5h2zm4-14h-2v14h2zm4 0h-2v14h2z"
                />
              </svg>
            </Link>
          </div>
          <div className={show ? "sideshow" : "sidehide"}>
            <div className="side-nav">
              <ul>
                <li
                  className={`nav-items ${
                    location.pathname === "/account" ? "active" : ""
                  }`}
                >
                  <Link to="/account">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={UserlogoImg} />
                    </div>
                    {lang("My profile")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/my-booking" ? "active" : ""
                  }`}
                >
                  <Link to="/my-booking">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={UpcomingImg} />
                    </div>
                    {lang("My Booking")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/upcoming-booking" ? "active" : ""
                  }`}
                >
                  <Link to="/upcoming-booking">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={UpcomingImg} />
                    </div>
                    {lang("Upcoming Events and Preparations")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/transication" ? "active" : ""
                  }`}
                >
                  <Link to="/transication">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={TransactionImg} />
                    </div>
                    {lang("Transaction History")}
                  </Link>
                </li>

                <li
                  className={`nav-items ${
                    location.pathname === "/aboutUs" ? "active" : ""
                  }`}
                >
                  <Link to="/aboutUs">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={AboutusImg} />
                    </div>
                    {lang("About Us")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/how-work" ? "active" : ""
                  }`}
                >
                  <Link to="/how-work">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={HowWorkImg} />
                    </div>
                    {lang("How It Works")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/privecy_policy" ? "active" : ""
                  }`}
                >
                  <Link to="/privecy_policy">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={PrivecyImg} />
                    </div>
                    {lang("Privacy Policy")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/change-password" ? "active" : ""
                  }`}
                >
                  <Link to="/change-password">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={ChangePassword} />
                    </div>
                    {lang("Change Password")}
                  </Link>
                </li>
                <li
                  className={`nav-items ${
                    location.pathname === "/settings" ? "active" : ""
                  }`}
                >
                  <Link to="/settings">
                    <div className="mainnew-logo-icon-navbar">
                      <img src={SettingImg} />
                    </div>
                    {lang("Settings")}
                  </Link>
                </li>
                <li
                  className={`nav-items${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link onClick={() => setDeleteAccount(true)}>
                    <div className="mainnew-logo-icon-navbar">
                      <img src={DeleteImg} />
                    </div>
                    {lang("Delete Account")}
                  </Link>
                </li>

                <li
                  className={`nav-items${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link onClick={() => setIsLogoutModalVisible(true)}>
                    <div className="mainnew-logo-icon-navbar">
                      <img src={LogoutImg} />
                    </div>
                    {lang("Logout")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>
      <Col span={24} lg={6} xxl={4} className="d-none">
        <div className="side-nav-main side-nav-main-for-web">
          <div className={show ? "sideshow" : "sidehide"}>
            <div className="side-nav">
              <ul>
                <li
                  onClick={() => navigate("/account")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/account" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UserlogoImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/account"> {lang("My profile")}</Link>
                      <p>{lang("Edit name and view address details")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/my-booking")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/my-booking" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UpcomingImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/my-booking"> {lang("My Booking")}</Link>
                      <p>{lang("my-booking to access")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/upcoming-booking")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/upcoming-booking" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UpcomingImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/Upcoming-booking">
                        {" "}
                        {lang("Upcoming Events and Preparations")}
                      </Link>
                      <p>{lang("Upcoming Events and Preparations to access")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/transication")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/transication" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={TransactionImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/transication">
                        {" "}
                        {lang("Transaction History")}
                      </Link>
                      <p>{lang("Transaction History View")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/aboutUs")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/aboutUs" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={AboutusImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/aboutUs"> {lang("About Us")}</Link>
                      <p>{lang("About Us Details")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/how-work")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/how-work" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={HowWorkImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/how-work"> {lang("How It Works")}</Link>
                      <p>{lang("View It Works ")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/privecy_policy")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/privecy_policy" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={PrivecyImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/privecy_policy">
                        {" "}
                        {lang("Privacy Policy")}
                      </Link>
                      <p>{lang("View your Privacy Policy")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/change-password")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/change-password" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={ChangePassword} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/change-password">
                        {lang("Change Password")}
                      </Link>
                      <p>{lang("Change Password")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/settings")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/settings" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={SettingImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/settings">{lang("Settings")}</Link>
                      <p>{lang("View and manage your Settings")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => setDeleteAccount(true)}
                  className={`nav-items mobile-side `}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={DeleteImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/"> {lang("Delete Account")}</Link>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li className={`nav-items mobile-side `} onClick={() => setIsLogoutModalVisible(true)}>
                  <Link >
                    <div className="accont-details">
                      <div className="account-icon-box">
                        <img src={LogoutImg} />
                      </div>
                      <div className="accounts-detail-list">
                        <Link  >{lang("Logout")}</Link>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>

      {isLogoutModalVisible && (
        <ShowConfirmBox
          reasons={[]}
          title={lang("Logout")}
          subtitle={lang(`Are you sure you want to Logout ?`)}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => showDeleteConfirm()}
        />
      )}
      {deleteAccount && (
        <ShowConfirmBox
          reasons={[]}
          title={lang("Delete Account")}
          subtitle={lang(`Are you sure you want to delete this Account?`)}
          show={deleteAccount}
          hide={() => {
            setDeleteAccount(false);
          }}
          onOk={() => {
            handleDeleteAccount();
          }}
        />
      )}
      {showOtpModal && (
        <DeleteAccountOtp
          show={showOtpModal}
          hide={() => {
            setShowOtpModal(false);
          }}
        />
      )}
    </>
  );
};

export default AccountSideNav;
