import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Input,
  Pagination,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  CalendarOutlined,
  SearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Loader from "../../components/Loader";
import { useMediaQuery } from "react-responsive";
import ImageImg from "../../assets/images/photoforlogin.jpeg";
import { useAppContext } from "../../context/AppContext";
import ContentApis from "../../services/ContentApis";
import moment from "moment";
import AuthApis from "../../services/AuthApis";
import apiPath from "../../constants/apiPath";
import useDebounce from "../../hooks/useDebounce";
import notfound from "../../assets/images/not_found.png";
import useRequest from "../../hooks/useRequest";
import { activityStatusFilter } from "../../constants/var";
import NodataImg from "../../assets/images/no-data.png";
import ExamImgNew from "../../assets/images/exam.png";
import prerarationImgNew from "../../assets/images/preparation.png";
import EventImgNew from "../../assets/images/event.png";
import TimingImg from "../../assets/images/timer.png";
import ClockImg from "../../assets/images/clock_loader_20.png";
import DateImg from "../../assets/images/date_range.png";
import SingleImageUpload from "../../components/SingleImageUpload";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const { TabPane } = Tabs;

const cmsTabs = {
  EXAM: "Exams",
  PREPARATION: "Preparations",
  EVENT: "Events",
};

function Booking() {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("exam");
  const [students, setStudents] = useState([]);
  const { request } = useRequest();
  const [allCenter, setAllCenters] = useState([]);

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  const getStudentData = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.getBookingStudent}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setStudents(data?.data ?? []);
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  const getCenter = () => {
    request({
      url: `${apiPath.centerList}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setAllCenters(data?.list ?? []);
      },
    });
  };

  useEffect(() => {
    getStudentData();
    getCenter();
  }, []);

  return (
    <>
      <section className="main container-fluid">
        <div className="account-outers">
          <Row>
            {isMobile ? <BackArrow /> : <AccountSideNav />}
            <Col span={24} lg={16} xl={15} className="mx-auto  amt-auto">
              <div className="tital-text-sign">My Booking</div>
              <div className="tabled quoteManagement">
                <Row gutter={[24, 0]}>
                  <Col xs={24} xl={24}>
                    <Card
                      bordered={false}
                      className="criclebox tablespace mb-24"
                    >
                      <Tabs
                        className="main_tabs main_tabs-new-1234"
                        onTabClick={handleTabChange}
                        activeKey={selectedTab}
                        tabBarStyle={{ color: "green" }}
                      >
                        <TabPane
                          tab={
                            <span>
                              <div className="mini-logo-exam-img-main-div  m-0">
                                {cmsTabs.EXAM}
                              </div>
                            </span>
                          }
                          key={"exam"}
                        >
                          <div className="cms-bodycontent">
                            <MyExam students={students} allCenter={allCenter} />
                          </div>
                        </TabPane>

                        <TabPane
                          tab={
                            <span>
                              <div className="mini-logo-exam-img-main-div  m-0">
                                {cmsTabs.PREPARATION}
                              </div>
                            </span>
                          }
                          key={"preparation"}
                        >
                          <MyPreparation
                            students={students}
                            allCenter={allCenter}
                          />
                        </TabPane>
                        <TabPane
                          tab={
                            <span>
                              <div className="mini-logo-exam-img-main-div  m-0">
                                {cmsTabs.EVENT}
                              </div>
                            </span>
                          }
                          key="event"
                        >
                          <MyEvent students={students} allCenter={allCenter} />
                        </TabPane>
                      </Tabs>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
const { Option } = Select;

const MyExam = ({ students, allCenter }) => {
  const { isMobile, language } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [ComLoading, setComLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [activeKey, setActiveKey] = useState(null);
  const [viewExam, setViewExam] = useState([]);
  const [component, setComponent] = useState([]);
  const { request } = useRequest();
  const [docsFile, setDocsFile] = useState();
  const [refresh, setRefresh] = useState(false);
  const FileType = ["application/pdf"];
  const [filter, setFilter] = useState({
    stauts: "all",
    student: undefined,
    center: undefined,
    start_date: undefined,
  });
  const { fetchBookingData } = AuthApis();

  const getData = () => {
    fetchBookingData({
      url: `${apiPath.myBooking}/Exam`,
      pagination,
      filter,
      onFatch: (value) => setData(value?.data),
      setLoading,
      debouncedSearchText,
    });
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [filter,refresh, debouncedSearchText]);

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };
  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const getViewExam = (id) => {
    setComLoading(true);
    request({
      url: `${apiPath.upcomingBookingView}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setComLoading(false);
        setViewExam(data?.data ?? []);
      },
      onError: (err) => {
        setComLoading(false);
        console.log(err, "err");
      },
    });
  };

  const getComponent = (id) => {
    request({
      url: `${apiPath.getComponent}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setComponent(data?.data?.[0] ?? []);
      },
      onError: (err) => {
        console.log(err, "err");
      },
    });
  };

  const handleDocFile = (value,id, key) => {
    if (key === "sendDocs") {
      console.log(value, "valuevalue");
      const payload = { document: value.length > 0 ? value[0].name : "" };
      request({
        url: `${apiPath.uploadDocs}/${id}`,
        data: payload,
        method: "POST",
        onSuccess: ({ data, status }) => {
        setRefresh(true)
        },
        onError: (err) => {
          console.log(err, "err");
        },
      });
    }
  };

  return (
    <>
      <div className="w-100 text-head_right_cont text-head_right_cont-new-main">
        <div
          className="pageHeadingSearch"
          style={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          {/* Event Type Filter */}
          {/* Search Bar */}
          <div className="role-wrap">
            <Input.Search
              placeholder="Search by Booking Number"
              style={{ width: 200 }}
              enterButton
              value={searchText}
              onChange={onSearch}
            />
          </div>
          {/* <div className="role-wrap">
           <Select
              last20Years
              width="110px"
              placeholder={"Select Type"}
              showSearch
              value={filter.stauts}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={activityStatusFilter?.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              onChange={(value) => onChange("stauts", value)}
            />
          </div> */}

          {/* Student Name Filter */}
          <div className="role-wrap">
            <Select
              last20Years
              width="110px"
              placeholder={"Select Student"}
              showSearch
              value={filter.student}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={students?.map((item) => ({
                value: item._id,
                label: item.name,
              }))}
              onChange={(value) => onChange("student", value)}
            />
          </div>
          <div className="role-wrap">
            <Select
              last20Years
              width="110px"
              placeholder={"Select Center"}
              showSearch
              value={filter.center}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={allCenter?.map((item) => ({
                value: item._id,
                label: item.name,
              }))}
              onChange={(value) => onChange("center", value)}
            />
          </div>

          {/* Date Filter */}
          <div className="role-wrap">
            <DatePicker
              style={{ width: 250 }}
              placeholder="Select Date"
              value={filter.start_date ? moment(filter.start_date) : undefined}
              onChange={(value) =>
                onChange(
                  "start_date",
                  value ? moment(value).format("YYYY-MM-DD") : undefined
                )
              }
            />
          </div>
        </div>
        <Button
          type="primary"
          onClick={() => {
            setFilter({
              status: "all",
              type: undefined,
              center: undefined,
              student: undefined,
              start_date: undefined,
            });
            setSearchText("");
          }}
          icon={<UndoOutlined />}
          className="custom-button-width-reset-buttonhnn"
        >
          Reset
        </Button>
      </div>
      <div className="main-new-remember-classes-main-scroll-class">
        {loading ? (
          <Loader />
        ) : (
          <Row gutter={[24, 24]} className="mt-4">
            {data?.length ? (
              data?.map((exam, index) => (
                <Col span={24} xl={12}>
                  <Collapse className="booking-pageiamge4er5mmainhbg-collpes8uhy">
                    <Collapse
                      activeKey={activeKey}
                      onChange={(key) => {
                        setActiveKey(key.length ? [key[key.length - 1]] : []);
                        getViewExam(exam?._id);
                        getComponent(exam?.syllabus_option_id);
                      }}
                    >
                      <Panel
                        key={index.toString()}
                        showArrow={false}
                        header={
                          <div className="booking-pageiamge4er5mmainhbg">
                            <div className="booking-pageiamge4er5">
                              <img
                                src={
                                  exam?.centerData?.image
                                    ? exam?.centerData?.image
                                    : notfound
                                }
                                alt="Event"
                              />
                            </div>
                            <div className="booking-pageiamge4er5-text">
                              <div className="mini-logo-exam-img-main-div">
                                <div className="mini-logo-exam-img">
                                  <img src={ExamImgNew} />
                                </div>
                                <h3 className="mini-logo-exam-img-teextss">
                                  {exam?.examData?.name
                                    ? exam?.examData?.name
                                    : "-"}
                                </h3>
                              </div>

                              {exam?.centerData?.name ? (
                                <h4> {exam?.centerData?.name} </h4>
                              ) : (
                                ""
                              )}

                              {exam?.examData?.exam_code ? (
                                <h4> {exam?.examData?.exam_code} </h4>
                              ) : (
                                ""
                              )}

                              <h5>
                                {exam?.examData?.start_date
                                  ? moment(exam?.examData?.start_date).format(
                                      "DD-MMM-YYYY hh:mm a"
                                    )
                                  : ""}
                              </h5>
                            </div>
                          </div>
                        }
                      >
                        {ComLoading ? (
                          <Loader />
                        ) : (
                          <div>
                            <div className="main-new-modal-ofbooking-view-pahe-ijuh890">
                              {(component?.title ||
                                component?.code ||
                                component?.duration ||
                                component?.weighting ||
                                component?.syllabus_date) && (
                                <div className="main-new-view-of-collapes-ikjhu-heading main-new-mybooking-collapers-text">
                                  <h3>Components</h3>
                                  <div className="main-new-view-of-collapes-ikjhu">
                                    <div className="main-new-view-of-collapes-ikjhu-inner-12">
                                      {component?.title && (
                                        <h5>{component?.title}</h5>
                                      )}
                                      {component?.code && (
                                        <p>{component?.code}</p>
                                      )}
                                    </div>
                                    <div className="main-new-view-of-collapes-ikjhu-inner-12">
                                      {component?.duration && (
                                        <h2>
                                          <img src={TimingImg} alt="Duration" />
                                          {component?.duration}
                                        </h2>
                                      )}
                                      {component?.weighting && (
                                        <h3>
                                          <img src={ClockImg} alt="Weighting" />
                                          {component?.weighting}
                                        </h3>
                                      )}
                                      {component?.syllabus_date && (
                                        <h4>
                                          <img
                                            src={DateImg}
                                            alt="Syllabus Date"
                                          />
                                          {component?.syllabus_date}
                                        </h4>
                                      )}
                                      {component?.tool_tip && (
                                        <Tooltip title={component?.tool_tip}>
                                          <h4 style={{ cursor: "pointer" }}>
                                            ?
                                          </h4>
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              {viewExam?.length ? (
                                viewExam?.map((item) => (
                                  <div className="main-new-mybooking-collapers-text">
                                    <h3>Attributes</h3>

                                    {item?.studentData?.length ? (
                                      <div className="main-new-mybooking-collapers-text-attributes-new">
                                        <h2>Student </h2>{" "}
                                        <h5>
                                          {item?.studentData
                                            ?.map(({ name }) => name)
                                            .join(", ") ?? "-"}
                                        </h5>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {item?.status ? (
                                      <div className="main-new-mybooking-collapers-text-attributes-new">
                                        <h2>Status </h2>{" "}
                                        <h5>{item?.status ?? "-"}</h5>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {/* <div className="main-new-mybooking-collapers-text-attributes-new">
                                      <h2>Attachment </h2>{" "}
                                      <h5>
                                        {" "}
                                        <SingleImageUpload
                                          value={docsFile}
                                          fileType={FileType}
                                          imageType={"file"}
                                          btnName={"PDF"}
                                          onChange={(data) =>
                                            handleDocFile(data,exam?._id, "sendDocs")
                                          }
                                          isDimension={false}
                                        />
                                      </h5>
                                      {item?.document ? (
                                        <div>
                                          <Button
                                            type="primary"
                                            block
                                            style={{
                                              marginTop: 20,
                                              width: "100%",
                                              maxWidth: "100%",
                                            }}
                                            onClick={() =>
                                              item?.document
                                                ? window.open(
                                                    apiPath.profileBaseUrl +
                                                      item?.document
                                                  )
                                                : ""
                                            }
                                          >
                                            VIEW
                                          </Button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div> */}

                                    {item?.bookingNo ? (
                                      <div className="main-new-mybooking-collapers-text-attributes-new">
                                        <h2>booking No. </h2>{" "}
                                        <h5>{item?.bookingNo ?? "-"}</h5>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {item?.bookingNo ? (
                                      <div className="main-new-mybooking-collapers-text-attributes-new">
                                        <h2>Exam Start Date </h2>{" "}
                                        <h5>
                                          {" "}
                                          {item?.examData?.start_date
                                            ? moment(
                                                item?.examData?.start_date
                                              ).utc().format("DD-MMM-YYYY hh:mm a")
                                            : ""}
                                          
                                        </h5>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {item?.centerData ? (
                                      <div className="main-new-mybooking-collapers-text-attributes-new">
                                        <h2>Center</h2>{" "}
                                        <div className="main-new-center-address">
                                          {item?.centerData?.profile_desc ? (
                                            <h2>
                                              {item?.centerData?.profile_desc ??
                                                "-"}
                                            </h2>
                                          ) : (
                                            ""
                                          )}
                                          {item?.centerData?.location ? (
                                            <h5>
                                              {item?.centerData?.location ??
                                                "-"}
                                            </h5>
                                          ) : (
                                            ""
                                          )}
                                          {item?.centerData?.center_code ? (
                                            <p>
                                              {item?.centerData?.center_code ??
                                                "-"}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                          {item?.centerData?.email ? (
                                            <p>
                                              {item?.centerData?.email ?? "-"}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ))
                              ) : (
                                <div>No Data Found</div>
                              )}
                            </div>
                          </div>
                        )}
                      </Panel>
                    </Collapse>
                  </Collapse>
                </Col>
              ))
            ) : (
              <Col span={24} className="no-data-col">
                <div className="no-data-image">
                  <img src={NodataImg} alt="No Data" />
                </div>
              </Col>
            )}
          </Row>
        )}
      </div>
    </>
  );
};

const MyPreparation = ({ students, allCenter }) => {
  const { isMobile, language } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [activeKey, setActiveKey] = useState(null);

  const [filter, setFilter] = useState({
    stauts: "all",
    student: undefined,
    center: undefined,
    start_date: undefined,
  });
  const { fetchBookingData } = AuthApis();

  const getData = () => {
    fetchBookingData({
      url: `${apiPath.myBooking}/Preparation`,
      pagination,
      filter,
      onFatch: (value) => setData(value?.data),
      setLoading,
      debouncedSearchText,
    });
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [filter, debouncedSearchText]);

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };
  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <div className="w-100 text-head_right_cont text-head_right_cont-new-main">
        <div
          className="pageHeadingSearch"
          style={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          {/* Event Type Filter */}
          {/* Search Bar */}
          <div className="role-wrap">
            <Input.Search
              placeholder="Search by Booking Number"
              style={{ width: 200 }}
              enterButton
              value={searchText}
              onChange={onSearch}
            />
          </div>
          {/* <div className="role-wrap">
           <Select
              last20Years
              width="110px"
              placeholder={"Select Type"}
              showSearch
              value={filter.stauts}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={activityStatusFilter?.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              onChange={(value) => onChange("stauts", value)}
            />
          </div> */}

          {/* Student Name Filter */}
          <div className="role-wrap">
            <Select
              last20Years
              width="110px"
              placeholder={"Select Student"}
              showSearch
              value={filter.student}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={students?.map((item) => ({
                value: item._id,
                label: item.name,
              }))}
              onChange={(value) => onChange("student", value)}
            />
          </div>
          <div className="role-wrap">
            <Select
              last20Years
              width="110px"
              placeholder={"Select Center"}
              showSearch
              value={filter.center}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={allCenter?.map((item) => ({
                value: item._id,
                label: item.name,
              }))}
              onChange={(value) => onChange("center", value)}
            />
          </div>

          {/* Date Filter */}
          <div className="role-wrap">
            <DatePicker
              style={{ width: 250 }}
              placeholder="Select Date"
              value={filter.start_date ? moment(filter.start_date) : undefined}
              onChange={(value) =>
                onChange(
                  "start_date",
                  value ? moment(value).format("YYYY-MM-DD") : undefined
                )
              }
            />
          </div>
        </div>

        <Button
          type="primary"
          onClick={() => {
            setFilter({
              stauts: "all",
              type: undefined,
              center: undefined,
              student: undefined,
              start_date: undefined,
            });
            setSearchText("");
          }}
          className="custom-button-width-reset-buttonhnn"
          icon={<UndoOutlined />}
        >
          {"Reset"}
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Row gutter={[24, 24]} className="mt-4">
          {data?.length ? (
            data?.map((item, index) => (
              <Col span={24} md={12}>
                <Collapse className="booking-pageiamge4er5mmainhbg-collpes8uhy">
                  <Collapse
                    activeKey={activeKey}
                    onChange={(key) =>
                      setActiveKey(key.length ? [key[key.length - 1]] : [])
                    }
                  >
                    <Panel
                      key={index.toString()}
                      showArrow={false}
                      header={
                        <div className="booking-pageiamge4er5mmainhbg">
                          <div className="booking-pageiamge4er5">
                            <img
                              src={
                                item?.eventData?.image
                                  ? item?.eventData?.image
                                  : notfound
                              }
                              alt="Event"
                            />
                          </div>
                          <div className="booking-pageiamge4er5-text">
                            <div className="mini-logo-exam-img-main-div">
                              <div className="mini-logo-exam-img mini-logo-exam-img-for-preparation">
                                <img src={prerarationImgNew} />
                              </div>
                              <h3 className="mini-logo-exam-img-teextss">
                                {item?.eventData?.name
                                  ? item?.eventData?.name
                                  : "-"}
                              </h3>
                            </div>

                            <h4>
                              {item?.centerData?.name
                                ? item?.centerData?.name
                                : "-"}
                            </h4>
                            <h5>
                              <CalendarOutlined />{" "}
                              {item?.eventData?.event_start_date
                                ? moment(
                                    item?.eventData?.event_start_date
                                  ).utc().format("DD-MMM-YYYY hh:mm a")
                                : ""}
                            </h5>
                          </div>
                        </div>
                      }
                    >
                      {item?.eventData?.description ? (
                        <div className="main-new-mybooking-collapers-text">
                          <h3>About</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.eventData?.description,
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="main-new-mybooking-collapers-text">
                        <h3>Attributes</h3>

                        {item?.bookingNo ? (
                          <div className="main-new-mybooking-collapers-text-attributes-new">
                            <h2>Booking Number </h2>{" "}
                            <h5>{item?.bookingNo ?? "-"}</h5>
                          </div>
                        ) : (
                          ""
                        )}

                        {item?.centerData ? (
                          <div className="main-new-mybooking-collapers-text-attributes-new">
                            <h2>Center</h2>{" "}
                            <div className="main-new-center-address">
                              {item?.centerData?.profile_desc ? (
                                <h5>{item?.centerData?.profile_desc ?? "-"}</h5>
                              ) : (
                                ""
                              )}
                              {item?.centerData?.location ? (
                                <h5>{item?.centerData?.location ?? "-"}</h5>
                              ) : (
                                ""
                              )}
                              {item?.centerData?.center_code ? (
                                <p>{item?.centerData?.center_code ?? "-"}</p>
                              ) : (
                                ""
                              )}
                              {item?.centerData?.email ? (
                                <p>{item?.centerData?.email ?? "-"}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                </Collapse>
              </Col>
            ))
          ) : (
            <Col span={24} className="no-data-col">
              <div className="no-data-image">
                <img src={NodataImg} alt="No Data" />
              </div>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

const MyEvent = ({ students, allCenter }) => {
  const { isMobile, language } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [activeKey, setActiveKey] = useState(null);
  const [filter, setFilter] = useState({
    stauts: "all",
    student: undefined,
    center: undefined,
    start_date: undefined,
  });
  const { fetchBookingData } = AuthApis();

  const getData = () => {
    fetchBookingData({
      url: `${apiPath.myBooking}/Event`,
      pagination,
      filter,
      onFatch: (value) => setData(value?.data),
      setLoading,
      debouncedSearchText,
    });
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [filter, debouncedSearchText]);

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };
  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };
  return (
    <>
      <div className="w-100 text-head_right_cont text-head_right_cont-new-main">
        <div
          className="pageHeadingSearch"
          style={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          {/* Event Type Filter */}
          {/* Search Bar */}
          <div className="role-wrap">
            <Input.Search
              placeholder="Search by Booking Number"
              style={{ width: 200 }}
              enterButton
              value={searchText}
              onChange={onSearch}
            />
          </div>
          {/* <div className="role-wrap">
           <Select
              last20Years
              width="110px"
              placeholder={"Select Type"}
              showSearch
              value={filter.stauts}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={activityStatusFilter?.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              onChange={(value) => onChange("stauts", value)}
            />
          </div> */}

          {/* Student Name Filter */}
          <div className="role-wrap">
            <Select
              last20Years
              width="110px"
              placeholder={"Select Student"}
              showSearch
              value={filter.student}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={students?.map((item) => ({
                value: item._id,
                label: item.name,
              }))}
              onChange={(value) => onChange("student", value)}
            />
          </div>
          <div className="role-wrap">
            <Select
              last20Years
              width="110px"
              placeholder={"Select Center"}
              showSearch
              value={filter.center}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={allCenter?.map((item) => ({
                value: item._id,
                label: item.name,
              }))}
              onChange={(value) => onChange("center", value)}
            />
          </div>

          {/* Date Filter */}
          <div className="role-wrap">
            <DatePicker
              style={{ width: 250 }}
              placeholder="Select Date"
              value={filter.start_date ? moment(filter.start_date) : undefined}
              onChange={(value) =>
                onChange(
                  "start_date",
                  value ? moment(value).format("YYYY-MM-DD") : undefined
                )
              }
            />
          </div>
        </div>
        <Button
          type="primary"
          onClick={() => {
            setFilter({
              stauts: "all",
              type: undefined,
              center: undefined,
              student: undefined,
              start_date: undefined,
            });
            setSearchText("");
          }}
          className="custom-button-width-reset-buttonhnn"
          icon={<UndoOutlined />}
        >
          {"Reset"}
        </Button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Row gutter={[24, 24]} className="mt-4">
          {data?.length ? (
            data?.map((item, index) => (
              <Col span={24} md={12}>
                <Collapse className="booking-pageiamge4er5mmainhbg-collpes8uhy">
                  <Collapse
                    activeKey={activeKey}
                    onChange={(key) =>
                      setActiveKey(key.length ? [key[key.length - 1]] : [])
                    }
                  >
                    <Panel
                      key={index.toString()}
                      showArrow={false}
                      header={
                        <div className="booking-pageiamge4er5mmainhbg">
                          <div className="booking-pageiamge4er5">
                            <img
                              src={
                                item?.eventData?.image
                                  ? item?.eventData?.image
                                  : notfound
                              }
                              alt="Event"
                            />
                          </div>
                          <div className="booking-pageiamge4er5-text">
                            <div className="mini-logo-exam-img-main-div">
                              <div className="mini-logo-exam-img mini-logo-exam-img-for-event">
                                <img src={EventImgNew} />
                              </div>
                              <h3 className="mini-logo-exam-img-teextss">
                                {item?.eventData?.name
                                  ? item?.eventData?.name
                                  : "-"}
                              </h3>
                            </div>
                            <h4>
                              {item?.centerData?.name
                                ? item?.centerData?.name
                                : "-"}
                            </h4>
                            <h5>
                              <CalendarOutlined />{" "}
                              {item?.eventData?.event_start_date
                                ? moment( item.eventData.event_start_date ).utc().format("DD-MMM-YYYY hh:mm a")
                                : ""}
                               
                            </h5>
                          </div>
                        </div>
                      }
                    >
                      {item?.eventData?.description ? (
                        <div className="main-new-mybooking-collapers-text">
                          <h3>About</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.eventData?.description,
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="main-new-mybooking-collapers-text">
                        <h3>Attributes</h3>

                        {item?.bookingNo ? (
                          <div className="main-new-mybooking-collapers-text-attributes-new">
                            <h2>Booking Number </h2>{" "}
                            <h5>{item?.bookingNo ?? "-"}</h5>
                          </div>
                        ) : (
                          ""
                        )}

                        {item?.centerData ? (
                          <div className="main-new-mybooking-collapers-text-attributes-new">
                            <h2>Center</h2>{" "}
                            <div className="main-new-center-address">
                              {item?.centerData?.profile_desc ? (
                                <h5>{item?.centerData?.profile_desc ?? "-"}</h5>
                              ) : (
                                ""
                              )}
                              {item?.centerData?.location ? (
                                <h5>{item?.centerData?.location ?? "-"}</h5>
                              ) : (
                                ""
                              )}
                              {item?.centerData?.center_code ? (
                                <p>{item?.centerData?.center_code ?? "-"}</p>
                              ) : (
                                ""
                              )}
                              {item?.centerData?.email ? (
                                <p>{item?.centerData?.email ?? "-"}</p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                </Collapse>
              </Col>
            ))
          ) : (
            <Col span={24} className="no-data-col">
              <div className="no-data-image">
                <img src={NodataImg} alt="No Data" />
              </div>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export const BackArrow = () => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Booking;
