import { useNavigate } from "react-router";
import apiPath from "../constants/apiPath";
import { useAppContext } from "../context/AppContext";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { useAuthContext } from "../context/AuthContext";

const DeleteApis = () => {
  const { request } = useRequest();

  const deleteData = ({ url, id, setRefresh, setLoading, setSelected,method }) => {
    setLoading(true);
    request({
      url: `${url}/${id}`,
      method: method ? method : "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast("Deleted successfully", Severty.SUCCESS);
          setRefresh(() => (prev) => !prev);
          setSelected();
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return { deleteData };
};

export default DeleteApis;
