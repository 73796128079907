import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
import { ShowToast, Severty } from "../../helper/toast";
import GoogleImg from "../../assets/images/google-plus.png";
import lang from "../../helper/langHelper";
import useFirebase from "../../hooks/useFirebase";
import apiPath from "../../constants/apiPath";
import FacebookImg from "../../assets/images/facebook.svg";




const FacebookLogin = () => {
  const provider = new FacebookAuthProvider();
  const auth = getAuth();
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { fcmToken } = useFirebase();
  const { request } = useRequest();

  provider.setCustomParameters({
    'display': 'popup'
  });


  
  const signInWithFacebook = () => {
    signInWithPopup(auth, provider)
      .then((res) => {
        const { user } = res;

        const payload = {
                    name: user.providerData[0].displayName,
                    email: user.providerData[0].email,
                    social_id: user.providerData[0].uid,
                    image: user.providerData[0].photoURL,
                    login_type: 'Facebook',
                    device_type: 'Web',
                    device_token: fcmToken,
                  };

    

        console.log(payload, "\nPayload------------------->");

        request({
          url: apiPath.socialLogin,
          method: "POST",
          data: payload,
          onSuccess: (data) => {
            if (data.status) {
              setIsLoggedIn(true);
              setUserProfile(data.data.user);
              setTimeout(() => navigate("/account"), 200);
              ShowToast(data.message, Severty.SUCCESS);
              localStorage.setItem("token", data.data.token);
              localStorage.setItem(
                "userProfile",
                JSON.stringify(data.data.user)
              );
            } else {
              ShowToast(data.message, Severty.ERROR);
            }
          },
          onError: (error) => {
            ShowToast(error?.response?.data?.message, Severty.ERROR);
          },
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(
          `Error signing in with FacebookImg: ${errorCode} - ${errorMessage}`
        );
      });
  };

  return (
    <button
      onClick={signInWithFacebook}
      disabled={loading}
      className="google-button-img"
    
    >
      {loading ? (
        <>
          <img  src={FacebookImg} alt="FacebookImg" />
          {" Logging in..."}
        </>
      ) : (
        <>
          <img  src={FacebookImg} alt="FacebookImg" />
        </>
      )}
    </button>
  );
};

export default FacebookLogin;
