import { useNavigate } from "react-router";
import apiPath from "../constants/apiPath";
import { useAppContext } from "../context/AppContext";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { useAuthContext } from "../context/AuthContext";




const ContentApis = ()=>{
    const {request } = useRequest() 

    const fetchContentData = ({slug,setData,setLoading}) => {  
        setLoading(true)
        request({
          url: `${apiPath.fetchContent}/${slug}`,
          method: "GET",     
          onSuccess: (data) => {
           setLoading(false)
            if (data.status) {
              setData(data?.data );
            }
          },
          onError: (error) => {
            setLoading(false)
            ShowToast(error?.response?.data?.message, Severty.ERROR);

          },
        });
      };

      return {fetchContentData}
}




export default ContentApis