import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 35,
            color: `#000000`,
            
        }}
        spin
    />
);

const Loader = () => {
    return (
        <div className="spin_loader">
            <Spin  >{content}</Spin>
        </div>
    )
}

export default Loader