import { ArrowLeftOutlined, BellOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { Accordion } from "react-bootstrap";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";
import SettingApis from "../../services/SettingApis";
import { useAuthContext } from "../../context/AuthContext";
import { useMediaQuery } from "react-responsive";
const { Title, Text, Link } = Typography;

window.Buffer = window.Buffer || require("buffer").Buffer;

function Settings() {
  const { request } = useRequest();
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { setRefreshUser, userProfile, isDarkTheme, setIsDarkTheme } =
    useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function to show the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle closing of modal with OK button
  const handleOk = () => {
    setIsModalVisible(false);
  };

  // Function to handle closing of modal with Cancel button
  const handleCancel = () => {
    setIsModalVisible(false);
  };

    const { changeStatus } = SettingApis();

    const onChange = (value) => {
      changeStatus({
        url: apiPath.updateSetting,
        setLoading: setLoading,
        payload: value,
      });
    };

  return (
    <>
      <section className="main container-fluid">
        <div className="account-outers">
          <Row>
            {isMobile ? <BackArrow /> : <AccountSideNav />}
            <Col span={24} lg={16} xl={15} className="mx-auto  amt-auto">
              <div className="privecy-main-discription-1 main-new-setting-iconhygt123">
                <h3>{lang("Settings")}</h3>
                <Card>
                  <div style={{ marginBottom: 20 }}>
                    <div className="push-notifiy-switch">
                    <Title level={5}>Push Notifications</Title>
                    <Switch
                        checked={userProfile?.pause_notification}
                        onChange={(value) => {
                          onChange({ pause_notification: value });
                        }}
                      />
                      </div>
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Pause All</Text>
                      <Switch
                        checked={userProfile?.pause_notification}
                        onChange={(value) => {
                          onChange({ pause_notification: value });
                        }}
                      />
                    </div> */}
                    <Text  onClick={showModal} style={{ cursor: "pointer" }}>
                      Email Notifications
                    </Text>
                  </div>

                  <div style={{ marginBottom: 20 }}>
                    <Title level={5}>Location settings</Title>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Recommended Location</Text>
                      <Switch defaultChecked />
                    </div>
                  </div>

                  <div>
                    <Title level={5}>Dark/Light</Title>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>Dark/Light</Text>
                      <Switch
                        checked={isDarkTheme}
                        onChange={(value) => {
                          localStorage.setItem("darkTheme", value);
                          window.location.reload();
                          setIsDarkTheme(value);
                        }}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        footer={false}
        cancelText="Cancel"
        className="main-email-notifiaction-setting"
      >
        <Card className="email-notifications-card">
          <Title level={5} className="email-notifications-title">
            Email Notifications
          </Title>

          <div className="email-notification-item">
            <Text>Feedback Emails</Text>
            <Switch
              checked={userProfile?.feedback_email}
              onChange={(value) => {
                onChange({ feedback_email: value });
              }}
            />
          </div>

          <div className="email-notification-item">
            <Text>Reminder Emails</Text>
            <Switch
              checked={userProfile?.reminder_email}
              onChange={(value) => {
                onChange({ reminder_email: value });
              }}
            />
          </div>

          <div className="email-notification-item">
            <Text>News Emails</Text>
            <Switch
              checked={userProfile?.news_email}
              onChange={(value) => {
                onChange({ news_email: value });
              }}
            />
          </div>

          <div className="email-notification-item">
            <Text>Promotional Emails</Text>
            <Switch
              checked={userProfile?.promotional_email}
              onChange={(value) => {
                onChange({ promotional_email: value });
              }}
            />
          </div>
        </Card>
      </Modal>
    </>
  );
}

export const BackArrow = () => {
  const navigate = useNavigate();

  return (
    <div className="fillter-sidebar-back-arrow">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Settings;
