import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Select,
  Form,
  Collapse,
  Modal,
  Tag,
  Input,
  Tooltip,
} from "antd";
import BANNERiMG from "../../assets/images/image 9.png";
import apiPath from "../../constants/apiPath";
import ExamImgNew from "../../assets/images/exam.png";
import TimingImg from "../../assets/images/timer.png";
import ClockImg from "../../assets/images/clock_loader_20.png";
import DateImg from "../../assets/images/date_range.png";
import NodataImg from "../../assets/images/no-data.png";
import Adduser from "../../assets/images/add-user-img.png";
import Deluser from "../../assets/images/dlt-user-img.png";
import NewseatIcon from "../../assets/images/airline_seat_recline_normal.png";

import {
  HeartOutlined,
  HeartFilled,
  UserAddOutlined,
  UserDeleteOutlined,
  EyeFilled,
} from "@ant-design/icons";
import useRequest from "../../hooks/useRequest";
import { eventType } from "../../constants/var";
import { useNavigate, useParams } from "react-router";
import { useAuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import { Severty, ShowToast } from "../../helper/toast";
import { Title } from "chart.js";
import AuthApis from "../../services/AuthApis";
import useDebounce from "../../hooks/useDebounce";
const { Panel } = Collapse;
const ViewExam = ({ hide, show, data }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const pageType = urlParams.get("type");
  const editCart = urlParams.get("student");
  const cartId = urlParams.get("cartId");
  const [courseList, setCourseList] = useState([]);
  const [centerData, setcenterData] = useState({});
  const [courseSyllabusList, setCourseSyllabusList] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [crsloading, setCrsLoading] = useState(false);
  const [crsOploading, setCrsOpLoading] = useState(false);
  const { request } = useRequest();
  const params = useParams();
  const { isLoggedIn } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [crsActiveKey, setCrsActiveKey] = useState(null);
  const [crsOpActiveKey, setCrsOpActiveKey] = useState(null);
  const [crsOptions, setcrsOptions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCodeText, setSearchCodeText] = useState("");
  const debouncedSearchCodeText = useDebounce(searchCodeText, 300);

  const { favCenter } = AuthApis();
  const api = {
    viewDetails: apiPath.viewExams,
    courseSyllbus: apiPath.courseSyllbus,
    studentList: apiPath.getBookingStudent,
    createBooking: apiPath.createBooking,
    crsOption: apiPath.crsOption,
    getComponent: apiPath.getComponent,
    editCreateBooking: apiPath.editCreateBooking,
  };

  const getData = (centrId, examId) => {
    setLoading(true);
    request({
      url: `${api.viewDetails}/${centrId}/${examId}?levelSearch=${debouncedSearchText}&codeSearch=${debouncedSearchCodeText}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setList(data?.getExamData?.[0] ?? []);
          setCourseList(data?.course ?? []);
          setcenterData(data?.centre)
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  const getCourseSyllabus = (centrId, examId, courseId) => {
    setCrsLoading(true);
    request({
      url: `${api.courseSyllbus}/${centrId}/${examId}/${courseId}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setCrsLoading(false);
        if (status) {
          setCourseSyllabusList(data?.data ?? []);
        }
      },
      onError: (err) => {
        setCrsLoading(false);
        console.log(err, "err");
      },
    });
  };

  const getCrsOption = (courseId, optionId) => {
    setCrsOpLoading(true);
    request({
      url: `${api.crsOption}/${params?.centerId}/${params?.examId}/${courseId}/${optionId}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setCrsOpLoading(false);
        if (status) {
          setcrsOptions(data?.data ?? []);
        }
      },
      onError: (err) => {
        setCrsOpLoading(false);
        console.log(err, "err");
      },
    });
  };

  useEffect(() => {
    if (!params.examId && !params.centerId) return;
    setLoading(true);
    getData(params.centerId, params.examId);
  }, [params.examId, refresh, debouncedSearchCodeText, debouncedSearchText]);

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const onCodeSearch = (e) => {
    setSearchCodeText(e.target.value);
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Card>
          <Row gutter={[16, 16]}>
            <Col span={24} lg={12}>
              {loading ? (
                <Loader />
              ) : (
                <div className="main-new-baner-ijuhy8">
                  <img
                    src={
                      centerData?.image
                        ? centerData?.image
                        : BANNERiMG
                    }
                    alt="Event"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              )}
            </Col>

            <Col span={24} lg={12}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <div className="main-new-baner-ijuhy81212">
                    <div className="main-new-baner-ijuhy8-buttonh">
                      <div className="main-new-view-amd-visit-website">
                        <Button
                          type="default"
                          icon={
                            list?.is_fav ? (
                              <HeartFilled style={{ color: "red" }} />
                            ) : (
                              <HeartOutlined />
                            )
                          }
                          style={{ marginLeft: "10px" }}
                          className="main-new-favrated-buton-new6tgfr"
                          onClick={() =>
                            favCenter({
                              payload: {
                                type: pageType,
                                exam_id: list?._id,
                              },
                              setRefresh,
                            })
                          }
                        ></Button>

                        <div className="main-new-view-website-icon" >
                          <a
                            target="_blank"
                            class="visit-button main-new-favrated-buton-new6tgfr"
                            style={{cursor: "no-drop"}}
                          >
                            {centerData?.concession ? (
                              <img src={Adduser} />
                            ) : (
                              <img src={Deluser} />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="mini-logo-exam-img-main-div">
                      <div className="mini-logo-exam-img">
                        <img src={ExamImgNew} />
                      </div>
                      <h2>{list?.name ? list?.name : ""}</h2>
                    </div>
                    <h4 className="text-clg-name">
                          {centerData?.name ? centerData?.name : ""}
                        </h4>
                    {list?.description ? (
                      <div>
                        <h2>About</h2>{" "}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: list?.description,
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                {!isLoggedIn ? (
                  <Col span={24}>
                    <div className="main-new-baner-ijuhy8">
                      <h3>Event Booking Form</h3>
                      <p>
                        Please <Link to="/login">Sign In</Link> /{" "}
                        <Link to="/register">Register</Link> to continue with
                        purchase.
                      </p>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <div>
                <div className="pageHeadingSearch pageHeadingSearch-exam-view-page-452 mt-4">
                  <Input.Search
                    value={searchText}
                    className="searchInput"
                    placeholder={"Search by level"}
                    onChange={onSearch}
                    allowClear
                  />

                  <Input.Search
                    value={searchCodeText}
                    className="searchInput"
                    placeholder={"Search by code"}
                    onChange={onCodeSearch}
                    allowClear
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <Row gutter={[16, 16]} className="mt-4">
                  {courseList?.length ? (
                    courseList?.map((item, index) => (
                      <Col span={24} md={12}>
                        <div className="main-new-collapes-class-all-div-juh67">
                          <Collapse
                            activeKey={activeKey}
                            onChange={(key) => {
                              setActiveKey(
                                key.length ? [key[key.length - 1]] : []
                              );
                              getCourseSyllabus(
                                params.centerId,
                                params.examId,
                                item?._id
                              );
                              setcrsOptions([]);
                            }}
                          >
                            <Panel
                              key={index.toString()}
                              showArrow={false}
                              header={
                                <div className="booking-pageiamge4er5mmainhbggfdgfdg">
                                  <div className="booking-pageiamge4er5-textgdfgfd">
                                    {" "}
                                    {item?.name ?? "-"} | {item?.course_code}
                                  </div>
                                </div>
                              }
                            >
                              <Collapse
                                activeKey={crsActiveKey}
                                onChange={(key) => {
                                  setCrsActiveKey(
                                    key.length ? [key[key.length - 1]] : []
                                  );
                                }}
                              >
                                {crsloading ? (
                                  <Loader />
                                ) : courseSyllabusList?.length ? (
                                  courseSyllabusList.map((crs, index) => (
                                    <Panel
                                      key={crs?._id || index.toString()} // Use crs._id if available, else fallback to index
                                      showArrow={false}
                                      header={
                                        <div className="booking-pageiamge4er5mmainhbggfdgfdg">
                                          <div className="booking-pageiamge4er5-textgdfgfd">
                                            <div className="main-new-mybooking-collapers-textfgfdgfd">
                                              <p
                                                onClick={() =>
                                                  getCrsOption(
                                                    crs?.course,
                                                    crs?.syallbus?._id
                                                  )
                                                }
                                              >
                                                {crs?.syallbus?.name} |{" "}
                                                {crs?.syallbus?.syllabus_code}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      {crsOploading ? (
                                        <Loader />
                                      ) : (
                                        <div>
                                          {crsOptions?.length ? (
                                            crsOptions?.map((opn) => (
                                              <Collapse
                                                accordion
                                                key={opn?._id}
                                                activeKey={crsOpActiveKey}
                                                onChange={(key) => {
                                                  setCrsOpActiveKey(
                                                    key.length
                                                      ? [key[key.length - 1]]
                                                      : []
                                                  );
                                                }}
                                              >
                                                <Panel
                                                  header={
                                                    <div className="inner-collapes-div-class-hn-view-button">
                                                      <p>
                                                        {
                                                          opn?.syllabusoptions
                                                            ?.option_code
                                                        }
                                                      </p>
                                                      <div className="total-seat-icomand-div">
                                                        <img
                                                          src={NewseatIcon}
                                                        />
                                                       <Tooltip title="Available seat">
                                                       <p>
                                                          {" "}
                                                          {
                                                            opn?.availableSeat
                                                          }{" "}
                                                        </p>
                                                        </Tooltip>
                                                      </div>

                                                      <p className="total-seat-icomand-div-price-tagnew097">
                                                        {" "}
                                                        R{" "}
                                                        {
                                                          opn?.normal
                                                            ?.bookingFee
                                                        }
                                                      </p>
                                                      <Tag
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          setSelected(opn);
                                                          setVisible(true);
                                                        }}
                                                      >
                                                        <EyeFilled />
                                                      </Tag>
                                                    </div>
                                                  }
                                                  key={opn?._id}
                                                  showArrow={false}
                                                ></Panel>
                                              </Collapse>
                                            ))
                                          ) : (
                                            <p>No syllabus options available</p>
                                          )}
                                        </div>
                                      )}
                                    </Panel>
                                  ))
                                ) : (
                                  <p>No course syllabus available</p>
                                )}
                              </Collapse>
                            </Panel>
                          </Collapse>
                        </div>
                      </Col>
                    ))
                  ) : (
                  <Col span={24}>
                    <div className="no-data-image">
                      <img src={NodataImg} alt="No Data" />
                    </div>
                  </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      </div>

      {visible && (
        <SelectStudent
          section={"Student"}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          centerId={params.centerId}
          examId={params.examId}
          selected={selected}
          editCart={editCart}
          cartId={cartId}
          // refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
};

const SelectStudent = ({
  show,
  hide,
  api,
  data,
  refresh,
  selectedStudent,
  selected,
  editCart,
  cartId,
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const [form] = Form.useForm();
  const [compData, setCompData] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const getComponent = (id) => {
    request({
      url: `${api.getComponent}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCompData(data?.data?.[0] ?? []);
        }
      },
      onError: (err) => {
        console.log(err, "err");
      },
    });
  };

  const getStudentData = (id) => {
    setLoading(true);
    request({
      url: `${api.studentList}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setList(data?.data ?? []);
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  const onBooking = (values) => {
    setLoading(true);
    const payload = {
      course_id: selected?.course ? selected?.course : null,
      syllabus_option_id: selected?._id ? selected?._id : null,
      syllabus_id: selected?.syallbus ? selected?.syallbus : null,
      student: values?.student_id ? values?.student_id : null,
      exam_id: params.examId ? params.examId : null,
      center_id: selected.centerId,
      totalAmount: selected?.normal?.bookingFee
        ? selected?.normal?.bookingFee
        : 0,
      amount: selected?.normal?.bookingFee ? selected?.normal?.bookingFee : 0,
      quantity: 1,
      type: "Exam",
    };
    // return console.log(payload, "payload");
    request({
      url: editCart
        ? `${api.editCreateBooking}/${cartId}`
        : `${api.createBooking}`,
      method: "POST",
      data: payload,
      onSuccess: ({ data, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          navigate("/cart");
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  useEffect(() => {
    console.log(data);
    setLoading(true);
    getStudentData();
  }, []);

  useEffect(() => {
    if (!editCart) return;
    form.setFieldsValue({ ...editCart, student_id: editCart });
  }, [editCart]);

  useEffect(() => {
    if (!selected) return;
    console.log(selected, "selected");
    getComponent(selected?._id);
  }, [selected]);

  return (
    <>
      <Modal
        // title="Select Student"
        open={show}
        onCancel={hide}
        okText="ADD STUDENTS TO BOOKING"
        footer={[
          <Col xl={24} md={24} span={24}>
            <div className="main-new-footer-button-dased">
              <Form.Item>
                <Button
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  Book Now
                </Button>
              </Form.Item>
            </div>
          </Col>,
        ]}
      >
        <Form
          id="create"
          form={form}
          onFinish={onBooking}
          layout="vertical"
          className="row-col"
        >
          <div className="add_user_title">
            <h4 className="modal_title_cls">{"Select Student"}</h4>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="main-new-modal-ofbooking-view-pahe-ijuh890">
                {(compData?.title ||
                  compData?.code ||
                  compData?.duration ||
                  compData?.weighting ||
                  compData?.syllabus_date) && (
                  <div className="main-new-view-of-collapes-ikjhu-heading">
                    <h1>Components</h1>
                    <div className="main-new-view-of-collapes-ikjhu">
                      <div className="main-new-view-of-collapes-ikjhu-inner-12">
                        {compData?.title && <h5>{compData?.title}</h5>}
                        {compData?.code && <p>{compData?.code}</p>}
                      </div>
                      <div className="main-new-view-of-collapes-ikjhu-inner-12">
                        {compData?.duration && (
                          <h2>
                            <img src={TimingImg} alt="Duration" />
                            {compData?.duration}
                          </h2>
                        )}
                        {compData?.weighting && (
                          <h3>
                            <img src={ClockImg} alt="Weighting" />
                            {compData?.weighting}
                          </h3>
                        )}
                        {compData?.syllabus_date && (
                          <h4>
                            <img src={DateImg} alt="Syllabus Date" />
                            {compData?.syllabus_date}
                          </h4>
                        )}
                        {compData?.tool_tip && (
                          <Tooltip title={compData?.tool_tip}>
                            <h4 style={{cursor : "pointer"}}>?</h4>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Row gutter={[16, 0]}>
                <Col xl={24} md={24} span={24}>
                  <Form.Item
                    className="username"
                    name="student_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select student!",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select a student"
                      mode="single"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {list?.map((item, index) => (
                        <Select.Option key={index} value={item?._id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ViewExam;
