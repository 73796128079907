import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Select, Form, Tag, Modal, Layout } from "antd";
import BANNERiMG from "../assets/images/image 9.png";
import Footer from "../components/layout/Footer";
import apiPath from "../constants/apiPath";
import { useNavigate } from "react-router-dom";
import prepImgNew from "../../src/assets/images/preparation.png";
import moment from "moment";
import Adduser from "../assets/images/add-user-img.png";
import Deluser from "../assets/images/dlt-user-img.png";


import {
  CheckCircleOutlined,
  DollarOutlined,
  HeartOutlined,
  HeartFilled,
  UserOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import useRequest from "../hooks/useRequest";
import { eventType } from "../constants/var";
import { useParams } from "react-router";
import { useAuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { Severty, ShowToast } from "../helper/toast";
import AuthApis from "../services/AuthApis";

const ViewEventPreparation = ({ hide, show, data }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const pageType = urlParams.get("type");
  const editCart = urlParams.get("student");
  const cartId = urlParams.get("cartId");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const params = useParams();
  const { isLoggedIn } = useAuthContext();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(
    editCart ? { _id: editCart } : null
  );
  const navigate = useNavigate();

  const { favCenter } = AuthApis();

  const api = {
    viewDetails:
      pageType === "Event" ? apiPath.viewEvents : apiPath.viewBookPreparation,
    studentList: apiPath.getBookingStudent,
    createBooking: apiPath.createBooking,
    editCreateBooking: apiPath.editCreateBooking,
  };

  const getData = (id) => {
    setLoading(true);
    request({
      url: `${api.viewDetails}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setList(data ?? []);
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  const onBooking = (values) => {
    setLoading(true);
    const { booking_fees, center_id, _id } = values;
    const payload = {
      student: selectedStudent?._id ? selectedStudent?._id : null,
      event_id: _id ? _id : null,
      center_id: center_id?._id ? center_id?._id : null,
      totalAmount: booking_fees ? booking_fees : 0,
      amount: booking_fees ? booking_fees : 0,
      quantity: 1,
      type: pageType ? pageType : null,
    };
    // return console.log(payload,"payload");
    request({
      url: editCart
        ? `${api.editCreateBooking}/${cartId}`
        : `${api.createBooking}`,
      method: "POST",
      data: payload,
      onSuccess: ({ data, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          navigate("/cart");
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getData(params.id);
  }, [params.id, refresh]);

  const eventType = (type) => {
    let event_type;
    switch (type) {
      case "work_shop":
        event_type = "Work Shop";
        break;
      case "general_event":
        event_type = "General Event";
        break;
      default:
        event_type = type;
    }
    return event_type;
  };

  return (
    <>
      <Layout>
        <div style={{ padding: "20px" }}>
          <Card>
            {loading ? (
              <Loader />
            ) : (
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <div className="main-new-baner-ijuhy8">
                    <img
                      src={list?.image ? list?.image : BANNERiMG}
                      alt="Event"
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                  </div> 
                </Col>

                <Col span={24} lg={12}>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <div className="main-new-baner-ijuhy81212">                   
                        <div className="main-new-baner-ijuhy8-buttonh">
                          <div className="mini-logo-exam-img-main-div">
                            <div className="mini-logo-exam-img mini-logo-exam-img-for-preparation">
                              <img src={prepImgNew} />
                            </div>
                            <h2>{list?.name ? list?.name : ""}</h2>
                          </div>

                          <div className="main-new-view-amd-visit-website">
                            <Button
                              type="default"
                              icon={
                                list?.is_fav ? (
                                  <HeartFilled style={{ color: "red" }} />
                                ) : (
                                  <HeartOutlined />
                                )
                              }
                              style={{ marginLeft: "10px" }}
                              className="main-new-favrated-buton-new6tgfr"
                              onClick={() =>
                                favCenter({
                                  payload: {
                                    type: pageType,
                                    event_id: list?._id,
                                  },
                                  setRefresh,
                                })
                              }
                            ></Button>
                            {list?.center_id?.web_url ? (
                              <div className="main-new-view-website-icon">
                                <a
                                  onClick={() =>
                                    window.open(
                                      list?.center_id?.web_url,
                                      "_blank"
                                    )
                                  }
                                  target="_blank"
                                  class="visit-button main-new-favrated-buton-new6tgfr"
                                >
                                  <i class="fas fa-external-link-alt"></i>
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                            {pageType === "Event" ? (
                              <div className="main-new-view-website-icon" >
                                <a
                                style={{cursor: "no-drop"}}
                                  target="_blank"
                                  class="visit-button main-new-favrated-buton-new6tgfr"
                                >
                                  {list?.center_id?.concession ? (
                                 <img src={Adduser} />
                              ) : (
                                <img src={Deluser} />
                              )}
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <h4 className="text-clg-name">
                          {list?.center_id?.name ? list?.center_id?.name : ""}
                        </h4>
                        <h3>
                          {list?.event_start_date
                              ? moment(list?.event_start_date).format(
                                  "DD-MMM-YYYY hh:mm a"
                                )
                              : ""
                           }
                        </h3>
                        {/* Price and availability */}
                        <div className="main-new-tags-hhnh">
                          {list?.booking_fees ? (
                            <Tag color="yellow">
                              <DollarOutlined /> R{list?.booking_fees} Per
                              Ticket
                            </Tag>
                          ) : (
                            ""
                          )}
                          <Tag color="green">
                            <CheckCircleOutlined />{" "}
                            {list?.total_seat ? list?.total_seat : "0"} Seats
                            Available
                          </Tag>

                          {list?.mode ? (
                            <Tag color="blue">
                              Mode: {list?.mode}
                            </Tag>
                          ) : (
                            ""
                          )}
                          {list?.event_type ? (
                            <Tag color="cyan">
                              Type:{" "}
                              {list?.event_type
                                ? eventType(list?.event_type)
                                : eventType(list?.event_type)}
                            </Tag>
                          ) : (
                            ""
                          )}
                        </div>

                        {list?.description !== null || list?.description !== ""  ? (
                          <div className="main-new-about-description-view-page">
                            <h2>About</h2>{" "}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: list?.description,
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {isLoggedIn ? (
                          moment(
                            pageType === "Event"
                              ? list?.event_end_date
                              : list?.booking_end_date
                          ).isAfter(moment()) ? (
                            <div className="select-student-and-booknow">
                              <Button
                                type="primary"
                                onClick={() => {
                                  setSelected(list);
                                  setVisible(true);
                                }}
                              >
                                {selectedStudent
                                  ? "Change Student"
                                  : "Select Student"}
                              </Button>
                              <Button
                                type="primary"
                                disabled={!selectedStudent}
                                style={{ marginLeft: "10px" }}
                                onClick={() => onBooking(list)}
                              >
                                Book Now
                              </Button>
                            </div>
                          ) : pageType === "Event" ? (
                            <div>This Event is Expired</div>
                          ) : (
                            <div>This Exams Preparations is Expired</div>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    {!isLoggedIn ? (
                      <Col span={24}>
                        <div className="main-new-baner-ijuhy8">
                          <h3>Event Booking Form</h3>
                          <p>
                            Please <Link to="/login">Sign In</Link> /{" "}
                            <Link to="/register">Register</Link> to continue
                            with purchase.
                          </p>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              </Row>
            )}
          </Card>
        </div>
      </Layout>

      {visible && (
        <SelectStudent
          section={"Student"}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selectedStudent}
          selectedStudent={(value) => setSelectedStudent(value)}
          // refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
};

const SelectStudent = ({ show, hide, api, data, refresh, selectedStudent }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const [form] = Form.useForm();

  const getStudentData = (id) => {
    setLoading(true);
    request({
      url: `${api.studentList}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setList(data?.data ?? []);
        }
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, "err");
      },
    });
  };

  const onFinish = (value) => {
    selectedStudent(value);
    hide();
  };

  useEffect(() => {
    setLoading(true);
    getStudentData();
  }, []);

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data });
  }, [data]);

  return (
    <>
      <Modal
        // title="Select Student"
        open={show}
        onCancel={hide}
        okText="ADD STUDENTS TO BOOKING"
        footer={[
          <Col xl={24} md={24} span={24}>
            <div className="main-new-footer-button-dased">
              <Form.Item>
                <Button
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  ADD STUDENTS TO BOOKING
                </Button>
              </Form.Item>
            </div>
          </Col>,
        ]}
      >
        <Form
          id="create"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="row-col"
        >
          <div className="add_user_title">
            <h4 className="modal_title_cls">{"Select Student"}</h4>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <Row gutter={[16, 0]}>
              <Col xl={24} md={24} span={24}>
                <Form.Item
                  className="username"
                  name="_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select student!",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select a student"
                    mode="single"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {list?.map((item, index) => (
                      <Select.Option key={index} value={item?._id}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ViewEventPreparation;
