import React, { createContext, useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { ShowToast, Severty } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [session, setSession] = useState({ token: null });
  const [isAdmin, setIsAdmin] = useState(true);
  const [userProfile, setUserProfile] = useState();
  const [refreshUser, setRefreshUser] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 991 });

  const fetchUser = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(apiPath.baseURL + `${apiPath.profile}`, {
        headers,
      });
      setUserProfile(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) return;

    let user = localStorage.getItem("userProfile")
      ? JSON.parse(localStorage.getItem("userProfile"))
      : null;
    if (user) {
      fetchUser();
      //setUserProfile(user)
    }
    setIsLoggedIn(true);
    setSession({ token: token });

    let darkTheme = JSON.parse(localStorage.getItem("darkTheme"));
    darkTheme = darkTheme ? darkTheme : false;
    console.log(darkTheme, "darkTheme");
    if (darkTheme == true) {
      setIsDarkTheme(true);
    }
  }, [refreshUser]);

  useEffect(() => {
    if (!userProfile) return;
    if (userProfile.type == "SubAdmin") {
      setIsAdmin(false);
    }
  }, [userProfile]);

  useEffect(() => {
    if (!isLoggedIn) return;
  }, [isLoggedIn]);

  const login = () => {
    setIsLoggedIn(true);
    return <Navigate to="/dashboard" />;
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("wallet");
    setIsLoggedIn(false);
    setSession({ token: null });
    setUserProfile();
    return <Navigate to="/home" />;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        session,
        setSession,
        userProfile,
        setUserProfile,
        login,
        logout,
        isAdmin,
        setIsAdmin,
        isMobile,
        refreshUser,
        setRefreshUser,
        isDarkTheme,
        setIsDarkTheme,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
