
import React, { useEffect, useState } from "react";
import Main from "../../components/layout/Main";
import { useLocation, useNavigate } from "react-router";
import useRequest from "../../hooks/useRequest";


import { Severty, ShowToast } from "../../helper/toast";
import Loader from "../../components/Loader";
import { Button, Result } from "antd";
const ReturnUrl = () => {

    const { request } = useRequest()

    const location = useLocation()

    const searchParams = new URLSearchParams(location.search)
    const bookingId = searchParams.get('bookingId');
    const userId = searchParams.get('userId');
    const [loading, setLoading] = useState(false)
   const navigate = useNavigate()
    const payNow = () => {
        setLoading(true)
        request({
            url: `/app/payment/pay-now?bookingId=${bookingId}&userId=${userId}`,
            method: 'get',
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS);
                } else {
                    ShowToast(data.message, Severty.ERROR);
                }

                // ShowToast("Payment Successfully", Severty.SUCCESS)
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    useEffect(() => {
        payNow()
    }, [bookingId])




    return (
      <>
        {/* <ToastContainer position="top-right" /> */}

        {loading ? (
          <Loader />
        ) : (
          <div className="login-sec customer-form-main customer-form-main-for-calcel-payment">
            <Result
              status="success"
              title="Your Payment has been  Successfully Done !"
              extra={[
                <Button type="primary" key="console"  onClick={() => {navigate("/my-booking");}}>
                 Back
                </Button>
              ]}
            />
          </div>
        )}
      </>
    );
}

export default ReturnUrl