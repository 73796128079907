import { Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import UserlogoImg from "../../assets/images/user (1).png";
import AboutusImg from "../../assets/images/information-button.png";
import HowWorkImg from "../../assets/images/user-guide.png";
import PrivecyImg from "../../assets/images/insurance.png";
import UpcomingImg from "../../assets/images/upcoming.png";
import TransactionImg from "../../assets/images/trade.png";
import SettingImg from "../../assets/images/settings.png";
import DeleteImg from "../../assets/images/delete (1).png";
import LogoutImg from "../../assets/images/logout (3).png";
import ChangePassword from "../../assets/images/padlock.png";
import RightIcon from "../../assets/images/right-errow.png";
import NOtifiactionimg from "../../assets/images/notification-white-side.svg";
import StudentImg from "../../assets/images/user-new-side.svg";
import CartImg from "../../assets/images/shopping_bag-side.svg";


import { Container } from "react-bootstrap";
import { ShowToast, Severty } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
// import OtpModal from "../../modals/OtpModal";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteAccountOtp from "../../modals/DeleteAccountOtp";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const AccountSideNav = ({}) => {
  const location = useLocation();
  const { request } = useRequest();
  const [show, setShow] = useState(false);
  const { logout, setIsLoggedIn, isLoggedIn } = useContext(AuthContext);
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const { userProfile } = useAuthContext();
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const navigate = useNavigate();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const { ShowConfirmBox } = ConfirmationBox();
  const showDeleteConfirm = (record) => {
    navigate("/login");
    logout();
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
    if (!isMobile) {
      navigate("/account");
    }
  }, [isMobile]);

  const handleDeleteAccount = () => {
    let payload = {};
    request({
      url: apiPath.deleteAccount,
      method: "GET",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          setShowOtpModal(true);
          ShowToast("Otp send successfully", Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
    
      <Col span={24} lg={6} xxl={4} className="d-block d-lg-none">
        <div className="side-nav-main">
          <div className={show ? "sideshow" : "sidehide"}>
            <div className="side-nav">
              <ul>
                <li
                  onClick={() => navigate("/account")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/account" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UserlogoImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/account"> {lang("My profile")}</Link>
                      <p>{lang("Edit name and view address details")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                
                <li
                  onClick={() => navigate("/student")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/student" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={StudentImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/student">
                        {" "}
                        {lang("Student")}
                      </Link>
                      <p>{lang("View Student")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/my-booking")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/my-booking" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UpcomingImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/my-booking"> {lang("My Booking")}</Link>
                      <p>{lang("my-booking to access")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/upcoming-booking")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/upcoming-booking" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={UpcomingImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/upcoming-booking"> {lang("Upcoming Events and Preparations")}</Link>
                      <p>{lang("my-booking to access")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
              
                <li
                  onClick={() => navigate("/transication")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/transication" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={TransactionImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/transication">
                        {" "}
                        {lang("Transaction History")}
                      </Link>
                      <p>{lang("Transaction History View")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/notification")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/notification" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={NOtifiactionimg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/notification">
                        {" "}
                        {lang("Notification")}
                      </Link>
                      <p>{lang("View Notification")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
               
                <li
                  onClick={() => navigate("/cart")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/cart" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={CartImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/cart">
                        {" "}
                        {lang("Cart")}
                      </Link>
                      <p>{lang("View Cart")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
              
               
                <li
                  onClick={() => navigate("/aboutUs")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/aboutUs" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={AboutusImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/aboutUs"> {lang("About Us")}</Link>
                      <p>{lang("About Us Details")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/how-work")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/how-work" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={HowWorkImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/how-work"> {lang("How It Works")}</Link>
                      <p>{lang("View It Works ")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/privecy_policy")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/privecy_policy" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={PrivecyImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/privecy_policy">
                        {" "}
                        {lang("Privacy Policy")}
                      </Link>
                      <p>{lang("View your Privacy Policy")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/change-password")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/change-password" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={ChangePassword} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/change-password">
                        {lang("Change Password")}
                      </Link>
                      <p>{lang("Change Password")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => navigate("/settings")}
                  className={`nav-items mobile-side ${
                    location.pathname === "/settings" ? "active" : ""
                  }`}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={SettingImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/settings">{lang("Settings")}</Link>
                      <p>{lang("View and manage your Settings")}</p>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => setDeleteAccount(true)}
                  className={`nav-items mobile-side `}
                >
                  <div className="accont-details">
                    <div className="account-icon-box">
                      <img src={DeleteImg} />
                    </div>
                    <div className="accounts-detail-list">
                      <Link to="/"> {lang("Delete Account")}</Link>
                    </div>
                    <div className="errow-icon">
                      <img width={10} src={RightIcon} />
                    </div>
                  </div>
                </li>
                <li className={`nav-items mobile-side ` } onClick={() => setIsLogoutModalVisible(true)}>
                  <Link >
                    <div className="accont-details">
                      <div className="account-icon-box">
                        <img src={LogoutImg} />
                      </div>
                      <div className="accounts-detail-list">
                        <Link>{lang("Logout")}</Link>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Col>

      {isLogoutModalVisible && (
        <ShowConfirmBox
          reasons={[]}
          title={lang("Logout")}
          subtitle={lang(`Are you sure you want to Logout ?`)}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => showDeleteConfirm()}
        />
      )}
      {deleteAccount && (
        <ShowConfirmBox
          reasons={[]}
          title={lang("Delete Account")}
          subtitle={lang(`Are you sure you want to delete this Account?`)}
          show={deleteAccount}
          hide={() => {
            setDeleteAccount(false);
          }}
          onOk={() => {
            handleDeleteAccount();
          }}
        />
      )}
      {showOtpModal && (
        <DeleteAccountOtp
          show={showOtpModal}
          hide={() => {
            setShowOtpModal(false);
          }}
        />
      )}
    </>
  );
};
export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};
export default AccountSideNav;
