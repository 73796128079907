import React from "react";
import { Container } from "react-bootstrap";
import { Row, Col } from "antd";
import { LinkOutlined, PhoneOutlined } from "@ant-design/icons";
import Logo from "../../assets/images/booknow-small-logo.png";
import { useNavigate } from "react-router";



function Footer() {

const navigate = useNavigate();

  return (
    <section className="main-new-footer">
      <Row gutter={[24, 0]}>
        <Col span={24} md={8}>
          <div className="left-section">
            <h2>Booknow!</h2>
            <p>
              Plot 105, Achilles St,
              <br />
              Zwavelpoort, Pretoria, 0036
              <br />
              South Africa
            </p>
            <p className="m-0">
              <strong>Phone:</strong> 069 828 6501
            </p>
            <p className="m-0">
              <strong>Email:</strong> support@booknow.academy
            </p>
          </div>
        </Col>
        <Col span={24} md={8}></Col>
        <Col span={24} md={8}>
          <div className="right-section">
          <img
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
                src={Logo}
                alt="Logo"
                className="img-fluid-oki"
              />
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default Footer;
