import {
  Home,
  Exam,
  Event,
  Preparation,
  Account,
  ChangePassword,
  Notification,
  Student,
  AboutUs,
  HowWorks,
  Privecy,
  Transication,
  Booking,
  UpcomingBooking,
  Settings,
  ViewEventPreparation,
  ViewExam,
  Cart,
} from "./pages/Index";
import Signin from "./pages/Auth/Signin";
import Register from "./pages/Auth/Register";
import Result from "./pages/Student/resultpage";
import "./assets/styles/responsive.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import React, { Suspense, useState, useEffect } from "react";
// import "./assets/styles/main.css";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/layout/Header";

import PrivateRoute from "./components/PrivateRoute";
import AccountMenu from "./pages/Account/AccountMenu";
import CancelUrl from "./pages/Cart/CancelUrl";
import ReturnUrl from "./pages/Cart/ReturnUrl";

<link
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
  rel="stylesheet"
/>;

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const [query, setQuery] = useState("");

  return (
    <>
      {" "}
      <Routes>
          <Route path="/login" element={<Signin />} />
          <Route path="/register" element={<Register />} />
          <Route path="/cancel" element={<CancelUrl />} />
          <Route path="/success" element={<ReturnUrl />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route path="/" element={<Home />} />
        
          <Route path="/exam" element={<Exam />} />
          <Route path="/view-exam/:centerId/:examId" element={<ViewExam />} />
          <Route path="/preparation" element={<Preparation />} />
          <Route path="/event" element={<Event />} />
          <Route path="/result" element={<Result />} />
          <Route
            path="/view-event-preparation/:id"
            element={<ViewEventPreparation />}
          />
          <Route path="/account" element={<Account />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/student" element={<Student />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/how-work" element={<HowWorks />} />
          <Route path="/privecy_policy" element={<Privecy />} />
          <Route path="/transication" element={<Transication />} />
          <Route path="/my-booking" element={<Booking />} />
          <Route path="/upcoming-booking" element={<UpcomingBooking />} />
          <Route path="/settings" element={<Settings />} />
          {/* <Route path="/book-now" element={<BookNow />} /> */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/account-menu" element={<AccountMenu />} />
        </Route>
      </Routes>
    </>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
