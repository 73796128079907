import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
  Typography,
  Tag,
  Badge,
  Calendar,
  Image,
  Tooltip
} from "antd";
import {
  ArrowLeftOutlined,
  CalendarOutlined,
  FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import AccountSideNav from "../../components/AccountSideNav";
import Loader from "../../components/Loader";
import { useMediaQuery } from "react-responsive";
import apiPath from "../../constants/apiPath";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import UserLogoNew from "../../assets/images/main-new-face.png";
import moment from "moment";
import notfound from "../../assets/images/not_found.png";
import NodataImg from "../../assets/images/no-data.png";
import ExamImgNew from "../../assets/images/exam.png";
import prerarationImgNew from "../../assets/images/preparation.png";
import EventImgNew from "../../assets/images/event.png";
import ViewUpBookingData from "../../modals/ViewUpBookingData";

const { Text } = Typography;
const getStatusTag = (status) => {
  switch (status) {
    case "Processing":
      return <div className="main-new-div-tags-098uj">Processing</div>;
    case "Accepted":
      return <div className="main-new-div-tags-098uj">Accepted</div>;
    case "On Hold":
      return <div className="main-new-div-tags-098uj">On Hold</div>;
    default:
      return null;
  }
};

function Booking() {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const { request } = useRequest();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const monthYear = value.format("MMMM YYYY");

    return (
      <div className="main-new-calander-header-mini-calander">
        <button onClick={() => onChange(value.clone().subtract(1, "month"))}>
          ◀
        </button>
        <Text strong style={{ fontSize: 16 }}>
          {monthYear}
        </Text>
        <button onClick={() => onChange(value.clone().add(1, "month"))}>
          ▶
        </button>
      </div>
    );
  };

  const dateCellRender = (date) => {
    const dayEvents = data?.length
      ? data?.filter((event) => {
          const bookingStartDate = event?.type !== "Exam" ? moment(event?.eventData?.booking_start_date) : "";
          return event?.type !== "Exam" && bookingStartDate?.isSame(date, "day");
        })
      : [];

    return (
      <div className="calander-remaining-class-dost">
      {dayEvents?.length
        ? dayEvents
            .slice(0, 3) // Only take the first 3 events
            .map((event, index) => {
              let badgeColor = "black";
              if (event?.type === "Exam") {
                badgeColor = "#E05E00";
              } else if (event?.type === "Event") {
                badgeColor = "#CA00C2";
              } else if (event?.type === "Preparation") {
                badgeColor = "#00A9CE";
              }
    
              return (
                <>
                <Tooltip title={event?.type === "Exam" ? event?.examData?.name : event?.eventData?.name}>
                <Badge key={index} dot status="processing" color={badgeColor} />
                </Tooltip>
                </>
              );
            })
        : ""}
    </div>
    );
  };

  const getData = () => {
    setLoading(true);
    request({
      url: `${apiPath.upcomingBooking}?search=${debouncedSearchText}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setData(data?.getBooking);
        }
      },
      onError: (error) => {
        setLoading(false);
        // ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, [debouncedSearchText,refresh]);

  return (
    <>
      <section className="main container-fluid">
        <div className="account-outers">
          <Row>
            {isMobile ? <BackArrow /> : <AccountSideNav />}
            <Col span={24} lg={16} xl={15} className="mx-auto  amt-auto">
              <div className="tital-text-sign">Upcoming Events and Preparations</div>
              <div className="tabled quoteManagement upcomming-booking0main-div">
                <Card className="criclebox tablespace" loading={loading}>
                  <Row gutter={[24, 24]}>
                    <Col span={24} lg={12}>
                      <Calendar
                        fullscreen={false}
                        headerRender={headerRender}
                        dateCellRender={dateCellRender}
                      />
                    </Col>
                    <Col span={24} lg={12}>
                      {loading ? (
                        <Loader />
                      ) : (
                        <div>
                          {data?.length ? <Text strong style={{ fontSize: 16 }}>
                            {moment().format("DD-MMM-YYYY")}
                          </Text> : "" }
                         <div className="main-new-remember-classes-main-scroll-class">
                         {data?.length ? (
                            data?.map((item) => (
                              <Card
                                key={item?._id}
                                className="main-new-remember-classes"
                                onClick={() => {
                                  setVisible(true);
                                  setSelected(item);
                                }}
                              >
                                <div className="main-new-remember-classes-olik-main-divall-mainhj">
                                  <div className="main-new-calaner-logo-user">
                                    <Image
                                      src={
                                        item?.type === "Exam"
                                          ? item?.centerData?.image
                                            ? item?.centerData?.image
                                            : notfound
                                          : item?.eventData?.image
                                          ? item?.eventData?.image
                                          : notfound
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </div>

                                  <div className="main-new-remember-classes-olik-main-div">
                                    <div className="main-new-remamber-clalaner-date-clas">
                                      <div className="d-flex gap-1 align-items-center">
                                      {item?.type === "Exam" ? (
                                        <div className="mini-logo-exam-img">
                                          {" "}
                                          <img src={ExamImgNew} />{" "}
                                        </div>
                                      ) : item?.type === "Event" ? (
                                        <div className="mini-logo-exam-img mini-logo-exam-img-for-event">
                                          {" "}
                                          <img src={EventImgNew} />{" "}
                                        </div>
                                      ) : (
                                        <div className="mini-logo-exam-img mini-logo-exam-img-for-preparation">
                                          {" "}
                                          <img src={prerarationImgNew} />{" "}
                                        </div>
                                      )}

                                      <h1>
                                        {item?.type === "Exam"
                                          ? item.syllabusData?.name
                                          : item.eventData?.name}{" "}
                                      </h1>
                                      </div>
                                      <div className="main-new-remamber-clalaner-date-clas1234">
                                        
                                      <h4>
                                        {item?.type === "Exam"
                                          ? item?.examData?.start_date ? (
                                            <>
                                              <span>{moment(item?.examData?.start_date).format("DD-MMM-YYYY")}</span>
                                              <span>{moment(item?.examData?.start_date).format("hh:mm a")}</span>
                                            </>
                                          ) : (
                                            ""
                                          )
                                          : item?.eventData?.booking_start_date ? (
                                            <>
                                              <span>{moment(item?.eventData?.booking_start_date).format("DD-MMM-YYYY")}</span>
                                              <span>{moment(item?.eventData?.booking_start_date).format("hh:mm a")}</span>
                                            </>
                                          ) : (
                                            ""
                                          )
                                        }
                                      </h4>
                                        
                                      </div>
                                    </div>

                                    <div className="main-new-remamber-clalaner-date-clas">
                                      <h6>
                                        {/* {item?.type} */}
                                        {"  "}
                                        {item?.type === "Exam"
                                          ? item.examData?.exam_code
                                          : item.bookingNo
                                          ? `${item.bookingNo}`
                                          : ""}
                                      </h6>
                                      <h1>{item.code}</h1>
                                    </div>
                                
                                  </div>
                                </div>
                                <div className="main-new-absolute-positionhh">
                                  {getStatusTag(item.status)}
                                </div>
                              </Card>
                            ))
                          ) : (
                            <div className="no-data-image">
                              <img src={NodataImg} />
                            </div>
                          )}
                         </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {visible && (
        <ViewUpBookingData
          data={selected}
          show={visible}
          hide={() => {
            setVisible(false);
            setSelected();
          }}
          refresh={()=> setRefresh(prev => !prev)}
        />
      )}
    </>
  );
}


// const ViewData = ({ show, hide, data }) => {
//   const [list, setList] = useState({});
//   const [loading, setLoading] = useState(false);
//   const { request } = useRequest();

//   const getData = () => {
//     setLoading(true);
//     request({
//       url: `${apiPath.upcomingBookingView}/${data?._id}`,
//       method: "GET",
//       onSuccess: ({ data, status }) => {
//         setLoading(false);
//         if (status) {
//           setList(data?.data ?? []);
//         }
//       },
//       onError: (error) => {
//         setLoading(false);
//         // ShowToast(error?.response?.data?.message, Severty.ERROR);
//       },
//     });
//   };

//   useEffect(() => {
//     if (!data) return;
//     getData();
//     console.log(data, "data");
//   }, [data]);

//   return (
//     <>
//       <Modal
//         open={show}
//         onCancel={hide}
//         footer={null}
//         header={null}
//         className="main-new-attribute-calander-modal"
      
//       >
//         {list?.length ? list?.map((item) => (
//           <Card >
//             <div className="main-new-card-attribute-calander-new-main-12">
//               <h3>Student</h3>
//               <div>
//                 {item?.studentData?.length ? (
//                   <Button
//                     icon={<UserOutlined />}
//                     type="text"
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "flex-start",
//                     }}
//                   >
//                     {item?.studentData?.map(({name}) => name).join(", ")}
//                   </Button>
//                 ) : (
//                   "-"
//                 )}
//               </div>
//             </div>

//             {/* Status Section */}
//             <div className="main-new-card-attribute-calander-new-main-12">
//               <h3>Status</h3>
//               <div>
//                 <Tag color="orange">{item?.status ?? "Processing"}</Tag>
//               </div>
//             </div>

//             {/* Attachments Section */}
//             <div className="main-new-card-attribute-calander-new-main-12">
//               <h3>Attachments</h3>
//               <div>
//                 <Button icon={<FileOutlined />} type="primary"  >
//                   Files
//                 </Button>
//               </div>
//             </div>

//             {/* Booking No Section */}
//             <div className="main-new-card-attribute-calander-new-main-12">
//               <h3>Booking No</h3>
//               <div>
//                 <Button icon={<FileOutlined />} type="text">
//                   {item?.bookingNo ?? "-"}
//                 </Button>
//               </div>
//             </div>

//             {/* Exam Start Date Section */}
//             <div className="main-new-card-attribute-calander-new-main-12">
//               <h3>Exam start date</h3>
//               <div>
//                 <Button icon={<CalendarOutlined />} type="text">
//                   {item?.type === "Exam" ?
//                    item?.examData?.start_date ? moment(item?.examData?.start_date).format("DD-MMM-YYYY hh:mm a") : "-" :
//                    item?.eventData?.event_start_date ? moment(item?.eventData?.event_start_date).format("DD-MMM-YYYY hh:mm a") : "-"}
//                 </Button>
//               </div>
//             </div>

//             {/* Center Section */}
//             <div className="main-new-card-attribute-calander-new-main-12">
//               <h3>Center</h3>
//               <div className="main-new-card-attribute-calander-new-main-12-main-divhg65">
//                 <div className="pin-iamge0main-divhgf">
//                   <img src={Newmainpin} />
//                   <h6>{item?.centerData?.name ?? "-"}</h6>
//                 </div>
//                 {item?.centerData?.location ? <div className="pin-iamge0main-divhgf">
//                   <h6>{item?.centerData?.location ?? "-"}</h6>
//                 </div> : ""}
//                 {item?.centerData?.center_code ? <div className="pin-iamge0main-divhgf">
//                   {item?.centerData?.center_code ?? "-"}
//                 </div> : ""}

//                {item?.centerData?.email ?  <div className="pin-iamge0main-divhgf">
//                   {item?.centerData?.email ?? "-"}
//                 </div> : ""}

               
//               </div>
//             </div>
//           </Card> 
//         )) : <Card loading={loading}></Card>}
//       </Modal>
//     </>
//   );
// };

export const BackArrow = () => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default Booking;
