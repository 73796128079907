import React, { useEffect, useState } from "react";
import {
  Button,
  Tabs,
  Modal,
  List,
  Avatar,
  Row,
  Image,
  Col,
  Typography,
  Input,
  Space,
  Result,
  Layout,
  Card,
} from "antd";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import Loader from "../../components/Loader";
import UserImg from "../../assets/images/Group 288.png";
import DeleteImg from "../../assets/images/delete (2)-new.png";
import EditImg from "../../assets/images/edit-new-9i.png";
import EyeImg from "../../assets/images/eye-new-pngg.png";
import Footer from "../../components/layout/Footer";
import NodataImg from "../../assets/images/no-data.png";
import Prousergirl from "../../assets/images/for-girl.png";
import Prouserboy from "../../assets/images/for-boy.png";

import { useAppContext } from "../../context/AppContext";
import useDebounce from "../../hooks/useDebounce";
import {
  UserOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  SearchOutlined,
  EyeFilled,
  EditFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import ViewDetails from "./ViewDetails";
import AddForm from "./AddForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteApis from "../../services/DeleteApis";
import { useNavigate } from "react-router";
import ViewUpBookingData from "../../modals/ViewUpBookingData";
import { useMediaQuery } from "react-responsive";

const { Title, Text } = Typography;
const { Search } = Input;

function Index() {
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const { deleteData } = DeleteApis();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const { language, setRefreshNotification } = useAppContext();
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleUpComingdata, setVisibleUpComingdata] = useState(false);
  const [upComingdata, setUpComingdata] = useState(null);
  const [selected, setSelected] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const { ShowConfirmBox } = ConfirmationBox();
  const [filter, setFilter] = useState({
    start_date: undefined,
    end_date: undefined,
  });

  const api = {
    delete: apiPath.deleteStudent,
    addEdit: apiPath.addEditStudent,
  };

  const handleDelete = () => {
    deleteData({
      url: api.delete,
      id: selected,
      setRefresh: setRefresh,
      setLoading: setLoading,
      setSelected: setSelected,
    });
  };

  const fetchData = (pagination, filters, sorter) => {
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        apiPath.getBookingStudent +
        `?page=${pagination ? pagination.current : 1}&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data?.data ?? []);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination, current: 1 });
  }, [refresh, debouncedSearchText, filter]);

  return (
    <>
      <Layout>
      {isMobile ? <BackArrow /> :""}
        <div className="container">
          <div className="main-new-student-hung7y-mainhn">
            <div className="main-new-student-hung7y">
              <h3 className="title">My Students</h3>

              <div className="main-new-view-searchbar">
                <Search
                  className="search-input"
                  placeholder="Search students"
                  allowClear
                  value={searchText}
                  onChange={onSearch}
                />
              </div>
              <div className="actions">
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setSelected();
                    setVisible(true);
                  }}
                >
                  Add new student
                </Button>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <Row gutter={[24, 24]}>
                {list?.length ? (
                  list.map((student) => (
                    <Col span={24} sm={12} md={8} lg={6}>
                      <div>
                        <Card
                          key={student?._id}
                          className="main-new-student-card-form-0oi9x"
                        >
                          <div className="main-new-image-tag-divghgtfyhgf567">
                            <Image
                              src={student?.image ? apiPath.profileBaseUrl + student.image : student.gender === "Female" ?  Prousergirl :  Prouserboy}
                              alt="Student Image"
                            />

                            <div className="student-card-text-main8-button-main-div">
                              <div className="student-card-text-main8">
                                <h3 style={{ margin: 0 }}>
                                  {`${student?.first_name} ${student?.last_name}` ??
                                    "-"}
                                </h3>
                                <p style={{ color: "#666" }}>
                                  {student?.type ?? "-"}
                                </p>
                              </div>

                              <div className="main-new-student-actions-buton">
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setSelected(student);
                                    setShow(true);
                                  }}
                                >
                                  <img src={EyeImg} />
                                </Button>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setSelected(student);
                                    setVisible(true);
                                  }}
                                >
                                  <img src={EditImg} />
                                </Button>
                                <Button
                                  type="primary"
                                  danger
                                  onClick={() => {
                                    setSelected(student?._id);
                                    setIsDeleteVisible(true);
                                  }}
                                >
                                  <img src={DeleteImg} />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  ))
                ) : (
                  <Col span={24}>
                    <div className="no-data-image">
                      <img src={NodataImg} />
                    </div>
                  </Col>
                )}
              </Row>
            )}
          </div>
        </div>
        {visible && (
          <AddForm
            section={"Student"}
            api={api}
            show={visible}
            hide={() => {
              setSelected();
              setVisible(false);
            }}
            data={selected}
            refresh={() => setRefresh((prev) => !prev)}
            showSuccess={() => {
              setIsSuccessModal(true);
              setSelected();
            }}
          />
        )}
        {isSuccessModal && (
          <SuccessModal
            show={isSuccessModal}
            hide={() => {
              setIsSuccessModal(false);
              setSelected();
            }}
            addStudent={() => {
              setIsSuccessModal(false);
              setVisible(true);
              setSelected(false);
            }}
          />
        )}
        {show && (
          <ViewDetails
            show={show}
            hide={() => {
              setShow(false);
              setSelected(null);
            }}
            data={selected}
            refresh={() => setRefresh((prev) => !prev)}
            setVisibleUpComingdata={setVisibleUpComingdata}
            setUpComingdata={setUpComingdata}
          />
        )}

       {visibleUpComingdata && (
        <ViewUpBookingData
          data={upComingdata}
          show={visibleUpComingdata}
          hide={() => {
            setVisibleUpComingdata(false);
            setUpComingdata(null)
            setSelected();
          }}
          refresh={()=> setRefresh(prev => !prev)}
        />
      )}

        {isDeleteVisible && (
          <ShowConfirmBox
            reasons={[]}
            title={"Delete Student"}
            subtitle={`Are you sure you want to delete this Student?`}
            show={isDeleteVisible}
            hide={() => {
              setIsDeleteVisible(false);
              setSelected();
            }}
            onOk={() => handleDelete()}
          />
        )}
      </Layout>
    </>
  );
}

const SuccessModal = ({ show, hide, addStudent }) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={show}
        onCancel={hide}
        footer={false}
        className="main-new-student-added-button-modal"
      >
        <Result
          status="success"
          title="Student Added"
          extra={[
            <div className="main-new-student-added-button">
              <Button key="buy" onClick={() => navigate("/exam")}>
                Continue with booking
              </Button>
              <Button type="primary" key="console" onClick={() => addStudent()}>
                Add another student
              </Button>
            </div>,
          ]}
        />
      </Modal>
    </>
  );
};
export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar-back-arrow fillter-sidebar-back-arrow-notification125">
      <div onClick={() => navigate("/account-menu")} className="sidebar-btn">
      <ArrowLeftOutlined />
    </div>
    </div>
  );
};
export default Index;
