import { useNavigate } from "react-router";
import apiPath from "../constants/apiPath";
import { useAppContext } from "../context/AppContext";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { useAuthContext } from "../context/AuthContext";




const FetchDataApis = ()=>{
    const {request } = useRequest() 

    const fetchData = ({url,pagination ,setPagination, filter,onFatch,setLoading,debouncedSearchText}) => {  
        setLoading(true)

        const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

        request({
          url: `${url}/?page=${pagination ? pagination.current : 1}&pageSize=${
            pagination ? pagination.pageSize : 10
          }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
          method: "GET",     
          onSuccess: ({data}) => {
           setLoading(false)
           onFatch(data ?? []);             
            if (data.status) {
            }
          },
          onError: (error) => {
            setLoading(false)
            // ShowToast(error?.response?.data?.message, Severty.ERROR);

          },
        });
      };

      return {fetchData}
}




export default FetchDataApis