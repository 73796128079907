import {
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import UploadImage from "../../components/UploadProfileImage";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import apiPath from "../../constants/apiPath";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import SingleImageUpload from "../../components/SingleImageUpload";

const AddForm = ({ section, api, show, hide, data, refresh, showSuccess }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();

  const FileType = ["application/pdf"];
  const [docsFile, setDocsFile] = useState();
  const [consFile, setconsFile] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue({
      ...data,
      dob: data.dob ? moment(data.dob) : "",
    });

    setDocsFile(data.document_file);
    setconsFile(data.concession_docs);
    setImage(data?.image);
  }, [data]);

  const onCreate = (values) => {
    const { dob, first_name, last_name, concession, concession_desc } = values;
    const payload = { type: "Student", ...values };
    setLoading(true);
    payload.dob = dob ? moment(dob).format("YYYY-MM-DD") : null;
    payload.document_file = docsFile ? docsFile : null;
    payload.image = image ? image : null;
    payload.name =
      first_name && last_name ? `${first_name} ${last_name}` : null;
    payload.concession_docs =
      concession === true ? (consFile ? consFile : null) : null;
    payload.concession_desc =
      concession === true ? (concession_desc ? concession_desc : null) : null;

    // return console.log(payload,"payload")
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "POST" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
          showSuccess();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleDocFile = (data) => {
    data.length > 0 ? setDocsFile(data[0].name) : setDocsFile([]);
  };

  const handleConsFile = (data) => {
    data.length > 0 ? setconsFile(data[0].name) : setconsFile([]);
  };

  const documentType = Form.useWatch("document_type", form);
  const concessionType = Form.useWatch("concession", form);
  return (
    <Modal
      maskClosable={false}
      open={show}
      width={750}
      okText={data ? lang("Update") : lang("Add")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      cancelText={lang("Cancel")}
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          // dob: moment("01-01-1990", "DD-MM-YYYY"),
          concession: false,
        }}
      >
        <div className="add_user_title">
          <h4 className="modal_title_cls">{`${
            data
              ? lang("Edit Student") /* sectionName */
              : lang("Add Student") /* sectionName */
          }`}</h4>
        </div>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <div className="text-center">
              <Form.Item
                className="upload_wrap"
                // rules={[
                //   {
                //     validator: (_, value) => {
                //       if (image) {
                //         return Promise.resolve();
                //       }
                //       return Promise.reject(
                //         new Error(lang("Profile image is required"))
                //       );
                //     },
                //   },
                // ]}
                name="image"
              >
                <UploadImage
                  preview={false}
                  value={image}
                  setImage={setImage}
                />
              </Form.Item>
            </div>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="username"
              name="first_name"
              label="First Name"
              rules={[
                {
                  max: 250,
                  message: "Name should contain more then 250 characters!",
                },
                {
                  required: true,
                  message: "Please Enter  Name",
                },
              ]}
            >
              <Input placeholder={"Enter First Name"} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              className="username"
              name="last_name"
              label="Last Name"
              rules={[
                {
                  max: 250,
                  message: "Name should contain more then 250 characters!",
                },
                {
                  required: true,
                  message: "Please Enter  Name",
                },
              ]}
            >
              <Input placeholder={"Enter Last Name"} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Email ID`)}</span>}
              name="email"
              rules={[
                {
                  type: "email",
                  message: lang("The email is not a valid email!"),
                },
                { required: true, message: lang("Please enter the email!") },
                {
                  max: 200,
                  message: lang(
                    "Email should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 5,
                  message: lang("Email should contain at least 5 characters!"),
                },
                {
                  pattern: new RegExp(
                    /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
                  ),
                  message: lang("Enter valid email!"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Email Address")}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Date of Birth`)}</span>}
              name="dob"
              rules={[
                {
                  required: true,
                  message: lang("Please select the date of birth"),
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder={lang("Select Date Of Birth")}
                disabledDate={(current) =>
                  current && current >= dayjs().endOf("day")
                }
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Gender`)}</span>}
              name="gender"
              rules={[
                { required: true, message: lang("Please select the gender!") },
              ]}
            >
              <Select
                placeholder={lang("Select Gender")}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Select.Option value="Male">{"Male"} </Select.Option>
                <Select.Option value="Female">{"Female"} </Select.Option>
                <Select.Option value="Prefer not to specify">
                  Prefer not to specify{" "}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Identity Document Type`)}</span>}
              name="document_type"
              rules={[
                { required: true, message: lang("Please select the Type!") },
              ]}
            >
              <Select
                placeholder={lang("Select Identity Document Type")}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={() => {
                  setDocsFile();
                  form.setFieldsValue({
                    document_file: "",
                  });
                }}
              >
                <Select.Option value="South African ID">
                  {"South African ID"}{" "}
                </Select.Option>
                <Select.Option value="Passport">{"Passport"} </Select.Option>
                <Select.Option value="Birth Certificate">
                  Birth Certificate{" "}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {(documentType === "Passport" ||
            documentType === "Birth Certificate" ||
            documentType === "South African ID") && (
            <Col span={24} sm={12}>
              <Form.Item
                className=""
                rules={[
                  {
                    validator: (_, value) => {
                      if (value !== undefined && value?.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(lang("Image is required"))
                      );
                    },
                  },
                ]}
                name="document_file"
                label={`Upload ${documentType} PDF`}
              >
                <SingleImageUpload
                  value={docsFile}
                  fileType={FileType}
                  imageType={"file"}
                  btnName={"PDF"}
                  onChange={(data) => handleDocFile(data)}
                  isDimension={false}
                />
              </Form.Item>
            </Col>
          )}
          {documentType === "South African ID" && (
            <Col span={24} md={12}>
              <Form.Item
                className="username"
                name="document_no"
                label="Document Number"
                rules={[
                  {
                    len: 13,
                    message: "Document Number must be 13 digits",
                  },
                  {
                    required: true,
                    message: "Please Enter  Document File",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  type="number"
                  maxLength={13}
                  placeholder={"Enter Document Number"}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Concession`)}</span>}
              name="concession"
              rules={[
                {
                  required: true,
                  message: lang("Please select the concession!"),
                },
              ]}
            >
              <Radio.Group>
                <Radio value={false} key={false}>
                  No concession required
                </Radio>
                <Radio value={true} key={true}>
                  We will apply for concessions
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {!!concessionType === true && (
            <>
              <Col span={24} md={12}>
                <Form.Item
                  className=""
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value?.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(lang("Image is required"))
                        );
                      },
                    },
                  ]}
                  label={lang("Upload Concessions support documentation")}
                  name="concession_docs"
                >
                  <SingleImageUpload
                    value={consFile}
                    fileType={FileType}
                    imageType={"file"}
                    btnName={"PDF"}
                    onChange={(data) => handleConsFile(data)}
                    isDimension={false}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  className="username"
                  name="concession_desc"
                  label={`Describe`}
                  rules={[
                    {
                      max: 250,
                      message: "Name should contain more then 250 characters!",
                    },
                    {
                      required: true,
                      message: "Please Enter  Document File",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder={"Enter Describe Concession..."}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24} sm={24}>
            <div className="mian-newterms-here">
              <Space align="baseline">
                Request advice on concession
                <Link
                  className="terms"
                  onClick={() =>
                    window.open("https://saintsburg.com/", "_blank")
                  }
                >
                  Here
                </Link>
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
