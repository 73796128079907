import { Button, Form, Modal } from "antd";
import { ShowToast, Severty } from "../helper/toast";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useRequest from "../hooks/useRequest";
const { confirm } = Modal;

const ConfirmationBox = () => {
  const { request } = useRequest();

  const ShowConfirmBox = ({
    show,
    hide,
    onOk,
    title,
    subtitle,
    reasons,
    children,
    okText = "Ok",
    cancelText = "Cancel",
  }) => {
    return (
      <Modal
        width={600}
        open={show}
        onOk={() => {
          if (onOk) onOk();
          hide();
        }}
        // maskClosable={false}
        okText={okText}
        cancelText={cancelText}
        onCancel={hide}
        centered
        className="tab_modal deleteWarningModal"
      >
        <Form layout="vertical" className="p-2">
          {children}
          <h4 className="modal_title_cls mb-2">{title}</h4>
          <h4 className="modal_sub_title_cls mb-2 ">{subtitle}</h4>
        </Form>
      </Modal>
    );
  };

  const ShowAlertBox = ({
    show,
    hide,
    onOk,
    title,
    subtitle,
    reasons,
    children,
    okText = "Ok",
    cancelText = "Cancel",
  }) => {
    return (
      <Modal
        width={500}
        open={show}
        onOk={() => {
          if (onOk) onOk();
          hide();
        }}
        // maskClosable={false}
        okText={okText}
        cancelText={cancelText}
        onCancel={hide}
        centered
        className="tab_modal deleteWarningModal"
      >
        <Form layout="vertical" className="p-2">
          {children}
          <h4 className="modal_title_cls mb-2">{title}</h4>
          <h4 className="modal_sub_title_cls mb-2 ">{subtitle}</h4>
        </Form>
      </Modal>
    );
  };

  

  // const handleItemDelete = (record, path, onSuccess) => {
  //   console.log(path, "hfjdhfkdhkfhdk 43 from confirmation box");
  //   const url = path + "/" + record._id;
  //   request({
  //     url: url,
  //     method: "DELETE",
  //     onSuccess: (data) => {
  //       onSuccess();
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //       ShowToast(error, Severty.ERROR);
  //     },
  //   });
  // };

  // const statusChange = (record, path, onLoading, onSuccess, type) => {
  //   onLoading(true);
  //   let url = "";
  //   if (type !== null && type !== undefined) {
  //     url = path + "/" + type + "/" + record;
  //   } else {
  //     url = path + "/" + record;
  //   }
  //   request({
  //     url: url,
  //     method: "GET",
  //     onSuccess: (data) => {
  //       onSuccess();
  //       onLoading(false);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //       ShowToast(error, Severty.ERROR);
  //     },
  //   });
  // };

  return { ShowConfirmBox, ShowAlertBox  };
};

export default ConfirmationBox;
