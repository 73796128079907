import { Button, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CancelUrl = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="login-sec customer-form-main customer-form-main-for-calcel-payment">
        <Result
          status="error"
          title="Your Payment has been Cancelled !"
          extra={[
            <Button type="primary" key="console" onClick={() => {navigate("/cart")}}>
              Back
            </Button>,
          ]}
        />
      </div>
    </>
  );
};

export default CancelUrl;
