let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://api.booknow.academy/api/";
} else {
  URL = "https://api.booknow.academy/api/";
  // URL = "http://103.189.173.7:4510/api/";
  //  URL = "http://localhost:4510/api/"; 
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  profileBaseUrl : "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/booknow/",

  //Auth 
  signUp :                  "app/auth/sign-up",
  login :                   "app/auth/login",
  forgotPassword :          "app/auth/forgot-password",
  verifyOTP :               "app/auth/verify-otp-new",  // forgot -password match otp
  resetPassword :           "app/auth/reset-password",
  profile       :           "app/auth/get-profile",
  editProfile       :       "app/auth/update-profile",
  changePassword       :    "app/auth/change-password",
  sendOtp       :           "app/auth/forgot-password",
  deleteAccount       :     "app/auth/delete-account",
  verifyDeleteAccOTP :      "app/auth/verify-otp",  // delete account match otp
  updateSetting       :     "app/auth/update-setting",
  favCenter       :         "app/auth/fav-center",
  socialLogin       :       "/app/auth/social-signup",


  //Content 
  fetchContent          :   "/common/get-content",
  transactionList       :   "app/booking/get-transaction-list",
  upcomingBooking       :   "app/booking/booking-list",
  upcomingBookingView     : "app/booking/get-booking",
  


  //Student
  getBookingStudent       :   "/app/booking/student-list",
  getStudent       :          "/app/student/list",
  deleteStudent       :       "app/student/delete",
  addEditStudent       :      "app/student/add-edit",
  studentUpBooking       :    "app/booking/get-booking-by-student",



  //Prepration
  listPreparation       :      "/app/preparation/list",
  createBooking       :        "app/cart/add-to-cart",
  editCreateBooking       :    "app/cart/edit-cart-item",
  preparationMapData       :   "common/get-map-data ",

  //Events 
  listEvents       :      "/app/event/list",
  viewEvents       :      "/app/event/view",

  //Exams 
  listExams       :      "/app/exam/list",
  viewExams       :      "/app/exam/exam-detail-website",
  courseSyllbus       :  "/app/exam/exam-course-syllabus",
  crsOption       :      "/app/exam/exam-course-syllabus-option",
  getComponent       :   "/app/syllabus/get-component",


  //Cart
  listCart       :      "app/cart/get-cart",
  deleteCart       :    "app/cart/delete-cart-item",
  checkOut       :      "app/cart/checkout",

  // My Booking
  myBooking       :      "app/booking/get-booking-by-type",
  uploadDocs       :     "app/booking/upload-file-booking",


  //Book now - 
  bookPreparationList       :      "/app/preparation/list",
  viewBookPreparation       :      "/app/preparation/view",

  //Notification - 
   notificationList       :      "app/notification/list",
   deleteNotifcation       :     "app/notification/delete",
   readNotifcation       :       "app/notification/read",
   showNotifcation       :       "app/notification/show",


  //Common 
  centerList       :      "common/centers",
  courseList       :      "common/course",
  syllabusList       :    "common/syllabus",


  
};

export default apiPath;
