import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Card,
  Row,
  Col,
  Space,
} from "antd";
import WelcomeImg from "../../assets/images/welcome-booknow.png";

import { UserAddOutlined } from "@ant-design/icons";
import LogoImg from "../../assets/images/booknow-small-logo.png";
import Banner1Img from "../../assets/images/Exam-banner1.png";
import Banner2Img from "../../assets/images/Prep-banner1.png";
import Banner3Img from "../../assets/images/Event-banner1.png";
import Footer from "../../components/layout/Footer";
import useRequest from "../../hooks/useRequest";
import { PhoneNumberField } from "../../components/InputField";
import AuthApis from "../../services/AuthApis";
import { useNavigate } from "react-router";
import AboutDrawer from "./AboutDrawer";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import { useAuthContext } from "../../context/AuthContext";
const { Title, Text, Link } = Typography;
const Register = () => {
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(null);
  const { request } = useRequest();
  // const { onRegister } = AuthApis();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showAboutDrawer, setShowAboutDrawer] = useState(false);
  const { setUserProfile, setIsLoggedIn } = useAuthContext();
  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  // const onFinish = (values) => {
  //   console.log(mobileNumber, values, "onFinish called");
  //   onRegister({
  //     form: form,
  //     values: values,
  //     mobileNumber: mobileNumber,
  //     setLoading: setLoading,
  //   });
  // };

  const onFinish = (values) => {
    setLoading(true);
    const { newPassword ,first_name, last_name } = values;
    const payload = {
      ...values,
      password: newPassword,
      country_code: mobileNumber?.country_code,
      mobile_number: mobileNumber?.mobile_number,
      device_type: "web",
      type: "Guardian",
      name : first_name && last_name ? `${first_name} ${last_name}` : null
    };

    request({
      url: apiPath.signUp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          console.log("data11134", data?.data);
          setIsLoggedIn(true);
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data?.data?.userData);
          localStorage.setItem("token", data?.data?.token);
          localStorage.setItem(
            "userProfile",
            JSON.stringify(data?.data?.userData)
          );
          setTimeout(() => navigate("/"), 200);
          form.resetFields();
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <div className="main-new-registernew-ujhy">
        <div className="section-grey">
          <Row gutter={[62, 32]}>
            <Col span={24} lg={16} className="order-2 order-lg-1">
              <div className="main-new-div-class-for-registerpage8u">
                <div className="main-new-div-class-for-registerpage8u-first">
                  <Row gutter={[16, 16]}>
                    <Col span={24} md={16}>
                      <h3>Exams</h3>
                      <p>
                        There are about 50 Cambridge Exam centres around the
                        country that accept home schoolers. We are in the
                        process of getting them all on board on Booknow! If
                        there is a centre on Booknow! that is near you, make use
                        of our easy booking procedures to make sure everything
                        goes smoothly.
                      </p>
                      <Button onClick={() => navigate("/exam")}>
                        FIND EXAM CENTERS
                      </Button>
                    </Col>

                    <Col span={24} md={8} className="main-new-jnh-logo-image">
                      <img src={Banner1Img} />
                    </Col>
                  </Row>
                </div>
                <div className="main-new-div-class-for-registerpage8u-first section-main-white">
                  <Row gutter={[16,16]}>
                    <Col span={24} md={16}>
                      <h3>Exam Preparations</h3>
                      <p>
                        If your child is currently preparing for an exam, give
                        him or her all the help they need. Look out for mock
                        exams, laboratory workshops and exam prep courses
                        presented by skilled teachers who advertise their
                        workshops on Booknow!
                      </p>
                      <Button onClick={() => navigate("/preparation")}>
                        FIND PREPARATIONS
                      </Button>
                    </Col>

                    <Col span={24} md={8} className="main-new-jnh-logo-image">
                      <img src={Banner2Img} />
                    </Col>
                  </Row>
                </div>
                <div className="main-new-div-class-for-registerpage8u-first">
                  <Row gutter={[16,16]}>
                    <Col span={24} md={16}>
                      <h3>Events</h3>
                      <p>
                        There are many events organised by the Home-schooling
                        community to assist you in your journey. Search for
                        events organised in your area and use Booknow! For easy
                        and discounted booking.
                      </p>
                      <Button onClick={() => navigate("/event")}>
                        FIND EVENTS
                      </Button>
                    </Col>

                    <Col span={24} md={8} className="main-new-jnh-logo-image">
                      <img src={Banner3Img} />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col
              span={24}
              lg={8}
              className="sigh-up-section order-1 order-lg-2"
            >
              <Card className="register-card">
                <div className="main-new-logo">
                  <img src={LogoImg} />
                </div>
                <div className="main-new-logo-gardin-textj">
                  <Title level={3}>Guardian Register</Title>

                  <p className="instuctionofregisterpage">
                    Please register below to make bookings for exams and other
                    events easy
                  </p>
                </div>
                <Form
                  name="register"
                  onFinish={onFinish}
                  form={form}
                  layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={24} xl={12}>
                      <Form.Item
                        label="First Name"
                        className="username"
                        name="first_name"
                        rules={[
                          {
                            max: 250,
                            message:
                              "Name should contain more then 250 characters!",
                          },
                          {
                            required: true,
                            message: "Please Enter  Name",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter First Name"} />
                      </Form.Item>
                    </Col>
                    <Col span={24} xl={12}>
                      <Form.Item
                        className="username"
                        label="Last Name"
                        name="last_name"
                        rules={[
                          {
                            max: 250,
                            message:
                              "Name should contain more then 250 characters!",
                          },
                          {
                            required: true,
                            message: "Please Enter  Name",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter Last Name"} />
                      </Form.Item>
                    </Col>
                    <Col span={24} xl={24}>
                      <Form.Item
                        className="username"
                        label={"Email Address"}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                          {
                            max: 255,
                            message:
                              "Email address not more then 255 characters!",
                          },
                          {
                            required: true,
                            message: "Please enter email!",
                          },
                        ]}
                      >
                        <Input placeholder={"Enter Email address"} />
                      </Form.Item>
                    </Col>
                    <PhoneNumberField
                      label={" Phone Number"}
                      name="mobile"
                      placeholder={"Enter Phone Number"}
                      cover={{ lg: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      className="new-mobile-passwordnew username"
                      onChange={handleMobileNumberChange}
                      number={mobileNumber?.mobile_number}
                    />
                  </Row>

                  <Row gutter={16}>
                    <Col span={24} xl={12}>
                      <Form.Item
                        label={"New Password"}
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your new password!",
                          },
                          {
                            pattern: new RegExp(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                            ),
                            message:
                              "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                          },
                        ]}
                      >
                        <Input.Password placeholder={"Enter New Password"} />
                      </Form.Item>
                    </Col>
                    <Col span={24} xl={12}>
                      <Form.Item
                        label={"Confirm New Password"}
                        name="confirm_new_password"
                        dependencies={["newPassword"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please enter the confirm password!",
                          },
                          {
                            pattern: new RegExp(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                            ),
                            message:
                              "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("newPassword") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Password that you entered doesn't match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder={"Enter Confirm Password"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="agree"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value !== undefined && value === true) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Please confirm  Agree to Terms and Conditions"
                            )
                          );
                        },
                      },
                    ]}
                  >
                    <div className="mian-newterms-conditionnewhg">
                      <Space align="baseline">
                        <Checkbox>
                          {"Agree to "}
                          <Link
                            className="terms"
                            onClick={() => setShowAboutDrawer(true)}
                          >
                            Terms and Conditions
                          </Link>
                        </Checkbox>
                      </Space>
                    </div>
                  </Form.Item>

                  <Form.Item>
                    <div className="main-register-button">
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                        disabled={loading}
                      >
                        Register
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item className="main-login-dont-have-account7yh">
                    <h3>
                      Already have an account?{" "}
                      <Link onClick={() => navigate("/login")}>Login</Link>
                    </h3>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {showAboutDrawer && (
        <AboutDrawer
          open={showAboutDrawer}
          hide={() => setShowAboutDrawer((prev) => !prev)}
        />
      )}
    </>
  );
};

export default Register;
